import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import "./campaign-account-row-context-menu.sass"
import { useQuery } from "@apollo/client"
import { useDispatch, useSelector } from "../../../state/hooks"
import { Scope } from "../../../util/types"
import IconButton from "../../IconButton"
import { isSuccess } from "../../../util/apiClient"
import { openDeliverableUpdateStatusModal } from "../../../state/campaignDeliverableV2UpdateStatusModal"
import {
  DeliverableQueryOutput,
  DeliverableStatus,
  GET_DELIVERABLE,
} from "../../../state/campaignDeliverableContentModalV2Slice/types"
import { DeliverableSummary } from "../../../graphqlV2/types/deliverableTypes"
import { DeliverableQueryVariables, Network } from "../../../graphql"
import Divider from "../../Divider"
import { openTikTokInviteModal } from "../../../state/tikTokInviteLinkModalSlice"
import { openCopyLinkModal } from "../../../state/copyLinkModalSlice"

interface CampaignDeliverableRowContextMenuProps {
  deliverable: DeliverableSummary
}

export default function CampaignDeliverableRowContextMenuV2({
  deliverable,
}: CampaignDeliverableRowContextMenuProps) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDeliverableRowContextMenu" })
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user }) => user)
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)

  const { data: detailedDeliverableData } = useQuery<DeliverableQueryOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE,
    {
      variables: { deliverableId: deliverable.id },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverable.id,
    },
  )
  const deliverableDetails = detailedDeliverableData?.deliverable

  if (!isSuccess(campaign) || !deliverableDetails) return null

  const showCreateTTCMLink = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    && deliverable.deliverableStatus === DeliverableStatus.FINALIZED
    && deliverable.socialAccount.networkType === Network.Tiktok
    && !deliverableDetails.ttcmLinkUrl

  const showCopyTTCMLink = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    && deliverable.socialAccount.networkType === Network.Tiktok
    && deliverableDetails.ttcmLinkUrl

  return (
    <>
      <IconButton
        className="cp_campaign-account-row-context-menu_component-button"
        id="cp_campaign_deliverable-v2_context_menu_button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_campaign-account-row-context-menu_component-menu-popover"
      >
        <MenuList
          dense={ true }
          className="cp_campaign-account-row-context-menu_component-menu"
        >
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
            <MenuItem
              onClick={ () => {
                dispatch(openDeliverableUpdateStatusModal({
                  deliverableId: deliverable.id,
                  deliverableStatus: deliverable.deliverableStatus as DeliverableStatus,
                }))
                setAnchorEl(null)
              } }
              className="cp_campaign-account-row-context-menu_component-menu-item"
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Update Status") }
              </ListItemText>
            </MenuItem>
          ) }
          { showCreateTTCMLink
             && (
             <>
               <Divider />
               <MenuItem
                 className="cp_campaign-account-row-context-menu_component-menu-item"
                 onClick={ () => {
                   if (!deliverable) return
                   dispatch(openTikTokInviteModal({
                     deliverableId: deliverable.id,
                     deliverableVersion: "v2",
                   }))
                   setAnchorEl(null)
                 } }
               >
                 <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                   { translate("Create TTCM Invite Link") }
                 </ListItemText>
               </MenuItem>
             </>
             ) }
          { showCopyTTCMLink && (
          <>
            <Divider />
            <MenuItem
              className="cp_campaign-account-row-context-menu_component-menu-item"
              onClick={ () => {
                if (!deliverableDetails.ttcmLinkUrl) return
                dispatch(openCopyLinkModal({
                  link: deliverableDetails.ttcmLinkUrl,
                  title: translate("Share TTCM Invite Link"),
                  subtitle: translate("Copy the invite link for the TTCM order."),
                }))
                setAnchorEl(null)
              }
                  }
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Copy TTCM Invite Link") }
              </ListItemText>
            </MenuItem>
          </>
          ) }
        </MenuList>
      </Menu>
    </>
  )
}
