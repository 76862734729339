import React from "react"
import "./style.sass"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import {
  ArrowDropDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import Button from "../../../Button"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  Metric,
  setHighlightsMetrics,
  setUpdateListForm,
  updateList,
} from "../../../../state/listConfigurationSlice"
import {
  Affinity,
  highlightMetrics,
  Occupation,
  ReadableAgeRange,
  ReadableEthnicity,
  ReadableFamilyStatus,
  ReadableIncome,
  ReadableReligion,
} from "../../constants"
import Dropdown from "../../../Dropdown"
import Input from "../../../Input"
import { HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES } from "../../../../util/constant"
import AudienceGenderForm from "./AudienceMetricForms/GenderForm"
import AudienceMetricDropdownForm from "./AudienceMetricForms/AudienceDropdownForm"
import AudienceFamilyStatusForm from "./AudienceMetricForms/FamilyStatusForm"
import AudienceLocationForm from "./AudienceMetricForms/LocationForm"
import AudienceOccupationForm from "./AudienceMetricForms/OccupationForm"
import AudienceAffinitiesForm from "./AudienceMetricForms/AffinitiesForm"
import * as GraphQL from "../../../../graphql"
import * as API from "../../../../util/apiClient"
import Pill from "../../../Pill"
import { pushToast } from "../../../../state/toastSlice"
import { fetchList } from "../../../../state/listSlice"

/**
 * AudienceMetricsFormProps: props for this component
 */
interface AudienceMetricsFormProps {
  heading: string
}

/**
 * SelectionValues: All the types that can be selected for audience metrics
 */
type SelectionValues =
  GraphQL.Sex[] | GraphQL.FamilyStatus[] | GraphQL.AgeRange | GraphQL.IncomeBrackets
  | GraphQL.Ethnicity | GraphQL.Religion | GraphQL.AudienceLocation | Occupation | Affinity

/**
 * AudiencePill: The label of the pill and code type
 */
type AudiencePill = {
  label: string
  code: string
}

/**
 * AudienceMetricFormDataProps: The properties for the various audience forms
 */
export interface AudienceMetricFormDataProps {
  label: string
  setSelected: (value: SelectionValues) => void
  selected?: SelectionValues
  options?: GraphQL.AgeRange[] | GraphQL.IncomeBrackets[] | GraphQL.Ethnicity[] | GraphQL.Religion[]
  locationType?: GraphQL.AudienceLocationType
}

/**
 * AudienceMetricsForm: The metrics form for audience
 * @param param0 The properties
 * @returns The react element for this component
 */
export default function AudienceMetricsForm({ heading }: AudienceMetricsFormProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()
  const MAX_METRICS = 5
  const { listID } = useParams()

  // Global state values
  const { updateListForm, highlightsMetrics } = useSelector((state) => state.listConfiguration)
  const { list } = useSelector((state) => state.list)

  // Local state values
  const [ expanded, setExpanded ] = React.useState(true)
  const [ saved, setSaved ] = React.useState<boolean>(false)
  const [ selectedMetric, setSelectedMetric ] = React.useState<Metric | undefined>()
  const [ metricValue, setMetricValue ] = React.useState<SelectionValues | undefined>()
  const [ audiencePills, setAudiencePills ] = React.useState<AudiencePill[]>([])
  const [ metricCount, setMetricCount ] = React.useState<number>(0)

  // Pulls all the audience metrics
  const audienceMetrics: Metric[] = React.useMemo((): Metric[] => {
    const metrics = highlightMetrics.find((group) => group.groupName === "Audience Metrics (up to 5)")?.metrics
    return metrics || []
  }, [ ])

  /**
   * Updates the pills from what was retrieved from database
   */
  React.useEffect(() => {
    // Make sure the list form exists
    if (updateListForm) {
      // Check to see if toggles are an array
      if (Array.isArray(updateListForm.toggles)) {
        // Filter out the audience metrics that are selected
        const metrics = highlightsMetrics
          .filter((metric) => highlightMetrics
            .find((group) => group.groupName === "Audience Metrics (up to 5)")?.metrics.includes(metric))

        // Set the display form
        setSaved(audienceMetrics.length > 0)

        // TODO Update all the metric pills
        if (updateListForm.highlightsAudienceDemographics) {
          const { highlightsAudienceDemographics: demo } = updateListForm
          const listDemo = (API.isSuccess(list)) ? list.payload.suggestionListById.highlightsAudienceDemographics : undefined
          const pills: AudiencePill[] = [ ...audiencePills ]
          let count = 0
          metrics.forEach((metric) => {
            if (!pills.some((pill) => pill.code === metric.code)) {
              switch (metric.code) {
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.GENDER: {
                  pills.push({
                    label: (Array.isArray(demo.audienceGender))
                      ? demo.audienceGender.join(" | ")
                      : demo.audienceGender,
                    code: metric.code,
                  })
                  count += 1
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS: {
                  const famStatus = (!Array.isArray(demo.audienceFamilyStatus))
                    ? [ demo.audienceFamilyStatus ]
                    : demo.audienceFamilyStatus
                  const singleMarried = famStatus
                    .filter((status) => (status !== GraphQL.FamilyStatus.Single) && (status !== GraphQL.FamilyStatus.Married))
                    .map((status) => ReadableFamilyStatus[status])
                    .join(" | ")
                  const parentNparent = famStatus
                    .filter((status) => (status !== GraphQL.FamilyStatus.Parent) && (status !== GraphQL.FamilyStatus.NonParent))
                    .map((status) => ReadableFamilyStatus[status])
                    .join(" | ")

                  if (singleMarried.length > 0) pills.push({ label: singleMarried, code: metric.code })
                  if (parentNparent.length > 0) pills.push({ label: parentNparent, code: metric.code })

                  // Set the pills
                  if (singleMarried.length > 0 || parentNparent.length > 0) count += 1
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE: {
                  if (demo.audienceAgeRange) {
                    pills.push({
                      label: ReadableAgeRange[demo.audienceAgeRange],
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE: {
                  if (demo.audienceIncomeRange) {
                    pills.push({
                      label: ReadableIncome[demo.audienceIncomeRange],
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES: {
                  if (demo.audienceEthnicity) {
                    pills.push({
                      label: ReadableEthnicity[demo.audienceEthnicity],
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS: {
                  if (demo.audienceReligion) {
                    pills.push({
                      label: ReadableReligion[demo.audienceReligion],
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES: {
                  if (demo.audienceCountry && listDemo && listDemo.audienceCountry.length > 0) {
                    pills.push({
                      label: listDemo.audienceCountry[0].name,
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.STATES: {
                  if (demo.audienceState && listDemo && listDemo.audienceCity.length > 0) {
                    if (!audiencePills.some((pill) => pill.code === metric.code)) {
                      pills.push({
                        label: listDemo.audienceState[0].name,
                        code: metric.code,
                      })
                      count += 1
                    }
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.CITIES: {
                  if (demo.audienceCity && listDemo && listDemo.audienceCity.length > 0) {
                    pills.push({
                      label: listDemo.audienceCity[0].name,
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS: {
                  if (demo.audienceOccupation && listDemo && listDemo.audienceOccupation.length > 0) {
                    pills.push({
                      label: listDemo.audienceOccupation[0].name,
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES: {
                  if (demo.audienceAffinity && listDemo && listDemo.audienceAffinity.length > 0) {
                    pills.push({
                      label: listDemo.audienceAffinity[0].name,
                      code: metric.code,
                    })
                    count += 1
                  }
                  break
                }
                default:
                  break
              }
            }
          })

          // Set the pills and count
          setAudiencePills(pills)
          setMetricCount(count)
        }
      }
    }
  }, [ updateListForm ])

  /**
   * showMetricForm: Shows the form that was selected from audience metric dropdown
   * @param code The code for the metric selected
   */
  const showMetricForm = (code: string) => {
    // Get metric name
    const name = audienceMetrics.find((metric) => metric.code === code)

    // Set the value
    setSelectedMetric(name)
  }

  /**
   * removePillMetric: Removes a pill and/or metric from the selected metrics
   * @param pill The metric pill with code
   */
  const removePillMetric = (pill: AudiencePill) => {
    // remove the pill/code
    const pills = audiencePills.filter((audiencePill) => audiencePill.label !== pill.label)
    const hasMorePills = pills.some((audiencePill) => audiencePill.code === pill.code)

    // Set new pill number
    setAudiencePills(pills)
    if (!hasMorePills) setMetricCount(metricCount - 1)

    /**
     * updateToggles: Removes a toggle from the list form toggles
     * @param toggles The list of toggles in list form
     * @param code The code to be removed
     * @returns Return the new array of toggles
     */
    const updateToggles = (toggles: string | string[], code: string): string[] => {
      if (Array.isArray(toggles)) return toggles.filter((toggle) => toggle !== code)
      if (toggles === code) return []
      return [ toggles ]
    }

    // UPdate metrics and list form
    if (updateListForm) {
      // Create copy of list form and highlight metrics
      const listForm = { ...updateListForm }
      let hlMetrics = [ ...highlightsMetrics ]

      // Update toggles and hmetrics
      if (!hasMorePills) {
        hlMetrics = hlMetrics.filter((metric) => metric.code !== pill.code)
        listForm.toggles = updateToggles(listForm.toggles, pill.code)
      }

      if (listForm.highlightsAudienceDemographics) {
        let demo = { ...listForm.highlightsAudienceDemographics }

        // Update the list form data
        switch (pill.code) {
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.GENDER: {
            demo = {
              ...demo,
              audienceGender: [],
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS: {
            const labels: string[] = pill.label.split("|").map((lbl) => lbl.trim())
            demo = {
              ...demo,
              audienceFamilyStatus: (Array.isArray(demo.audienceFamilyStatus))
                ? demo.audienceFamilyStatus.filter((value: GraphQL.FamilyStatus) => !labels.includes(ReadableFamilyStatus[value]))
                : [],
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE: {
            demo = {
              ...demo,
              audienceAgeRange: undefined,
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE: {
            demo = {
              ...demo,
              audienceIncomeRange: undefined,
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES: {
            demo = {
              ...demo,
              audienceEthnicity: undefined,
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS: {
            demo = {
              ...demo,
              audienceReligion: undefined,
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES: {
            demo = {
              ...demo,
              audienceCountry: [],
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.STATES: {
            demo = {
              ...demo,
              audienceState: [],
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.CITIES: {
            demo = {
              ...demo,
              audienceCity: [],
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS: {
            demo = {
              ...demo,
              audienceOccupation: [],
            }
            break
          }
          case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES: {
            demo = {
              ...demo,
              audienceAffinity: [],
            }
            break
          }
          default:
            break
        }

        // Update list form
        listForm.highlightsAudienceDemographics = demo

        // Update the state
        dispatch(setHighlightsMetrics(hlMetrics))
        dispatch(setUpdateListForm(listForm))
      }
    }
  }

  /**
   * addMetricToList: Adds a metric pill(s) to the list and prep it to be saved
   */
  const addMetricToList = () => {
    /**
     * updateListFormToggles: Adds the metric code to the list of toggles in the list form
     * @param toggles The toggle list in the list form
     * @param code The toggle code
     * @returns The array of list toggles
     */
    const updateListFormToggles = (toggles: string | string[], code: string): string[] => {
      // Handle array
      if (Array.isArray(toggles)) {
        if (!toggles.includes(code)) return [ ...toggles, code ]
        return toggles
      }

      // Handle single
      if (toggles !== code) return [ toggles, code ]
      return [ toggles ]
    }

    // Update highlights metrics
    if (selectedMetric) dispatch(setHighlightsMetrics([ ...highlightsMetrics, selectedMetric ]))

    // Add the pill
    if (selectedMetric && updateListForm) {
      // Create copy of the form
      const listForm = { ...updateListForm }

      let demo: GraphQL.SuggestionListAudienceInput = (listForm.highlightsAudienceDemographics)
        ? {
          ...listForm.highlightsAudienceDemographics,
        }
        : {
          audienceGender: [],
          audienceFamilyStatus: [],
          audienceCountry: [],
          audienceState: [],
          audienceCity: [],
          audienceOccupation: [],
          audienceAffinity: [],
        }

      // Update the pills, toggles and list form data
      switch (selectedMetric.code) {
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.GENDER: {
          const genders: GraphQL.Sex[] = metricValue as GraphQL.Sex[]
          setAudiencePills([
            ...audiencePills,
            {
              label: genders.map((gender) => gender.toString()).join(" | "),
              code: selectedMetric.code,
            },
          ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceGender: genders,
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS: {
          const famStatus: GraphQL.FamilyStatus[] = metricValue as GraphQL.FamilyStatus[]
          const singleMarried = famStatus
            .filter((status) => (status !== GraphQL.FamilyStatus.Single) && (status !== GraphQL.FamilyStatus.Married))
            .map((status) => ReadableFamilyStatus[status])
            .join(" | ")
          const parentNparent = famStatus
            .filter((status) => (status !== GraphQL.FamilyStatus.Parent) && (status !== GraphQL.FamilyStatus.NonParent))
            .map((status) => ReadableFamilyStatus[status])
            .join(" | ")

          const pills: AudiencePill[] = []
          if (singleMarried.length > 0) pills.push({ label: singleMarried, code: selectedMetric.code })
          if (parentNparent.length > 0) pills.push({ label: parentNparent, code: selectedMetric.code })

          // Set the pills
          if (pills.length > 0) {
            setAudiencePills([ ...audiencePills, ...pills ])
            setMetricCount(metricCount + 1)
          }
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceFamilyStatus: famStatus,
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE: {
          const ageRange = GraphQL.AgeRange[metricValue as string as keyof typeof GraphQL.AgeRange]
          setAudiencePills([ ...audiencePills, { label: ReadableAgeRange[ageRange], code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceAgeRange: ageRange,
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE: {
          const incomeRange = GraphQL.IncomeBrackets[metricValue as string as keyof typeof GraphQL.IncomeBrackets]
          setAudiencePills([ ...audiencePills, { label: ReadableIncome[incomeRange], code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceIncomeRange: incomeRange,
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES: {
          const ethnicity = GraphQL.Ethnicity[metricValue as string as keyof typeof GraphQL.Ethnicity]
          setAudiencePills([ ...audiencePills, { label: ReadableEthnicity[ethnicity], code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceEthnicity: ethnicity,
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS: {
          const religion = GraphQL.Religion[metricValue as string as keyof typeof GraphQL.Religion]
          setAudiencePills([ ...audiencePills, { label: ReadableReligion[religion], code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceReligion: religion,
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES: {
          const country = metricValue as GraphQL.AudienceLocation
          setAudiencePills([ ...audiencePills, { label: country.name, code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceCountry: [ country.code ],
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.STATES: {
          const state = metricValue as GraphQL.AudienceLocation
          setAudiencePills([ ...audiencePills, { label: state.name, code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceState: [ state.code ],
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.CITIES: {
          const city = metricValue as GraphQL.AudienceLocation
          setAudiencePills([ ...audiencePills, { label: city.name, code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceCity: [ city.code ],
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS: {
          const occupation = metricValue as Occupation
          setAudiencePills([ ...audiencePills, { label: occupation.name, code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceOccupation: [ occupation.code ],
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES: {
          const affinity = metricValue as Affinity
          setAudiencePills([ ...audiencePills, { label: affinity.name, code: selectedMetric.code } ])
          setMetricCount(metricCount + 1)
          listForm.toggles = updateListFormToggles(listForm.toggles, selectedMetric.code)
          demo = {
            ...demo,
            audienceAffinity: [ affinity.code ],
          }
          break
        }
        default:
          break
      }

      // Update demographics
      listForm.highlightsAudienceDemographics = demo

      // Update the list
      dispatch(setUpdateListForm(listForm))
    }

    // Reset selected metric
    setSelectedMetric(undefined)
    setMetricValue(undefined)
  }

  /**
   * handleAudienceFormPersistence: Saves the audience form data to database
   */
  const handleAudienceFormPersistence = () => {
    // Call to save list
    dispatch(updateList({
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Successfully saved highlights audience metrics!"),
        }))
        setSaved(true)
        dispatch(fetchList(listID || ""))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: "Unable to save highlights audience metrics. Please try again later!",
        }))
      },
    }))
  }

  return (
    <Accordion
      onChange={ () => setExpanded(!expanded) }
      className="highlights-audience-metrics-form"
      elevation={ 0 }
      id="highlights-audience-metrics-form"
      defaultExpanded={ true }
      expanded={ expanded }
    >
      <AccordionSummary
        className="highlights-audience-metrics-form-summary"
        expandIcon={ expanded
          ? <KeyboardArrowUp className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
          : <KeyboardArrowRight className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
        }
        aria-controls="panel1a-content"
        id="highlights-audience-metrics-header"
      >
        <h3 className={ `highlights-audience-metrics-form-summary-heading${ (saved) ? " dsf-saved" : " dsf-not-saved" }` }>
          { translate(heading) }
        </h3>
      </AccordionSummary>
      <AccordionDetails className="highlights-audience-metrics-form-details">
        { (audiencePills.length > 0) && (
          <div className="audience-metrics-pills">
            { audiencePills.map((pill) => (
              <Pill
                className="audience-metric-pill"
                label={ pill.label }
                onDelete={ () => removePillMetric(pill) }
              />
            )) }
          </div>
        ) }
        { (metricCount < MAX_METRICS) && (
          <div className="audience-metrics-form">
            <FormControl
              className="audience-metrics-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Metric*") }
              </FormLabel>
              <Dropdown
                key={ selectedMetric?.code }
                id="audience-metric-dropdown"
                buttonType="custom"
                placement="bottom"
                customButtonChildren={
                  (
                    <Input
                      id="audience-metric-dropdown-input"
                      className="audience-metric-dropdown-input"
                      autoComplete="off"
                      value={ selectedMetric?.name }
                      placeholder={ translate("Select") }
                      InputProps={ {
                        endAdornment: <ArrowDropDown className="audience-metric-dropdown-arrow-drop-down" />,
                      } }
                      contentEditable={ false }
                    />
                  )
                }
              >
                <List disablePadding={ true }>
                  { audienceMetrics.map((metric) => (
                    <ListItem
                      disablePadding={ true }
                      disableGutters={ true }
                      key={ metric.code }
                    >
                      <ListItemButton
                        id={ `audience-metric-${ metric.name.toLowerCase().replaceAll(" ", "_") }` }
                        onClick={ () => showMetricForm(metric.code) }
                      >
                        <ListItemText
                          primary={ metric.name }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))
                  }
                </List>
              </Dropdown>
            </FormControl>
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.GENDER) && (
              <AudienceGenderForm label={ selectedMetric.name } setSelected={ setMetricValue } />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS) && (
              <AudienceFamilyStatusForm
                label="Single/Married:Parents/Non-parents"
                setSelected={ setMetricValue }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE) && (
              <AudienceMetricDropdownForm
                label={ selectedMetric.name }
                setSelected={ setMetricValue }
                options={ Object.keys(GraphQL.AgeRange).map((key) => key as GraphQL.AgeRange) }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE) && (
              <AudienceMetricDropdownForm
                label={ selectedMetric.name }
                setSelected={ setMetricValue }
                options={ Object.keys(GraphQL.IncomeBrackets).map((key) => key as GraphQL.IncomeBrackets) }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES) && (
              <AudienceMetricDropdownForm
                label={ selectedMetric.name }
                setSelected={ setMetricValue }
                options={ Object.keys(GraphQL.Ethnicity).map((key) => key as GraphQL.Ethnicity) }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS) && (
              <AudienceMetricDropdownForm
                label={ selectedMetric.name }
                setSelected={ setMetricValue }
                options={ Object.keys(GraphQL.Religion).map((key) => key as GraphQL.Religion) }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES) && (
              <AudienceLocationForm
                label={ selectedMetric.name }
                setSelected={ setMetricValue }
                locationType={ GraphQL.AudienceLocationType.Country }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.STATES) && (
              <AudienceLocationForm
                label={ selectedMetric.name }
                setSelected={ setMetricValue }
                locationType={ GraphQL.AudienceLocationType.State }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.CITIES) && (
              <AudienceLocationForm
                label={ selectedMetric.name }
                setSelected={ setMetricValue }
                locationType={ GraphQL.AudienceLocationType.City }
              />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS) && (
              <AudienceOccupationForm label={ selectedMetric.name } setSelected={ setMetricValue } />
            ) }
            { (selectedMetric?.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES) && (
              <AudienceAffinitiesForm label={ selectedMetric.name } setSelected={ setMetricValue } />
            ) }
            <Button
              id="add-audience-metric-button"
              className="audience-metrics-form-add-metric-button"
              label={ translate("+ Add Metric") }
              isPrimary={ false }
              onClick={ addMetricToList }
            />
          </div>
        ) }
        <div className="audience-metrics-form-cta">
          <Button
            id="audience-metrics-form-save-button"
            label={ translate("Save") }
            isPrimary={ true }
            fullWidth={ true }
            onClick={ handleAudienceFormPersistence }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
