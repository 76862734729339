import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import {
  GridColDef,
  getGridSingleSelectOperators,
  getGridBooleanOperators,
  getGridStringOperators,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid"
import { useSearchParams } from "react-router-dom"

import DataGrid from "../../../DataGrid"
import { DeliverableFragment } from "../../../../graphql"
import EmptySplash from "../../../EmptySplash"
import EntityInfoRow from "../../../EntityInfoRow"
import { shorthandNumber } from "../../../../util/miscHelper"
import Pill from "../../../Pill"
import Avatar from "../../../Avatar"
import * as GraphQL from "../../../../graphql"
import ContextMenu from "../CampaignDeliverableRowContextMenu"
import "./recent-deliverables-table.sass"
import StatusDeliverable from "../../../StatusDeliverable"
import { Scope, Success } from "../../../../util/types"
// import { isError } from "../../../../util/apiClient"
import { useSelector, useDispatch } from "../../../../state/hooks"
import { openDeliverableContentModal } from "../../../../state/campaignDeliverableContentModalSlice"
import { refreshCampaign } from "../../../../state/campaignSlice"
import HoverableCopyableText from "./HoverableCopyableText"
import Tooltip from "../../../Tooltip"

const statusOrder = {
  [GraphQL.DeliverableStatus.AwaitingContent]: 0,
  [GraphQL.DeliverableStatus.Pending]: 1,
  [GraphQL.DeliverableStatus.Finalized]: 2,
  [GraphQL.DeliverableStatus.Uploaded]: 3,
  [GraphQL.DeliverableStatus.Live]: 4,
}

type Props = {
  deliverables: DeliverableFragment[]
  campaign: Success<GraphQL.CampaignQuery>
}

interface TTCMParams {
  row: {
    network: GraphQL.Network;
    tiktok?: {
      tiktokTcmCampaign?: {
        name?: string;
      };
    };
  };
}

export default function RecentDeliverablesTable({ deliverables, campaign }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.RecentDeliverablesTable" })
  const { scopes } = useSelector(({ user }) => user)
  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const NoDeliverablesOverlay = useCallback(() => (
    <EmptySplash bodyText={ translate("No Recent Deliverables") } />
  ), [ translate ])

  const getTTCMCampaignName = (params: TTCMParams) => params.row.network === GraphQL.Network.Tiktok
    ? (params.row.tiktok?.tiktokTcmCampaign?.name || translate("NOT LINKED"))
    : ""

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "account",
      headerName: translate("Account"),
      sortable: true,
      filterable: true,
      align: "left",
      alignHeader: "center",
      headerClassName: "account-header",
      filterOperators: getGridStringOperators(),
      valueGetter(params) {
        return params.row.account
      },
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          network={ params.row.network }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 275,
    },
    {
      field: "network",
      headerName: translate("Network"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        {
          label: translate("Facebook"),
          value: GraphQL.Network.Facebook,
        },
        {
          label: translate("Instagram"),
          value: GraphQL.Network.Instagram,
        },
        {
          label: translate("Snapchat"),
          value: GraphQL.Network.Snapchat,
        },
        {
          label: translate("TikTok"),
          value: GraphQL.Network.Tiktok,
        },
        {
          label: translate("Youtube"),
          value: GraphQL.Network.Youtube,
        },
      ],
      filterOperators: getGridSingleSelectOperators(),
      valueGetter(params) {
        return params.row.network
      },
      renderCell: (params) => (
        params.row.network
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "title",
      headerName: translate("Title"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      type: "string",
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.name
      },
      renderCell: (params) => {
        const { name } = params.row
        if (!name || typeof name !== "string") return null
        return (
          <HoverableCopyableText
            text={ name }
            containerClass="table-title"
            contentClass="table-title-content"
          />
        )
      },
      flex: 0.7,
      maxWidth: 300,
      minWidth: 200,
      align: "left",
    },
    {
      field: "caption",
      headerName: translate("Post Caption"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        const { caption } = params.row
        if (!caption || typeof caption !== "string") return null

        return (
          <HoverableCopyableText
            text={ caption }
            containerClass="table-caption"
            contentClass="table-caption-content"
          />
        )
      },
      flex: 0.7,
      maxWidth: 400,
      minWidth: 200,
      align: "left",
    },
    {
      field: "media",
      headerName: translate("Media"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const media = params.row.media && params.row.media.length > 0 ? params.row.media[0] : undefined
        if (!media) return null
        return (
          <Avatar
            className="table-media"
            variant="rounded"
            src={ media?.media?.url?.address || "" }
          />
        )
      },
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "feedback",
      headerName: translate("Feedback"),
      sortable: true,
      filterable: true,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.feedback || 0
      },
      disableColumnMenu: false,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.feedback) } />
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "status",
      headerName: translate("Status"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        GraphQL.DeliverableStatus.AwaitingContent,
        GraphQL.DeliverableStatus.Pending,
        GraphQL.DeliverableStatus.Finalized,
        GraphQL.DeliverableStatus.Live,
        GraphQL.DeliverableStatus.Uploaded,
      ],
      sortComparator: (v1: GraphQL.DeliverableStatus, v2: GraphQL.DeliverableStatus) => statusOrder[v1] - statusOrder[v2],
      filterOperators: getGridSingleSelectOperators(),
      renderCell: (params) => (
        <StatusDeliverable
          deliverableStatus={ params.row.status }
        />
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "tiktok",
      headerName: translate("TCM Campaign"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.tiktok?.tiktokTcmCampaign?.name || translate("NOT LINKED")
      },
      renderCell: (params) => (
        <Tooltip
          classes={ { tooltip: "cp-tooltip cp_campaign-recent-deliverables-table-tooltip" } }
          title={ getTTCMCampaignName(params) }
          placement="top"
          arrow={ true }
          disableHoverListener={ params.row.network !== GraphQL.Network.Tiktok }
        >
          <p className="table-ttcm-content">
            { params.row.network === GraphQL.Network.Tiktok
              ? (params.row.tiktok?.tiktokTcmCampaign?.name || translate("NOT LINKED"))
              : ""
          }
          </p>
        </Tooltip>
      ),
      flex: 0.7,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="column-context">
          <ContextMenu deliverable={ params.row.deliverable } />
        </div>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ], [ translate ])

  const topSixDeliverables = deliverables.slice(0, 5)
  const deliverablesCronologicalOrder = topSixDeliverables.sort((a, b) => b.created - a.created)

  const filteredColumns = React.useMemo(() => {
    // if (campaign === "loading" || campaign === "init" || isError(campaign)) return COLUMNS
    if (
      !campaign.payload.campaign.enableTiktok
      || !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) return COLUMNS.filter((column) => column.field !== "tiktok")
    return COLUMNS
  }, [ campaign, scopes ])

  return (
    <DataGrid
      className="cp_campaign-recent-deliverables-table"
      columnVisibilityModel={ {
        network: false,
      } }
      getRowId={ (row) => row.id }
      disableRowSelectionOnClick={ true }
      rowHeight={ 90 }
      columnHeaderHeight={ 40 }
      columns={ filteredColumns }
      disableColumnReorder={ true }
      hideFooter={ true }
      rows={ deliverablesCronologicalOrder.map(({
        id,
        deliverableCaption,
        name,
        deliverableMedia,
        campaignNetworkAccount,
        tiktokTcmOrder,
        contentStatus,
      }, i) => ({
        account: campaignNetworkAccount.socialAccount.userName,
        profilePictureUrl: campaignNetworkAccount.socialAccount.profilePictureUrl,
        followers: campaignNetworkAccount.socialAccount.socialAccountStatistics.followers,
        network: campaignNetworkAccount.socialAccount.network,
        id,
        name,
        caption: deliverableCaption?.text || "",
        media: deliverableMedia,
        status: contentStatus,
        deliverable: deliverables[i],
        // eslint-disable-next-line max-len
        feedback: (deliverableMedia || []).reduce((previous, media) => previous + (media?.feedback?.length || 0), 0) + (deliverableCaption?.feedback || []).length,
        tiktok: tiktokTcmOrder,
      })) }
      slots={ {
        noResultsOverlay: NoDeliverablesOverlay,
        noRowsOverlay: NoDeliverablesOverlay,
      } }
      pinnedColumns={
          {
            left: [ "account" ],
            right: [ "ellipsisMenu" ],
          }
        }
      onRowClick={ (params) => {
        setSearchParams({ deliverableId: params.row.deliverable.id })
        dispatch(openDeliverableContentModal({
          deliverable: params.row.deliverable,
          campaign: campaign.payload.campaign,
          onClose: async (edited: boolean) => {
            searchParams.delete("deliverableId")
            setSearchParams(searchParams)
            if (edited) await dispatch(refreshCampaign())
          },
        }))
      } }
    />
  )
}
