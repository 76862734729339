import React from "react"

import "./history.sass"
import { useQuery } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LoadingIndicator from "../LoadingIndicator"
import {
  DeliverableHistoryOutput,
  DeliverableQueryVariables,
  GET_DELIVERABLE_HISTORY,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import HistoryItem from "./HistoryItem"

function ModalCampaignDeliverableHistory(): React.JSX.Element {
  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableHistory" })

  const {
    data, loading,
  } = useQuery<DeliverableHistoryOutput, DeliverableQueryVariables>(GET_DELIVERABLE_HISTORY, {
    variables: {
      deliverableId,
    },
    context: {
      apiVersion: "v2",
    },
    skip: !deliverableId,
  })

  if (data === undefined) return <></>

  if (loading) return <LoadingIndicator />

  if (data === undefined && !loading) return <> </>

  const { deliverableHistoryItems } = data

  if (deliverableHistoryItems.length === 0) return <></>

  return (
    <div className="cp_modal-campaign-deliverable-v2-history-tab_component">
      <p>{ translate("Deliverable History") }</p>
      <div className="deliverable-history-items-container">
        { deliverableHistoryItems.map(
          (deliverableHistoryItem) => <HistoryItem deliverableHistoryItem={ deliverableHistoryItem } />,
        ) }
      </div>
    </div>
  )
}

export default ModalCampaignDeliverableHistory
