import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  CheckCircleOutline,
  RemoveCircleOutline,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material"
import dayjs from "dayjs"
import {
  DeliverableHistoryItem,
  DeliverableHistoryKind,
  DeliverableStatus,
  UserVote,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import EntityInfoRow from "../EntityInfoRow"
import * as Constant from "../../util/constant"
import { urlRegex } from "../../util/miscHelper"
import "./history-item.sass"

type Props = {
  deliverableHistoryItem: DeliverableHistoryItem
}

const STATUS_HELPERS = {
  [DeliverableStatus.DRAFT]: "DRAFT",
  [DeliverableStatus.FINALIZED]: "FINALIZED",
  [DeliverableStatus.LIVE]: "LIVE",
  [DeliverableStatus.PENDING_FEEDBACK]: "PENDING FEEDBACK",
  [DeliverableStatus.PENDING_REVISION]: "PENDING REVISION",
}

export default function HistoryItem({ deliverableHistoryItem }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableHistory" })

  const {
    agent,
    id,
    kind,
    payload,
    timestamp,
    isVisible,
  } = deliverableHistoryItem

  const ApprovedTag = (
    <div className="status-tag-container">
      <CheckCircleOutline className="approve-icon" />
      <p>{ translate("Approved") }</p>
    </div>
  )

  const ApproveWSuggestionsTag = (
    <div className="status-tag-container">
      <CheckCircleOutline className="approve-icon" />
      <p>{ translate("Approve w/ Suggestions") }</p>
    </div>
  )

  const FinalizedFeedbackTag = (
    <div className="status-tag-container">
      <CheckCircleOutline className="approve-icon" />
      <p>{ translate("Finalized Feedback") }</p>
    </div>
  )

  const RevisionRequestedTag = (
    <div className="status-tag-container">
      <RemoveCircleOutline className="request-revision-icon" />
      <p>{ translate("Revision Requested") }</p>
    </div>
  )

  const [ historyItemContent, statusTag ] = useMemo(() => {
    switch (kind.toUpperCase() as DeliverableHistoryKind) {
      // 1. When the Reviewer user leaves feedback on Media
      case DeliverableHistoryKind.DELIVERABLE_ITEM_COMMENT_CREATED:
        if (payload?.__typename !== "DeliverableItemFeedbackHistory") return [ null, null ]
        if (urlRegex.test(payload.mediaContent)) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Left feedback on media") }</p>
              <div className="history-item-details">
                <img src={ payload.mediaContent } alt="" />
                <p>{ payload.feedbackContent }</p>
              </div>
            </div>
          ), RevisionRequestedTag ]
        }
        // 2. When the reviewer leaves feedback on the caption
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Left feedback on caption") }</p>
            <div className="history-item-details">
              <p>{ payload.feedbackContent }</p>
            </div>
          </div>
        ), RevisionRequestedTag ]

      case DeliverableHistoryKind.DELIVERABLE_ITEM_VOTE_CREATED:
        // 3. Vote to approve Media
        if (payload?.__typename !== "DeliverableItemVoteHistory") return [ null, null ]
        if (urlRegex.test(payload.mediaContent)) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Voted to approve media") }</p>
              <div className="history-item-details">
                <img src={ payload.mediaContent } alt="" />
              </div>
            </div>
          ), ApprovedTag ]
        }
        // 4. Vote to approve Caption
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Voted to approve caption") }</p>
            <div className="history-item-details">
              { payload.mediaContent }
            </div>
          </div>
        ), ApprovedTag ]

      case DeliverableHistoryKind.DELIVERABLE_VOTE_CREATED:
        // 5. When the Reviewer user Approves the deliverable
        if (payload?.__typename !== "DeliverableVoteHistory") return [ null, null ]
        if (payload.voteType === UserVote.APPROVED) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Voted to approve deliverable") }</p>
            </div>
          ), ApprovedTag ]
        }
        // 6. When the Reviewer user Approves w/Suggestions
        if (payload.voteType === UserVote.APPROVED_WITH_SUGGESTIONS) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Voted to approve w/ suggestions") }</p>
              <div className="history-item-details">
                <p>{ payload.comment }</p>
              </div>
            </div>
          ), ApproveWSuggestionsTag ]
        }
        // 7. When the Reviewer user Requests a Revision
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Requested Revision") }</p>
            <div className="history-item-details">
              <p>{ payload.comment }</p>
              { /* Some Request Revision icon? */ }
            </div>
          </div>
        ), RevisionRequestedTag ]

        // 8. When the Reviewer Finalizes Feedback
      case DeliverableHistoryKind.DELIVERABLE_FINALIZED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Finalized group feedback") }</p>
          </div>
        ), FinalizedFeedbackTag ]
        // 9. Deliverable status changed
      case DeliverableHistoryKind.DELIVERABLE_STATUS_UPDATED:
        if (payload?.__typename !== "DeliverableStatusChangeHistory") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">
              { `${ translate("Deliverable") } ${ STATUS_HELPERS[payload.deliverableStatus] }` }
            </p>
          </div>
        ), null ]
      case DeliverableHistoryKind.DELIVERABLE_CREATED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Created deliverable") }</p>
          </div>
        ), null ]
      case DeliverableHistoryKind.REVIEWER_GROUP_ASSIGNED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Reviewer Group Assigned") }</p>
          </div>
        ), null ]
      case DeliverableHistoryKind.MEDIA_ACCEPTED:
        if (payload?.__typename !== "DeliverableItemMediaContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Accepted media") }</p>
          </div>
        ), ApprovedTag ]
      case DeliverableHistoryKind.MEDIA_CREATED:
        if (payload?.__typename !== "DeliverableItemMediaContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <div className="history-item-details">
              <img src={ payload.mediaContent } alt="" />
              <p className="history-item-action">{ translate("Added media") }</p>
            </div>
          </div>
        ), null ]
      case DeliverableHistoryKind.MEDIA_DELETED:
        if (payload?.__typename !== "DeliverableItemMediaContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Removed media") }</p>
            <div className="history-item-details">
              <img src={ payload.mediaContent } alt="" />
            </div>
          </div>
        ), null ]
      case DeliverableHistoryKind.TEXT_CREATED:
        if (payload?.__typename !== "DeliverableItemMediaContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Updated caption") }</p>
          </div>
        ), null ]
      case DeliverableHistoryKind.TEXT_DELETED:
        if (payload?.__typename !== "DeliverableItemMediaContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Deleted caption") }</p>
            <div className="history-item-details">
              { payload.mediaContent }
            </div>
          </div>
        ), null ]
      case DeliverableHistoryKind.TEXT_ACCEPTED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Accepted caption") }</p>
          </div>
        ), ApprovedTag ]
      default:
        return [ null, null ]
    }
  }, [ kind ])

  return (
    <div className="cp_component_deliverable_v2_history-item" key={ id }>
      <div className="main-info">
        <EntityInfoRow
          className="deliverable-history-entity-info-row"
          avatarSrc={ agent.avatarUrl }
          name={ agent.userName }
          subInfo={ agent.reviewerGroupName ? `${ agent.reviewerGroupName } | ${ agent.customerName }` : agent.customerName }
          copyDisabled={ true }
        />
        { statusTag }
      </div>
      <div className="sub-info">
        <div className="history-attributes">
          { isVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined /> }
          <p>{ dayjs(timestamp).format(Constant.LONGFORM_DATE_TIME) }</p>
        </div>
        { historyItemContent }
      </div>
    </div>
  )
}
