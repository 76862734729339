import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import Tabs, { TabLabelProps } from "../Tabs"
import AccountInsightsVisualizations from "./VisualizationPreviewTabs/AccountInsightsVisualizations"
import AudienceVisualizations from "./VisualizationPreviewTabs/AudienceVisualizations"
import ContentVisualizations from "./VisualizationPreviewTabs/ContentVisualizations"
import AppendixVisualization from "./VisualizationPreviewTabs/AppendixVisualization"

// eslint-disable-next-line no-shadow
export enum LCPAccountTab {
  ACCOUNT_INSIGHTS = "account-insights",
  AUDIENCE = "audience",
  CONTENT = "content",
  APPENDIX = "appendix",
}

interface LCPAccountTabLabel extends TabLabelProps {
  path: LCPAccountTab.ACCOUNT_INSIGHTS | LCPAccountTab.AUDIENCE | LCPAccountTab.CONTENT | LCPAccountTab.APPENDIX
}

export default function ConfigurationPreviewAccountTabs() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })

  const [ tabIndex, setTabIndex ] = React.useState<number>(0)

  const tabs: LCPAccountTabLabel[] = React.useMemo((): LCPAccountTabLabel[] => {
    const t: LCPAccountTabLabel[] = [
      { label: translate("Account Insights"), path: LCPAccountTab.ACCOUNT_INSIGHTS },
      { label: translate("Audience"), path: LCPAccountTab.AUDIENCE },
      { label: translate("Content"), path: LCPAccountTab.CONTENT },
      { label: translate("Appendix"), path: LCPAccountTab.APPENDIX },
    ]
    return t
  }, [ ])

  const tabContent = React.useMemo(() => {
    // Get tab that is selected
    const tabPath: LCPAccountTab = tabs[tabIndex].path

    // Determine which tab to render
    switch (tabPath) {
      case LCPAccountTab.ACCOUNT_INSIGHTS:
        return <AccountInsightsVisualizations />
      case LCPAccountTab.AUDIENCE:
        return <AudienceVisualizations />
      case LCPAccountTab.CONTENT:
        return <ContentVisualizations />
      case LCPAccountTab.APPENDIX:
        return <AppendixVisualization />
      default:
        return <p>Error or this tab does not exist yet</p>
    }
  }, [ tabIndex ])

  return (
    <div className="cp_lcp-tabs_container">
      <Tabs
        className="cp_lcp-tabs_container-tabs"
        handleChange={ setTabIndex }
        tabs={ tabs }
        defaultValue={ tabIndex }
        controlledValue={ tabIndex }
        controls={ (
          <>
            <p className="cp_lcp-tabs_container-tabs-placeholder">
              Placeholder
            </p>
          </>
        ) }
      />
      <div className="cp_lcp-tabs_container-content">
        { tabContent }
      </div>
    </div>
  )
}
