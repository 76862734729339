import React, { useMemo } from "react"
import dayjs from "dayjs"

import * as Constant from "../../util/constant"
import Divider from "../Divider"

import "./feedback-card.sass"
import { DeliverableItemFeedbackItem } from "../../state/campaignDeliverableContentModalV2Slice/types"

interface DeliverableFeedbackCardProps {
  feedback: DeliverableItemFeedbackItem
}

function DeliverableFeedbackCard({ feedback }: DeliverableFeedbackCardProps): React.JSX.Element {
  const formattedDate = useMemo(() => dayjs(feedback.timestamp)
    .format(Constant.LONGFORM_DATE_TIME), [ feedback.timestamp ])
  return (
    <div className="cp_media-feedback-card_component">
      <div className="cp_media-feedback-card_component-header">
        <div className="cp_media-feedback-card_component-header-name">
          <p className="cp_media-feedback-card_component-header-name-tag">
            @
            { feedback.user.userName }
          </p>
          <p className="cp_media-feedback-card_component-header-name-company">
            { feedback.user.customerName }
          </p>
        </div>
        <div className="cp_media-feedback-card_component-header-date">
          { formattedDate }
        </div>
      </div>
      <Divider />
      <div
        className="cp_media-feedback-card_component-body"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: feedback.content } }
      />
    </div>
  )
}

export default DeliverableFeedbackCard
