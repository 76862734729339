import React, {
  useCallback,
  useState,
  useEffect,
} from "react"
import { useTranslation } from "react-i18next"
import { Grid, InputAdornment } from "@mui/material"
import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/client"
import * as GraphQL from "../../../../graphql"
import { setModalOpen } from "../../../../state/assignReviewersModalSlice"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import Modal from "../../../Modal"
import PermissionsToggle from "./PermissionsToggle"
import CustomersAutocomplete from "./CustomersAutocomplete"
import UserAutocomplete from "./UserAutocomplete"
import "./campaign-assign-reviewers-modal.sass"
import { pushToast } from "../../../../state/toastSlice"
import * as API from "../../../../util/apiClient"
import {
  CREATE_REVIEWER_GROUP,
  EDIT_REVIEWER_GROUP,
  EDIT_USER_PERMISSIONS,
  GET_REVIEWER_GROUPS,
} from "../../../../graphqlV2"
import Input from "../../../Input"
import { ASSIGN_REVIEWERS_GROUP_NAME_MAX_LENGTH } from "../../../../util/constant"

type permissionType = "FINALIZE" | "APPROVE" | "COMMENT"
type UserType = GraphQL.CustomerTeamMemberFragment

type User = {
  userId: string
  permission: permissionType
  user: UserDetails
}

type UserDetails = {
  fullName: string
  avatarUrl: string
}

type UserAssignmentUpdate =
  | { create: { userId: string; permission: string } }
  | { remove: { userId: string } }

export default function AssignReviewersModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AssignReviewersModal" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()

  const {
    modalOpen,
    isAssignReviewerGroup,
    isEditGroup,
    isEditUser,
    data,
  } = useSelector(({ assignReviewersModal }) => assignReviewersModal)

  const [ groupName, setGroupName ] = useState("")
  const [ submitLoading, setSubmitLoading ] = useState(false)
  const [ selectedCustomer, setSelectedCustomer ] = useState<GraphQL.CustomerFragment | null>(null)
  const [ selectedUser, setSelectedUser ] = useState<User>()
  const [ users, setUsers ] = useState<User[]>([])
  const [ usersLoading, setUsersLoading ] = useState(false)
  const [ selectedUsers, setSelectedUsers ] = useState<User[]>([])
  const { campaignID: campaignId } = useParams()
  const [ removedUsers, setRemovedUsers ] = useState<User[]>([])
  const [ mutateCreateReviewerGroup ] = useMutation(CREATE_REVIEWER_GROUP, {
    refetchQueries: [ {
      query: GET_REVIEWER_GROUPS,
      variables: {
        campaignId: campaignId || "",
      },
      context: {
        apiVersion: "v2",
      },
    } ],
    awaitRefetchQueries: true,
  })

  const [ mutateUserPermission ] = useMutation(EDIT_USER_PERMISSIONS)

  const [ mutateEditReviewerGroup ] = useMutation(EDIT_REVIEWER_GROUP)

  const resetFields = () => {
    setGroupName("")
    setSelectedCustomer(null)
    setSelectedUser(undefined)
    setUsers([])
    setUsersLoading(false)
    setSubmitLoading(false)
    setSelectedUsers([])
    setRemovedUsers([])
  }

  const onClose = () => {
    dispatch(setModalOpen({
      modalOpen: false, isAssignReviewerGroup: false, isEditGroup: false, isEditUser: false, data: undefined,
    }))
    resetFields()
  }

  const createReviewerGroup = async () => {
    const input = {
      campaignId,
      displayName: data?.groupNm || groupName,
      description: "",
      userAssignments: selectedUsers.map((usr) => ({
        userId: usr.userId,
        permission: usr.permission,
      })),
    }
    await mutateCreateReviewerGroup({
      variables: {
        input,
      },
      context: {
        apiVersion: "v2",
      },
    })
  }

  const editPermissions = async () => {
    const input = {
      campaignId,
      groupId: data?.groupId,
      userAssignmentUpdates: selectedUsers.map((usr) => ({
        update: {
          userId: usr.userId,
          permission: usr.permission,
        },
      })),
    }
    await mutateUserPermission({
      variables: {
        input,
      },
      context: {
        apiVersion: "v2",
      },
    })
  }

  const editReviewerGroup = async () => {
    const input: {
    campaignId: string | undefined;
    groupId: string | undefined;
    displayName: string;
    userAssignmentUpdates: UserAssignmentUpdate[];

  } = {
    campaignId,
    groupId: data?.groupId,
    displayName: groupName,
    userAssignmentUpdates: selectedUsers.filter((usr) => !removedUsers.some((removedUser) => removedUser.userId === usr.userId))
      .map((usr) => ({
        create: {
          userId: usr.userId,
          permission: usr.permission,
        },

      })),
  }

    const removedUsersInput: UserAssignmentUpdate[] = removedUsers.map((usr) => ({
      remove: {
        userId: usr.userId,
      },
    }))

    if (removedUsersInput.length > 0) {
      input.userAssignmentUpdates = [ ...input.userAssignmentUpdates, ...removedUsersInput ]
    }

    await mutateEditReviewerGroup({
      variables: {
        input,
      },
      context: {
        apiVersion: "v2",
      },
    })
  }

  const onSubmit = async () => {
    setSubmitLoading(true)
    try {
      if (isAssignReviewerGroup) {
        await createReviewerGroup()
      } else if (isEditUser) {
        await editPermissions()
      } else if (isEditGroup) {
        await editReviewerGroup()
      } else {
        return
      }

      dispatch(pushToast({
        message: isAssignReviewerGroup ? translate("Successfully Created Group!")
          : translate("Successfully Updated Group!"),
        type: "success",
      }))
      onClose()
    } catch (error) {
      dispatch(pushToast({
        message: isAssignReviewerGroup ? translate("Failed to create group")
          : translate("Failed to update group"),
        type: "error",
      }))
    } finally {
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    const fetchUsers = async () => {
      if (selectedCustomer?.id) {
        setUsersLoading(true)
        await searchUsers("")
        setUsersLoading(false)
      } else {
        resetFields()
      }
    }
    fetchUsers()
  }, [ selectedCustomer ])

  useEffect(() => {
    if (data?.selectedUsers) {
      setSelectedUsers(data.selectedUsers)
    }
  }, [ data?.selectedUsers ])

  useEffect(() => {
    if (data?.groupNm) {
      setGroupName(data.groupNm)
    }
  }, [ isEditGroup ])

  const searchUsers = async (startsWith: string) => {
    if (!selectedCustomer?.id) return
    const result = await API.searchTeamMembersForCustomer({
      startsWith,
      page: 1,
      limit: 5000,
      customerId: selectedCustomer.id,
    })

    if (API.isSuccess(result)) {
      const newUsers = result.payload.searchUsers.rows.map(workingUser) || []
      setUsers(newUsers)
    } else {
      setUsers([])
    }
  }

  const workingUser = (initialUser: UserType):User => ({
    userId: initialUser.id,
    permission: "COMMENT",
    user: {
      fullName: initialUser.contact.name,
      avatarUrl: initialUser.contact.avatar.url.address,
    },
  })

  const handleUserSelected = useCallback((user : User) => {
    setSelectedUser(user)
  }, [])

  const getPermission = (updatedPermission: permissionType): permissionType => {
    const prevPermission = selectedUser?.permission || "COMMENT"
    if (selectedUser) {
      const permissions: permissionType[] = [ "FINALIZE", "APPROVE", "COMMENT" ]
      if (updatedPermission === "COMMENT"
        || permissions.indexOf(updatedPermission) < permissions.indexOf(prevPermission)) return updatedPermission
      if (prevPermission === updatedPermission) {
        const nextIndex = (permissions.indexOf(updatedPermission) + 1) % permissions.length; return permissions[nextIndex]
      }
      if (permissions.indexOf(updatedPermission) > permissions.indexOf(prevPermission)) {
        const nextIndex = (permissions.indexOf(updatedPermission) + 1) % permissions.length
        return permissions[nextIndex]
      }
    }

    return prevPermission
  }

  const updatePermission = useCallback((permission: permissionType) => {
    if (selectedUser && permission) {
      const updatedPermission = getPermission(permission)
      setSelectedUser({ ...selectedUser, permission: updatedPermission })
      setSelectedUsers((prevUsers) => prevUsers.map((user) => user.userId === selectedUser.userId
        ? { ...user, permission: updatedPermission } : user))
    }
  }, [ selectedUser ])

  const handleRemoveUser = useCallback((user: User) => {
    if (selectedUser) {
      setSelectedUser(undefined)
    }
    setRemovedUsers((prevUsers) => [ ...prevUsers, user ])
    setSelectedUsers((prevUsers) => prevUsers.filter((usr) => usr.userId !== user.userId))
  }, [ selectedUser ])

  const isSaveDisabled = ((!data?.groupNm && groupName === ""))

  const onCheckedUser = useCallback((checkedUser: User) => {
    setSelectedUsers((prevUsers) => [ ...prevUsers, checkedUser ])
  }, [ selectedUsers ])

  return (
    <Modal
      className="cp_component_campaign-assign-reviewers-modal"
      open={ modalOpen }
      title={ isAssignReviewerGroup ? translate("Create a New Reviewer Group") : isEditGroup
        ? translate("Edit Reviewer Group") : translate("Edit User Permission") }
      subtitle={ isAssignReviewerGroup ? translate("Add Reviewer Group subject") : isEditGroup
        ? translate("Edit Reviewer Group Subject") : translate("Edit User Permission Subject") }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Save") }
      secondaryLabel={ translateCommon("Cancel") }
      primaryAction={ onSubmit }
      secondaryAction={ onClose }
      closeAction={ onClose }
      maxWidth="xl"
      disabled={ isSaveDisabled }
    >
      <div className="add-reviewer-group-container">
        <Grid container={ true } spacing={ 2 }>
          <Grid item={ true } xs={ 12 } lg={ 6 }>
            <Input
              id="group-name"
              className="edit-group-name"
              label={ `${ translate("GROUP NAME") } ` }
              placeholder={ translate("Group [#] Reviewers") }
              value={ isEditUser ? data?.groupNm : groupName }
              onChange={ (e) => setGroupName(e.target.value) }
              disabled={ isEditUser }
              InputProps={ {
                ...(!isEditUser
                  && {
                    endAdornment: (
                      <InputAdornment position="end">
                        { `${ groupName.length } / ${ ASSIGN_REVIEWERS_GROUP_NAME_MAX_LENGTH }` }
                      </InputAdornment>),
                    inputProps: { maxlength: ASSIGN_REVIEWERS_GROUP_NAME_MAX_LENGTH },
                  }),
              } }
            />
          </Grid>

          <Grid item={ true } xs={ 12 } lg={ 6 }>
            { isAssignReviewerGroup || isEditGroup ? (
              <>
                <p className="filter-by-customer">
                  { translate("FILTER BY CUSTOMER") }
                </p>
                <CustomersAutocomplete
                  selectedCustomer={ selectedCustomer }
                  setSelectedCustomer={ setSelectedCustomer }
                />
              </>
            ) : null }

          </Grid>
          <Grid item={ true } xs={ 12 } lg={ 6 }>
            <div className="user-filter-container">
              <p className="filter-by-customer">
                { isAssignReviewerGroup ? translate("FILTER BY USER") : translate("USERS ASSIGNED TO CAMPAIGN") }
              </p>
              { !usersLoading
                ? (
                  <UserAutocomplete
                    users={ users }
                    onUserSelect={ handleUserSelected }
                    onRemoveUser={ handleRemoveUser }
                    isEditUser={ isEditUser }
                    selectedUsers={ selectedUsers }
                    addFilteredUsers={ onCheckedUser }
                    selectedUser={ selectedUser }
                  />
                ) : (<LoadingIndicator size={ 50 } />) }
            </div>
          </Grid>
          <Grid item={ true } xs={ 12 } lg={ 6 }>
            { selectedUser && (
            <div className="permissions-toggle-container">
              <PermissionsToggle permission={ selectedUser.permission } onToggle={ updatePermission } />
            </div>
            ) }

          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
