import React from "react"

// Importing images
import { ReactComponent as FollowersOverTimeIcon } from "../../../asset/visualizations/LIST_FOLLOWERS_OVER_TIME_EX.svg"
import { ReactComponent as AudienceQualityIcon } from "../../../asset/visualizations/LIST_AUDIENCE_QUALITY_EX.svg"
import { ReactComponent as GenderFamlyStatusIcon } from "../../../asset/visualizations/LIST_GENDER_FAMILY_EX.svg"
import { ReactComponent as AgeIncomeEducationRangeIcon } from "../../../asset/visualizations/LIST_BASIC_EX.svg"
// eslint-disable-next-line max-len
import { ReactComponent as EthinicitiesLanguagesReligionsIcon } from "../../../asset/visualizations/LIST_ETHNICITY_LANG_RELIGION_EX.svg"
import { ReactComponent as CountriesStatesCitiesIcon } from "../../../asset/visualizations/LIST_LOCATION_EX.svg"
import { ReactComponent as VocationIcon } from "../../../asset/visualizations/LIST_VOCATION_EX.svg"
import { ReactComponent as AffinitiesIcon } from "../../../asset/visualizations/LIST_AFFINITIES_EX.svg"

export default function AudienceVisualizations() {
  return (
    <div className="cp_lcp-preview_example-visualizations">
      <FollowersOverTimeIcon />
      <AudienceQualityIcon />
      <GenderFamlyStatusIcon />
      <AgeIncomeEducationRangeIcon />
      <EthinicitiesLanguagesReligionsIcon />
      <CountriesStatesCitiesIcon />
      <VocationIcon />
      <AffinitiesIcon />
    </div>
  )
}
