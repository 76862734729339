import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  Avatar,
  Paper,
  Typography,
  Box,
  ListItemText,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { useMutation } from "@apollo/client"
import { useParams } from "react-router-dom"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import { openConfirmationModal } from "../../../../state/confirmationModalSlice"
import { useDispatch } from "../../../../state/hooks"
import {
  ReviewerGroup,
  ReviewerGroupUserAssignment,
  RemoveReviewerGroupUserAssignmentInput,
  RemoveReviewerGroupUserAssignmentResponse,
} from "../../../../graphqlV2/types/assignReviewerTypes"
import { REMOVE_GROUP, REMOVE_USER_FROM_GROUP } from "../../../../graphqlV2/mutations/assignReviewersMutations"
import { pushToast } from "../../../../state/toastSlice"
import { setModalOpen } from "../../../../state/assignReviewersModalSlice"
import { GET_REVIEWER_GROUPS } from "../../../../graphqlV2"

interface ReviewerGroupsListProps {
  groups: ReviewerGroup[]
  loading: boolean
}

export default function ReviewerGroupsList({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  groups, loading,
}: ReviewerGroupsListProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ReviewerGroupsList" })
  const [ groupMenuAnchor, setGroupMenuAnchor ] = useState<null | HTMLElement>(null)
  const [ userMenuAnchor, setUserMenuAnchor ] = useState<null | HTMLElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedGroup, setSelectedGroup ] = useState<ReviewerGroup | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedUser, setSelectedUser ] = useState<ReviewerGroupUserAssignment | null>(null)
  const { campaignID: campaignId } = useParams()
  const handleGroupMenuOpen = (event: React.MouseEvent<HTMLElement>, group: ReviewerGroup) => {
    setGroupMenuAnchor(event.currentTarget)
    setSelectedGroup(group)
  }

  const permissionss = [
    {
      id: "FINALIZE",
      titleKey: "Finalize Feedback",
    },
    {
      id: "APPROVE",
      titleKey: "Approve",
    },
    {
      id: "COMMENT",
      titleKey: "View & Leave Feedback",
    },
  ]
  const dispatch = useDispatch()
  const [ removeUserFromGroup ] = useMutation<
  RemoveReviewerGroupUserAssignmentResponse,
   RemoveReviewerGroupUserAssignmentInput
    >(
      REMOVE_USER_FROM_GROUP,
    )

  const [ removeGroup ] = useMutation(REMOVE_GROUP, {
    refetchQueries: [ {
      query: GET_REVIEWER_GROUPS,
      variables: {
        campaignId: campaignId || "",
      },
      context: {
        apiVersion: "v2",
      },
    } ],
    awaitRefetchQueries: true,
  })

  const handleUserMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    assignment: ReviewerGroupUserAssignment,
    group: ReviewerGroup,
  ) => {
    event.stopPropagation()
    setUserMenuAnchor(event.currentTarget)
    setSelectedUser(assignment)
    setSelectedGroup(group)
  }

  const handleMenuClose = () => {
    setGroupMenuAnchor(null)
    setUserMenuAnchor(null)
    setSelectedGroup(null)
    setSelectedUser(null)
  }

  const deleteUser = async () => {
    if (selectedGroup?.id && selectedUser?.userId && campaignId) {
      dispatch(openConfirmationModal({
        onConfirm: async () => {
          try {
            await removeUserFromGroup({
              variables: {
                groupId: selectedGroup.id,
                userId: selectedUser.userId,
                campaignId,
              },
              context: {
                apiVersion: "v2",
              },
            })
            await dispatch(pushToast({
              message: translate("Successfully Deleted User!"),
              type: "success",
            }))
          } catch (error) {
            dispatch(pushToast({
              message: translate("Failed to Delete User"),
              type: "error",
            }))
          }
          handleMenuClose()
        },
        title: translate("Delete User"),
        subtext: translate("Are you sure you want to delete this user from the reviewer group?"),
      }))
    }
  }
  // modalOpen: true, isEdit,users: selectedUsers, editUser: true, groupNm: selectedGroup?.displayName,
  const editPermissions = async () => {
    if (selectedGroup?.id && selectedUser?.userId && campaignId) {
      const selectedUsers = [ selectedUser ]

      await dispatch(setModalOpen({
        modalOpen: true,
        isEditUser: true,
        data: {
          selectedUsers, groupNm: selectedGroup.displayName, groupId: selectedGroup.id,
        },
      }))

      handleMenuClose()
    }
  }

  const editGroup = async () => {
    if (selectedGroup?.userAssignments && campaignId) {
      const selectedUsers = selectedGroup.userAssignments

      await dispatch(setModalOpen({
        modalOpen: true,
        isEditGroup: true,
        data: {
          selectedUsers, groupNm: selectedGroup.displayName, groupId: selectedGroup.id,
        },
      }))

      handleMenuClose()
    }
  }

  const deleteGroup = async () => {
    if (selectedGroup?.id && campaignId) {
      dispatch(openConfirmationModal({
        onConfirm: async () => {
          try {
            await removeGroup({
              variables: {
                input: {
                  groupId: selectedGroup.id,
                  campaignId,
                },
              },
              context: {
                apiVersion: "v2",
              },
            })
            await dispatch(pushToast({
              message: translate("Successfully Deleted Group!"),
              type: "success",
            }))
          } catch (error) {
            dispatch(pushToast({
              message: translate("Failed to Delete Group"),
              type: "error",
            }))
          }
          handleMenuClose()
        },
        title: translate("Delete Group"),
        subtext: (
          <p>
            { translate("Are you sure you want to delete this reviewer group?") }
            <br />
            { translate("If this group is assigned to Deliverables deleting the group will remove their access.") }
          </p>),
      }))
    }
  }

  return (
    <div className="reviewer-groups-list">
      <div className="groups-grid">
        { loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" width="100vw">
            <LoadingIndicatorCard />
          </Box>
        ) : groups.map((group) => (
          <Paper
            key={ group.id }
            elevation={ 0 }
            className="group-container"
          >
            <div className="group-header">
              <div>
                <Typography variant="h6" className="group-name">
                  { group.displayName }
                </Typography>
              </div>
              <IconButton
                onClick={ (e) => handleGroupMenuOpen(e, group) }
                aria-label={ translate("Group Actions") }
                className="user-actions"
              >
                <MoreHorizIcon />
              </IconButton>
            </div>
            <List className="users-list">
              {
                group.userAssignments?.map((assignment) => (
                  <ListItem key={ assignment.userId } className="user-item">
                    <div className="user-info">
                      <Avatar
                        src={ assignment.user.avatarUrl }
                        alt={ assignment.user.fullName }
                      >
                        { assignment.user.initials }
                      </Avatar>
                      <div className="user-details">
                        <Typography variant="subtitle1" fontWeight="bold" className="subtitle1">
                          { assignment.user.fullName }
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          textTransform="capitalize"
                          className="textSeconday"
                        >
                          { permissionss.find((perm) => perm.id === assignment.permission.toUpperCase())?.titleKey.toLowerCase() }
                        </Typography>
                      </div>
                    </div>
                    <IconButton
                      size="small"
                      onClick={ (e) => handleUserMenuOpen(e, assignment, group) }
                      aria-label={ translate("User Actions") }
                      className="user-actions"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </ListItem>
                ))
               }
            </List>

          </Paper>
        )) }
      </div>
      <div className="reviewer-groups-list-user-context-menu">
        <Menu
          anchorEl={ groupMenuAnchor }
          open={ Boolean(groupMenuAnchor) }
          onClose={ handleMenuClose }
          className="group-menu"
        >
          <MenuItem onClick={ editGroup } id="reviewer-groups-list-user-actions-edit-group">
            <ListItemText>
              { translate("Edit Group") }
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={ deleteGroup } id="reviewer-groups-list-user-actions-delete-group">
            <ListItemText>
              { translate("Delete Group") }
            </ListItemText>
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={ userMenuAnchor }
          open={ Boolean(userMenuAnchor) }
          onClose={ handleMenuClose }
          className="group-menu"
        >
          <MenuItem onClick={ editPermissions } id="reviewer-groups-list-user-actions-edit-permissions">
            <ListItemText>
              { translate("Edit Permissions") }
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={ deleteUser } id="reviewer-groups-list-user-actions-delete-user">
            <ListItemText>
              { translate("Delete User") }
            </ListItemText>

          </MenuItem>
        </Menu>
      </div>

    </div>
  )
}
