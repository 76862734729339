import { gql } from "@apollo/client"

export interface SocialAccountSummary {
  id: string;
  name: string;
  username: string;
  networkType: string;
  profilePictureUrl: string;
  followerCount: number;
}

export interface AssignReviewerGroupToDeliverableVariables {
  groupId: string
  deliverableId: string
  deliverableIdString: string
}

export interface AssignReviewerGroupToDeliverableOutput {
  reviewerGroup: {
    id: string
    userAssignments: ReviewerGroupUserAssignment[]
  }
}

export interface DeliverableQueryVariables {
  deliverableId: string;
}

export interface DeliverableQueryOutput {
  deliverable: Deliverable;
}

export interface DeliverableVoteOutput {
  deliverableVote: {
    userVote: UserVote
  }
}

export interface DeliverableCaptionItemsOutput {
  deliverableCaptionItems: DeliverableCaptionContainer;
}
export interface DeliverableMediaItemsOutput {
  deliverableMediaItems: DeliverableMediaItem[];
}

export interface DeliverablePermissionsOutput {
  deliverablePermissions: DeliverablePermissionsContainer;
}

export interface DeliverableItemFeedbackItemsOutput {
  deliverableItemFeedbackItems: DeliverableItemFeedbackItem[]
}

export interface DeliverableItemFeedbackItemsVariables {
  deliverableItemId: string;
}

export interface UpdateDeliverableStatusInput {
  deliverableId: string,
  status: DeliverableStatus
}

export interface UpdateDeliverableStatusOutput {
  deliverableId: string
  deliverableStatus: DeliverableStatus
}

// NOTE: Current instances of deliverableStatus field in GraphQL return as string
// This enum is a mirror of the enum values that come from the back-end
// eslint-disable-next-line no-shadow
export enum DeliverableStatus {
  DRAFT = "DRAFT",
  PENDING_FEEDBACK = "PENDING_FEEDBACK",
  PENDING_REVISION = "PENDING_REVISION",
  FINALIZED = "FINALIZED",
  LIVE = "LIVE",
}

// eslint-disable-next-line no-shadow
export enum ReviewerGroupStatus {
  UNDER_REVIEW = "UNDER_REVIEW",
  FEEDBACK_COMPLETE = "FEEDBACK_COMPLETE"
}

export interface FinalizeReviewerGroupFeedbackOutput {
  deliverableId: string
  currentGroupStatus: ReviewerGroupStatus
}

export interface UserGroupsForCampaignOutput {
  reviewerGroupsForCampaign: {
    id: string
    displayName: string
  }[]
}

export interface UserGroupsForCampaignVariables {
  campaignId: string
}

export interface Deliverable {
  id: string;
  name: string;
  deliverableStatus: string;
  socialAccount: SocialAccountSummary;
  note?: string;
  ttcmOrderId?: string;
  ttcmCampaignName?: string;
  ttcmLinkUrl?: string;
  canGenerateTtcmLinkUrl: boolean;
  currentGroupStatus: ReviewerGroupStatus
}

export interface DeliverableCaptionContainer {
  id: string
  caption: string
  approved: boolean
  feedbackCount: number
}

// eslint-disable-next-line no-shadow
export enum DeliverableMediaType {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE"
}

export interface DeliverableMediaItem {
  id: string
  imageUrl: string
  mediaType: DeliverableMediaType
  approved: boolean
  feedbackCount: number
}

export interface DeliverablePermissionsContainer {
  deliverableId: string
  canVoteOnDeliverable: boolean
  canFinalizeDeliverableFeedback: boolean
  canUpdateDeliverable: boolean
}

export interface UserInfo {
  id: string
  userName: string
  customerName: string
  }
export interface DeliverableItemFeedbackItem {
  id: string
  userId: string
  groupId: string
  timestamp: number
  content: string
  user: UserInfo
  }

// Queries

export const GET_DELIVERABLE = gql`
query GetDeliverable($deliverableId: ObfID!) {
  deliverable(deliverableId: $deliverableId) {
    id
    name
    deliverableStatus
    currentGroupStatus
    socialAccount {
      id
      username
      networkType
      profilePictureUrl
      followerCount
    }
    note
    ttcmOrderId
    ttcmCampaignName
    ttcmLinkUrl
    canGenerateTtcmLinkUrl
  }
}
`

export const GET_DELIVERABLE_CAPTION_ITEMS = gql`
query GetDeliverableItems($deliverableId: ObfID!) {
  deliverableCaptionItems(deliverableId: $deliverableId) {
    id
    caption
    approved
    feedbackCount
  }
}
`

export const GET_DELIVERABLE_MEDIA_ITEMS = gql`
query GetDeliverableItems($deliverableId: ObfID!) {
  deliverableMediaItems(deliverableId: $deliverableId) {
    id
    imageUrl
    mediaType
    approved
    feedbackCount
  }
}
`

export const GET_DELIVERABLE_PERMISSIONS = gql`
  query getDeliverablePermissions($deliverableId: ObfID!) {
    deliverablePermissions(deliverableId: $deliverableId) {
      deliverableId
      canVoteOnDeliverable
      canFinalizeDeliverableFeedback
      canUpdateDeliverable
    }
  }
`

export const GET_DELIVERABLE_USER_GROUP = gql`
  query GetDeliverableUserGroup($deliverableId: ObfID!) {
    currentDeliverableUserGroup(deliverableId: $deliverableId) {
      id
      userAssignments {
        id
        userId
        groupId
        permission
        createdAt
        updatedAt
        userVote (deliverableId: $deliverableId)
        user {
          fullName
          avatarUrl
        }
      }
    }
  }
`

export const GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS = gql`
  query GetDeliverableItemFeedbackItems($deliverableItemId: ObfID!) {
    deliverableItemFeedbackItems(deliverableItemId: $deliverableItemId) {
      id
      userId
      groupId
      timestamp
      content
      user {
        id
        userName
        customerName
      }
    }
  }
`

export const CAST_DELIVERABLE_VOTE = gql`
  mutation CastDeliverableVote($deliverableId: ObfID!, $voteType: UserVote!, $comment: String) {
    castDeliverableVote(input: { deliverableId: $deliverableId, voteType: $voteType, comment: $comment }) {
      deliverableId
    }
  }
`

export const GET_DELIVERABLE_VOTE = gql`
  query DeliverableVote ($deliverableId: ObfID!) {
    deliverableVote(deliverableId:$deliverableId) {
      userVote
    }
  }
`

export const GET_REVIEWER_GROUPS_FOR_CAMPAIGN = gql`
  query GetReviewerGroupsForCampain ($campaignId: ObfID!) {
    reviewerGroupsForCampaign(campaignId: $campaignId) {
      id
      displayName
    }
  }
`

export const ASSIGN_REVIEWER_GROUP_TO_DELIVERABLE = gql`
  mutation AssignReviewerGroupToDeliverable(
    $deliverableId: ObfID!,
    $groupId: ID!
  ) {
    assignReviewerGroupToDeliverable(input: {
      groupId: $groupId, deliverableId: $deliverableId
    }) {
      reviewerGroup {
        id
        userAssignments {
          id
          userId
          groupId
          permission
          createdAt
          updatedAt
          userVote (deliverableId: $deliverableId)
          user {
            fullName
            avatarUrl
          }
        }
      }
    }
  }
`

export const FINALIZE_REVIEWER_GROUP_FEEDBACK = gql`
  mutation FinalizeReviewerGroupFeedback($deliverableId: ObfID!) {
    finalizeReviewerGroupFeedback(input: { deliverableId: $deliverableId }) {
      deliverableId
      currentGroupStatus
    }
  }
`

export const GET_DELIVERABLE_HISTORY = gql`
  query GetDeliverableHistoryItems($deliverableId: ObfID!) {
    deliverableHistoryItems(deliverableId: $deliverableId) {
      id
      kind
      timestamp
      isVisible
      agent {
        id
        userName
        avatarUrl
        customerName
        reviewerGroupName
      }
      payload {
        ...on DeliverableItemFeedbackHistory {
          feedbackContent
          mediaContent
        }
        ... on DeliverableItemVoteHistory {
          itemVoteType
          mediaContent
        }
        ... on DeliverableVoteHistory {
          voteType
          comment
        }
        ... on DeliverableStatusChangeHistory {
          deliverableStatus
        }
        ... on DeliverableItemMediaContent {
          mediaContent
        }
      }
    }
  }
`

export const UPDATE_DELIVERABLE_STATUS = gql`
  mutation UpdateDeliverableStatus($deliverableId: ObfID!, $status: DeliverableStatus!) {
    updateDeliverableStatus(input: { deliverableId: $deliverableId, status:$status }) {
      deliverableId
      deliverableStatus
    }
  }
`

// NOTE: Copied over for type secruity since graphQL returns a string type
// eslint-disable-next-line no-shadow
export enum DeliverableHistoryKind {
  // New Types for V2 flow
  DELIVERABLE_ITEM_COMMENT_CREATED = "DELIVERABLE_ITEM_COMMENT_CREATED",
  DELIVERABLE_ITEM_VOTE_CREATED = "DELIVERABLE_ITEM_VOTE_CREATED",
  DELIVERABLE_STATUS_UPDATED = "DELIVERABLE_STATUS_UPDATED",
  DELIVERABLE_VOTE_CREATED = "DELIVERABLE_VOTE_CREATED",
  GROUP_FEEDBACK_FINALIZED = "GROUP_FEEDBACK_FINALIZED",
  REVIEWER_GROUP_ASSIGNED = "REVIEWER_GROUP_ASSIGNED",
  DELIVERABLE_CREATED = "DELIVERABLE_CREATED",
  DELIVERABLE_FINALIZED = "DELIVERABLE_FINALIZED",
  DELIVERABLE_LIVE = "DELIVERABLE_LIVE",
  DELIVERABLE_UNFINALIZED = "DELIVERABLE_UNFINALIZED",
  MEDIA_ACCEPTED = "MEDIA_ACCEPTED",
  MEDIA_CREATED = "MEDIA_CREATED",
  MEDIA_DELETED = "MEDIA_DELETED",
  MEDIA_FEEDBACK = "MEDIA_FEEDBACK",
  TEXT_ACCEPTED = "TEXT_ACCEPTED",
  TEXT_CREATED = "TEXT_CREATED",
  TEXT_DELETED = "TEXT_DELETED",
  TEXT_FEEDBACK = "TEXT_FEEDBACK"
}

// eslint-disable-next-line no-shadow
export enum DeliverableItemVoteType {
  APPROVED = "APPROVED",
  COMMENT = "COMMENT"
}

export type DeliverableItemFeedbackHistory = {
  __typename: "DeliverableItemFeedbackHistory"
  feedbackContent: string
  mediaContent: string
}

export type DeliverableItemVoteHistory = {
  __typename: "DeliverableItemVoteHistory"
  itemVoteType: DeliverableItemVoteType
  mediaContent: string
}

export type DeliverableVoteHistory = {
  __typename: "DeliverableVoteHistory"
  voteType: UserVote
  comment: string
}

export type DeliverableStatusChangeHistory = {
  __typename: "DeliverableStatusChangeHistory"
  deliverableStatus: DeliverableStatus
}

export type DeliverableItemMediaContent = {
  __typename: "DeliverableItemMediaContent"
  mediaContent: string
}

export type DeliverableHistoryPayload =
  DeliverableItemFeedbackHistory |
  DeliverableItemVoteHistory |
  DeliverableVoteHistory |
  DeliverableStatusChangeHistory |
  DeliverableItemMediaContent |
  null

export type DeliverableHistoryItem = {
  id: string
  kind: string
  timestamp: string
  isVisible: boolean
  agent: {
    id: string
    userName: string
    avatarUrl: string
    customerName: string
    reviewerGroupName?: string
  }
  payload: DeliverableHistoryPayload
}
export interface DeliverableHistoryOutput {
  deliverableHistoryItems: DeliverableHistoryItem[]
}

// eslint-disable-next-line no-shadow
export enum ReviewerGroupPermission {
  COMMENT = "COMMENT",
  APPROVE = "APPROVE",
  FINALIZE = "FINALIZE"
}

// eslint-disable-next-line no-shadow
export enum UserVote {
  APPROVED = "APPROVED",
  APPROVED_WITH_SUGGESTIONS = "APPROVED_WITH_SUGGESTIONS",
  REQUEST_REVISION = "REQUEST_REVISION"
}

export interface ReviewerGroupUserAssignment {
  id: string
  userId: string
  groupId: string
  permission: ReviewerGroupPermission
  createdAt: string
  updatedAt: string
  userVote: UserVote
  user: {
    fullName: string
    avatarUrl: string
  }
}

export interface DeliverableUserGroupOutput {
  currentDeliverableUserGroup: {
    id: string
    userAssignments: ReviewerGroupUserAssignment[]
  } | null
}

export interface DeliverableUserGroupQueryVariables {
  deliverableId: string;
}

// Mutations
export const ADD_DELIVERABLE_ITEM_COMMENT = gql`
  mutation AddDeliverableItemComment($deliverableItemId: ObfID!, $content: String!){
    addDeliverableItemComment(input: {deliverableItemId: $deliverableItemId, content:$content}) {
      deliverableItemId
    }
  }`

export const CAST_DELIVERABLE_ITEM_VOTE = gql`
  mutation castDeliverableItemVote($deliverableItemId: ObfID!, $voteType: DeliverableItemVoteType!){
    castDeliverableItemVote(input: {deliverableItemId: $deliverableItemId, voteType:$voteType}) {
      deliverableItemId
    }
  }`

export interface CastDeliverableItemVoteInput {
  deliverableItemId: string,
  voteType: DeliverableItemVoteType
}

export interface CastDeliverableItemVoteOutput {
  deliverableItemId: string
}

export interface AddDeliverableItemCommentOutput {
  deliverableItemId: string
}

export interface AddDeliverableItemCommentInput {
  deliverableItemId: string
  content: string
}

export interface CastDeliverableVoteOutput {
  deliverableId: string
  userVote: UserVote
}

export interface CastDeliverableVoteInput {
  deliverableId: string,
  voteType: UserVote
  comment?: string
}
