import React, { useEffect } from "react"
import { EditorContent, useEditor } from "@tiptap/react"
import "./style.sass"
import StarterKit from "@tiptap/starter-kit"
import Underline from "@tiptap/extension-underline"
import TextAlign from "@tiptap/extension-text-align"
import Image from "@tiptap/extension-image"
import Link from "@tiptap/extension-link"
import MenuBar from "./MenuBar"
import EditorAttachment from "./EditorAttachment"
import { UploadedFile } from "../../util/types"

type Props = {
  editorContent: string
  setEditorContent: React.Dispatch<React.SetStateAction<string>>
  editorId?: string,
  handleFileAttachment?: (file: File) => Promise<void>
  uploadedFiles?: UploadedFile[],
  onDeleteThumbnail?: (url: string) => void
  hasMergeTags?: boolean
}

export default function RichTextEditor({
  editorId, editorContent, setEditorContent, handleFileAttachment, onDeleteThumbnail, uploadedFiles = [], hasMergeTags,
}: Props) {
  const editor = useEditor({
    content: editorContent,
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: [ "heading", "paragraph" ],
      }),
      Image,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: "https",
      }),
    ],
    onUpdate: (editorState) => { setEditorContent(editorState.editor.getHTML()) },
  })

  // Ensure editor content is updated when editorContent prop changes
  useEffect(() => {
    if (editor && editorContent !== editor.getHTML()) {
      editor.commands.setContent(editorContent)
    }
  }, [ editor, editorContent ])

  if (!editor) return null

  return (
    <div className="cp_component-rich-text-editor">
      <MenuBar hasMergeTags={ hasMergeTags } editor={ editor } handleFileAttachment={ handleFileAttachment } />
      <EditorContent
        onClick={ () => editor.commands.focus() }
        id={ editorId }
        className="text-editor-section"
        editor={ editor }
      />
      { onDeleteThumbnail && (
      <div className="cp_component-rich-text-editor-attachments">
        { uploadedFiles.map((file) => (
          <EditorAttachment
            key={ file.id }
            id={ file.id } // Add a key prop
            url={ file.url }
            deleteAttachement={ onDeleteThumbnail } // Corrected prop name
            attachementName={ file.name }
          />
        )) }
      </div>
      ) }
    </div>
  )
}
