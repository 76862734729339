import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Divider,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"

import "./campaign-account-row-context-menu.sass"
import { useDispatch, useSelector } from "../../../state/hooks"
import { Scope } from "../../../util/types"
import {
  DeliverableFragment,
  DeliverableStatus,
  Network,
} from "../../../graphql"
import IconButton from "../../IconButton"
import { openEditDeliverableModal } from "../../../state/campaignDeliverableModalSlice"
import { isSuccess } from "../../../util/apiClient"
import { deleteDeliverable, refreshCampaign } from "../../../state/campaignSlice"
import { openConfirmationModal } from "../../../state/confirmationModalSlice"
import { pushToast } from "../../../state/toastSlice"
import { openTikTokInviteModal } from "../../../state/tikTokInviteLinkModalSlice"
import { openCopyLinkModal } from "../../../state/copyLinkModalSlice"
import { openDeliverableMetricModal } from "../../../state/campaignDeliverableMetricModalSlice"
import { openEditURLModal } from "../../../state/editURLModalSlice"

interface CampaignDeliverableRowContextMenuProps {
  deliverable: DeliverableFragment
}

export default function CampaignDeliverableRowContextMenu({
  deliverable,
}: CampaignDeliverableRowContextMenuProps) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDeliverableRowContextMenu" })
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user }) => user)
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const source = "menu"

  const availableAccounts = React.useMemo(() => {
    if (!isSuccess(campaign)) return []
    return campaign.payload.campaign.campaignNetworkAccounts
  }, [ campaign ])
  if (!isSuccess(campaign)) return null

  const deliverableMetricAvailable = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  && scopes.includes(Scope.CAMPAIGN_REPORTING)
  && deliverable.contentStatus === "LIVE"
  && deliverable.postFormat
  && deliverable.postType

  const showCreateTTCMLink = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
  && deliverable.contentStatus === DeliverableStatus.Finalized
  && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
  && !deliverable.tiktokTcmOrder?.tiktokTcmCampaign?.inviteUrl?.address

  const showCopyTTCMLink = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
  && (deliverable.contentStatus === DeliverableStatus.Finalized || deliverable.contentStatus === DeliverableStatus.Live)
  && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
  && deliverable.tiktokTcmOrder?.tiktokTcmCampaign?.inviteUrl?.address

  const showEditURL = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  && deliverable.contentStatus === "LIVE"
  && deliverable.postFormat
  && deliverable.postType

  return (
    <>
      <IconButton
        className="cp_campaign-account-row-context-menu_component-button"
        id="cp_campaign_deliverable_context_menu_button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_campaign-account-row-context-menu_component-menu-popover"
      >
        <MenuList
          dense={ true }
          className="cp_campaign-account-row-context-menu_component-menu"
        >
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? (
            <MenuItem
              onClick={ () => {
                dispatch(
                  openEditDeliverableModal({
                    deliverable,
                    socialAccounts: availableAccounts,
                    updateCallback: () => dispatch(refreshCampaign()),
                  }),
                )
                setAnchorEl(null)
              } }
              className="cp_campaign-account-row-context-menu_component-menu-item"
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Edit Deliverable") }
              </ListItemText>
            </MenuItem>
          ) : null }
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? (
            <MenuItem
              className="cp_campaign-account-row-context-menu_component-menu-item"
              onClick={ () => {
                dispatch(openConfirmationModal({
                  onConfirm: () => dispatch(deleteDeliverable({
                    variables: { id: deliverable.id },
                    onSuccess: () => {
                      dispatch(refreshCampaign())
                      dispatch(pushToast({
                        message: translate("Delete Success!"),
                        type: "success",
                      }))
                    },
                  })),
                  title: translate("Delete Deliverable"),
                  subtext: translate("Are you sure you want to delete this deliverable?"),
                }))
                setAnchorEl(null)
              } }
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Delete Deliverable") }
              </ListItemText>
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </MenuItem>
          ) : null }
          { deliverableMetricAvailable ? (
            <>
              <Divider />
              <MenuItem
                onClick={ () => {
                  dispatch(openDeliverableMetricModal({
                    deliverable,
                    campaign: campaign.payload.campaign,
                    source,
                    updateCallback: () => dispatch(refreshCampaign()),
                  }))
                  setAnchorEl(null)
                } }
                className="cp_campaign-account-row-context-menu_component-menu-item"
              >
                <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                  { translate("Update Metrics") }
                </ListItemText>
              </MenuItem>
            </>
          ) : null }
          { showEditURL ? (
            <>
              { !scopes.includes(Scope.CAMPAIGN_REPORTING) ? <Divider /> : null }
              <MenuItem
                onClick={ () => {
                  dispatch(openEditURLModal({
                    deliverableId: deliverable.id,
                    url: deliverable.liveStatusUrl?.address || "",
                    updateCallback: () => dispatch(refreshCampaign()),
                  }))
                  setAnchorEl(null)
                } }
                className="cp_campaign-account-row-context-menu_component-menu-item"
              >
                <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                  { translate("Edit URL") }
                </ListItemText>
              </MenuItem>
            </>
          ) : null }
          { showCreateTTCMLink
            ? (
              <>
                <Divider />
                <MenuItem
                  className="cp_campaign-account-row-context-menu_component-menu-item"
                  onClick={ () => {
                    if (!deliverable) return
                    dispatch(openTikTokInviteModal({
                      deliverableId: deliverable.id,
                      deliverableVersion: "v1",
                    }))
                    setAnchorEl(null)
                  }
                  }
                >
                  <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                    { translate("Create TTCM Invite Link") }
                  </ListItemText>
                </MenuItem>
              </>
            ) : null }
          { showCopyTTCMLink
            ? (
              <>
                <Divider />
                <MenuItem
                  className="cp_campaign-account-row-context-menu_component-menu-item"
                  onClick={ () => {
                    if (!deliverable.tiktokTcmOrder?.tiktokTcmCampaign.inviteUrl?.address) return
                    dispatch(openCopyLinkModal({
                      link: deliverable.tiktokTcmOrder.tiktokTcmCampaign.inviteUrl.address,
                      title: translate("Share TTCM Invite Link"),
                      subtitle: translate("Copy the invite link for the TTCM order."),
                    }))
                    setAnchorEl(null)
                  }
                  }
                >
                  <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                    { translate("Copy TTCM Invite Link") }
                  </ListItemText>
                </MenuItem>
              </>
            ) : null }
        </MenuList>
      </Menu>
    </>
  )
}
