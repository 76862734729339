// eslint-disable-next-line no-shadow
export enum PostTrackerStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    EXPIRED = "EXPIRED",
  }

export interface DeliverableSummary{
    id: string
    name: string
    caption: string
    deliverableStatus: string
    feedbackCount: number
    networkId: string
    socialAccount: SocialAccountSummary
    ttcmCampaignName?: string
    mediaUrl: string
    postTrackerId: string
    postTracker: PostTracker
}

export interface SocialAccountSummary{
    id: string
    name: string
    username: string
    networkType: string
    profilePictureUrl: string
    followerCount: number
}

export interface PostTracker{
    id: string
    enabled: boolean
    status: PostTrackerStatus
}
