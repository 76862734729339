import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  setHighlightsMetrics,
  Metric,
  setUpdateListForm,
} from "../../../../state/listConfigurationSlice"
import Checkbox from "../../../Checkbox"
import { GroupMetrics } from "../../constants"
import { copyObject } from "../../../../util/miscHelper"

interface GroupMetricsProps {
  metricData: GroupMetrics
}

export default function MetricDisplayGroup({ metricData }: GroupMetricsProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  const { highlightsMetrics, updateListForm } = useSelector((state) => state.listConfiguration)

  // Local state
  const [ expanded, setExpanded ] = React.useState(false)

  /**
   * allSelectOrDeselect: Selects all or de-selects all sub list items
   * @param e The event object
   */
  const allSelectOrDeselect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (highlightsMetrics.length === metricData.metrics.length) {
      // Update global state
      dispatch(setHighlightsMetrics(highlightsMetrics.filter((metric) => !metricData.metrics.includes(metric))))

      // Check if update list form exists
      if (updateListForm) {
        // Remove all toggles from update list form
        const listForm = copyObject(updateListForm)
        listForm.toggles = (Array.isArray(listForm.toggles))
          ? listForm.toggles.filter((toggle) => !metricData.metrics.some((metric) => metric.code === toggle))
          : [ listForm.toggles ].filter((toggle) => !metricData.metrics.some((metric) => metric.code === toggle))
        dispatch(setUpdateListForm(listForm))
      }
    } else {
      // reverse filter find what isn't there and then add
      const missing = metricData.metrics.filter((metric) => !highlightsMetrics.includes(metric))
      dispatch(setHighlightsMetrics([ ...highlightsMetrics, ...missing ]))

      // Check if update list form exists
      if (updateListForm) {
        // Add all toggles to update list form
        const listForm = copyObject(updateListForm)
        const addList = metricData.metrics.filter((metric) => !updateListForm.toggles.includes(metric.code))
        listForm.toggles = [
          ...((Array.isArray(listForm.toggles)) ? listForm.toggles : [ listForm.toggles ]),
          ...addList.map((metric) => metric.code),
        ]
        dispatch(setUpdateListForm(listForm))
      }
    }
    e.stopPropagation()
  }

  /**
   * selectItem: Handles selecting or de-selecting a sub list item
   * @param metric The metric to select/de-select
   */
  const selectItem = (metric: Metric) => {
    if (highlightsMetrics.includes(metric)) {
      // Remove the metric from state
      dispatch(setHighlightsMetrics(highlightsMetrics.filter((stateMetric) => stateMetric !== metric)))

      // Check if update list form exists
      if (updateListForm) {
        // Remove toggle from update list form
        const listForm = copyObject(updateListForm)
        listForm.toggles = (Array.isArray(listForm.toggles))
          ? listForm.toggles.filter((toggle) => toggle !== metric.code)
          : [ listForm.toggles ].filter((toggle) => toggle !== metric.code)

        dispatch(setUpdateListForm(listForm))
      }
    } else {
      // Add item

      dispatch(setHighlightsMetrics([ ...highlightsMetrics, metric ]))

      // Check if update list form exists
      if (updateListForm) {
        // Add toggle to update list form
        const listForm = copyObject(updateListForm)
        listForm.toggles = [
          ...((Array.isArray(listForm.toggles)) ? listForm.toggles : [ listForm.toggles ]),
          metric.code,
        ]

        dispatch(setUpdateListForm(listForm))
      }
    }
  }

  /**
   * containsAllMetrics: Checks to see if all metrics are saved
   * @returns True if all are there, otherwise false
   */
  const containsAllMetrics = (): boolean => metricData.metrics.every((metric) => highlightsMetrics.includes(metric))

  /**
   * containsSomeMetrics: Checks to see how many metrics are saved
   * @returns True if there are some, but not all, otherwise false
   */
  const containsSomeMetrics = (): boolean => highlightsMetrics.some((metric) => metricData.metrics.includes(metric))

  // Render the component
  return (
    <>
      <ListItemButton
        className="group-highlights-parent-list-item"
        onClick={ () => setExpanded(!expanded) }
      >
        { (expanded)
          ? <ExpandMore className="list-item-expand-contract" />
          : <ExpandLess className="list-item-expand-contract" /> }
        <ListItemIcon>
          <Checkbox
            checked={ containsAllMetrics() && metricData.metrics.length !== 0 }
            indeterminate={ containsSomeMetrics() && !(containsAllMetrics() && metricData.metrics.length !== 0) }
            onClick={ (e) => allSelectOrDeselect(e) }
          />
        </ListItemIcon>
        <ListItemText
          className="group-highlights-parent-list-item-text"
          primary={ translate(metricData.groupName) }
        />
      </ListItemButton>
      <Collapse in={ expanded } timeout="auto" unmountOnExit={ true }>
        <List
          id="plad-metrics"
          className="group-highlights-child-list-item"
          component="div"
          disablePadding={ true }
        >
          { metricData.metrics.map((item) => (
            <ListItemButton
              className="group-highlights-child-list-item-button"
              key={ item.code }
              onClick={ () => selectItem(item) }
            >
              <ListItemIcon>
                <Checkbox className="group-highlights-child-list-item-cb" checked={ highlightsMetrics.includes(item) } />
              </ListItemIcon>
              <ListItemText
                className="group-highlights-child-list-item-text"
                primary={ translate(item.name) }
              />
            </ListItemButton>
          )) }
        </List>
      </Collapse>
    </>
  )
}
