import React from "react"

import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { CheckCircleOutlined } from "@mui/icons-material"
import Button from "../Button"
import EntityInfoRow from "../EntityInfoRow"

import "./post-preview.sass"
import { useDispatch } from "../../state/hooks"
import { setAssignReviewerModalOpen } from "../../state/campaignDeliverableAssignReviewerModalSlice"
import {
  DeliverableUserGroupOutput,
  DeliverableUserGroupQueryVariables,
  GET_DELIVERABLE_USER_GROUP,
  ReviewerGroupPermission,
  UserVote,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import LoadingIndicatorCard from "../LoadingIndicatorCard"

import "./assign-reviewer.sass"

function AssignReviewer() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableAssignReviewerGroup" })

  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""

  const dispatch = useDispatch()

  const { data: deliverableUserGroupData, loading } = useQuery<DeliverableUserGroupOutput, DeliverableUserGroupQueryVariables>(
    GET_DELIVERABLE_USER_GROUP,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId,
    },
  )

  const getUserSubInfo = (permission: ReviewerGroupPermission) => {
    switch (permission) {
      case ReviewerGroupPermission.APPROVE:
        return translate("Approve")
      case ReviewerGroupPermission.FINALIZE:
        return translate("Finalize")
      case ReviewerGroupPermission.COMMENT:
        return translate("Comment")
      default:
        return ""
    }
  }

  const renderAssignedUsers = () => {
    if (loading) return <LoadingIndicatorCard />
    if (
      deliverableUserGroupData
      && deliverableUserGroupData.currentDeliverableUserGroup
      && deliverableUserGroupData.currentDeliverableUserGroup.userAssignments.length > 0
    ) {
      return deliverableUserGroupData.currentDeliverableUserGroup.userAssignments.map((userAssignment) => (
        <div className="user-row" key={ userAssignment.id }>
          { (userAssignment.userVote === UserVote.APPROVED
          || userAssignment.userVote === UserVote.APPROVED_WITH_SUGGESTIONS)
          && <CheckCircleOutlined /> }
          <EntityInfoRow
            className="assign-reviewers-entity-row"
            copyDisabled={ true }
            key={ userAssignment.userId }
            name={ userAssignment.user.fullName }
            subInfo={ getUserSubInfo(userAssignment.permission) }
            avatarSrc={ userAssignment.user.avatarUrl }
          />
        </div>
      ))
    }
    return (
      <div className="cp_component_modal-campaign-deliverable-v2-assign-reviewer-body">
        <div>
          <span>{ translate("No Reviewer Group Created/Assigned") }</span>
        </div>
        <Button
          className="cp_btn_component_highlight"
          label={ translate("Assign Reviewer Group") }
          isPrimary={ false }
          isEnabled={ true }
          variant="text"
          onClick={ async () => dispatch(setAssignReviewerModalOpen(true)) }
        />
      </div>
    )
  }

  return (
    <div className="cp_component_modal-campaign-deliverable-v2-assign-reviewer">
      <p className="body_large">{ translate("Current Reviewer Group") }</p>
      { renderAssignedUsers() }
    </div>
  )
}
export default AssignReviewer
