import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import { CheckCircleOutline, RemoveCircleOutline } from "@mui/icons-material"
import NetworkAvatar from "../NetworkAvatar"
import { shorthandNumber } from "../../util/miscHelper"
import { useDispatch, useSelector } from "../../state/hooks"
import { DeliverableQueryVariables, Network } from "../../graphql"
import CopyIconButton from "../CopyIconButton"

import "./overview.sass"
import Dropdown from "../Dropdown"
import {
  CAST_DELIVERABLE_VOTE,
  CastDeliverableVoteInput,
  CastDeliverableVoteOutput,
  DeliverablePermissionsOutput,
  DeliverableVoteOutput,
  GET_DELIVERABLE_CAPTION_ITEMS,
  GET_DELIVERABLE_MEDIA_ITEMS,
  GET_DELIVERABLE_PERMISSIONS,
  GET_DELIVERABLE_USER_GROUP,
  GET_DELIVERABLE_VOTE,
  ReviewerGroupStatus,
  UserVote,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import { Scope } from "../../util/types"
import Divider from "../Divider"
import {
  setFeedbackPageOpen,
  setFeedbackPagePendingVote,
  setFinalizeConfirmationModalOpen,
  setDeliverablePermissions,
} from "../../state/campaignDeliverableContentModalV2Slice"

function Overview(): React.JSX.Element {
  const {
    deliverable,
    deliverableMedia,
    deliverablePermissions,
    feedbackPagePendingVote,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const dispatch = useDispatch()

  const {
    scopes,
  } = useSelector(({ user }) => user)

  const [ copyUserHandle, setCopyUserHandle ] = useState<string | null>(null)
  const [ approveAnchorEl, setApproveAnchorEl ] = React.useState<null | HTMLElement>(null)
  const [ finalizeAnchorEl, setFinalizeAnchorEl ] = React.useState<null | HTMLElement>(null)

  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const { data: permissionData } = useQuery<DeliverablePermissionsOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE_PERMISSIONS,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId,
      fetchPolicy: "network-only",
    },
  )

  useEffect(() => {
    if (permissionData?.deliverablePermissions) dispatch(setDeliverablePermissions(permissionData.deliverablePermissions))
  }, [ permissionData ])

  const { data: deliverableVoteData } = useQuery<DeliverableVoteOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE_VOTE,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId,
    },
  )

  const [ castDeliverableVote ] = useMutation<
    CastDeliverableVoteOutput,
    CastDeliverableVoteInput
  >(CAST_DELIVERABLE_VOTE)

  const deliverableEmpty = deliverableMedia === undefined || deliverableMedia.length === 0

  const approveButtonVisible = useMemo(() => {
    if (!deliverablePermissions) return false
    return deliverablePermissions.canVoteOnDeliverable || scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  }, [ deliverablePermissions, scopes ])

  const approveButtonDisabled = useMemo(() => {
    if (deliverableEmpty) return true
    return deliverable?.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE
  }, [ deliverable ])

  const finalizeFeebackButtonVisible = useMemo(() => {
    if (!deliverablePermissions) return false
    return deliverablePermissions.canFinalizeDeliverableFeedback || scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  }, [ deliverablePermissions, scopes ])

  const finalizeFeedbackButtonDisabled = useMemo(() => {
    if (deliverableEmpty) return true
    return deliverable?.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE
  }, [ deliverable ])

  const finalizeButtonTitle = useMemo(() => {
    if (deliverable?.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE) return translate("Finalized")
    return translate("Finalize Feedback")
  }, [ deliverable ])

  const handleCopyUserHandleMouseEnter = (text: string) => {
    setCopyUserHandle(text)
  }

  const handleCopyUserHandleMouseLeave = () => {
    setCopyUserHandle(null)
  }

  const handleApprove = () => {
    if (!deliverable) return
    castDeliverableVote({
      variables: {
        deliverableId: deliverable.id,
        voteType: UserVote.APPROVED,
        comment: "",
      },
      context: {
        apiVersion: "v2",
      },
      refetchQueries: [
        {
          query: GET_DELIVERABLE_CAPTION_ITEMS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_MEDIA_ITEMS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_VOTE,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_USER_GROUP,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
      ],
    })
    setApproveAnchorEl(null)
  }

  const handleApproveWithSuggestions = () => {
    dispatch(setFeedbackPageOpen(true))
    dispatch(setFeedbackPagePendingVote(UserVote.APPROVED_WITH_SUGGESTIONS))
    setApproveAnchorEl(null)
  }

  const handleRequestRevision = () => {
    dispatch(setFeedbackPageOpen(true))
    dispatch(setFeedbackPagePendingVote(UserVote.REQUEST_REVISION))
    setApproveAnchorEl(null)
  }

  const handleFinalize = () => {
    dispatch(setFinalizeConfirmationModalOpen(true))
  }

  const dropdownButtonTitle = useMemo(() => {
    const displayedStatus = feedbackPagePendingVote || deliverableVoteData?.deliverableVote?.userVote
    switch (displayedStatus) {
      case UserVote.APPROVED:
        return (
          <div className="dropdown-button-title-container">
            <CheckCircleOutline className="approved-icon" />
            <p>{ translate("Approved") }</p>
          </div>
        )
      case UserVote.APPROVED_WITH_SUGGESTIONS:
        return (
          <div className="dropdown-button-title-container">
            <CheckCircleOutline className="approved-icon" />
            <p>{ translate("Approved w/Suggestions") }</p>
          </div>
        )
      case UserVote.REQUEST_REVISION:
        return (
          <div className="dropdown-button-title-container">
            <RemoveCircleOutline className="request-revision-icon" />
            <p>{ translate("Revision Requested") }</p>
          </div>
        )
      default:
        return translate("Approve")
    }
  }, [ deliverable, feedbackPagePendingVote, deliverableVoteData ])

  if (!deliverable) return <> </>

  return (
    <div className="cp_deliverable-v2-content-overview_component">
      <div className="cp_deliverable-v2-content-overview_component-left">
        <NetworkAvatar
          size="lg"
          network={ deliverable.socialAccount.networkType as Network }
          src={ deliverable.socialAccount.profilePictureUrl }
        >
          { deliverable.socialAccount.username.charAt(0) }
        </NetworkAvatar>
        <div className="cp_deliverable-v2-content-overview_component-left-details">
          <div>
            <p
              className="cp_deliverable-v2-content-overview_component-left-details-name"
              onMouseEnter={
                () => handleCopyUserHandleMouseEnter(`@${ deliverable.socialAccount.username }`)
              }
              onMouseLeave={ handleCopyUserHandleMouseLeave }
            >
              { `@${ deliverable.socialAccount.username }` }
              { copyUserHandle && (
              <CopyIconButton textToCopy={ copyUserHandle } />
              ) }
            </p>
          </div>
          <p className="cp_deliverable-v2-content-overview_component-left-details-demographics">
            {
              [
                deliverable.socialAccount.name,
                `${ shorthandNumber(deliverable.socialAccount.followerCount) }
                ${ translate("FOLLOWERS") }`,
              ].filter((components) => !!components).join(" | ")
            }
          </p>
        </div>
      </div>
      <div className="cp_deliverable-v2-content-overview_component-review">
        { approveButtonVisible && (
        <Dropdown
          buttonTitle={ dropdownButtonTitle }
          buttonClassName="approve-dropdown-buttons"
          buttonType="split"
          customAnchorEl={ approveAnchorEl }
          setCustomAnchorEl={ setApproveAnchorEl }
          disabled={ approveButtonDisabled }
        >
          <List className="dropdown-list-item-container" disablePadding={ true }>
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
            >
              <ListItemButton
                id="cp_deliverable-v2-content-overview_approve-button"
                onClick={ handleApprove }
              >
                <CheckCircleOutline className="approved-icon" />
                <ListItemText primary={ translate("Approve") } />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
            >
              <ListItemButton
                id="cp_deliverable-v2-content-overview_approve-with-suggestions-button"
                onClick={ handleApproveWithSuggestions }
              >
                <CheckCircleOutline className="approved-icon" />
                <ListItemText primary={ translate("Approve with Suggestions") } />
              </ListItemButton>
            </ListItem>
            <Divider className="dropdown-button-divider" />
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
            >
              <ListItemButton
                id="cp_deliverable-v2-content-overview_request-revision-button"
                onClick={ handleRequestRevision }
              >
                <RemoveCircleOutline className="request-revision-icon" />
                <ListItemText primary={ translate("Request Revision") } />
              </ListItemButton>
            </ListItem>
          </List>
        </Dropdown>
        ) }

        { finalizeFeebackButtonVisible && (
          <Dropdown
            buttonTitle={ finalizeButtonTitle }
            buttonClassName="finalize-feedback-buttons"
            disabled={ finalizeFeedbackButtonDisabled }
            buttonType="split"
            customAnchorEl={ finalizeAnchorEl }
            setCustomAnchorEl={ setFinalizeAnchorEl }
            isPrimary={ true }
            onClickOverride={ !scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? undefined : handleFinalize }
          >
            <List className="dropdown-list-item-container" disablePadding={ true }>
              <ListItem
                disablePadding={ true }
                disableGutters={ true }
              >
                <ListItemButton
                  id="cp_deliverable-v2-content-overview_finalize-button"
                  onClick={ handleFinalize }
                >
                  <ListItemText primary={ translate("Finalize Feedback") } />
                </ListItemButton>
              </ListItem>
            </List>
          </Dropdown>
        ) }
      </div>
    </div>
  )
}

export default Overview
