import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import Input from "../Input"
import ImageTagsAutocomplete from "../ListFormModal/ImageTagsAutocomplete"
import Pill from "../Pill"

import "./configuration-option-styles.sass"

export default function ConfigurationVisualizations() {
  const {
    t: translateCVH,
  } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })

  // TODO: future task to set up slice for this
  const [ contentTogglesKeywords, setContentTogglesKeywords ] = useState<string[]>([])
  const [ relevantPostsKeyword, setRelevantPostsKeyword ] = useState("")
  const [ imageTags, setImageTags ] = useState<string[]>([])

  const handleRemoveKeywords = (tagName: string): void => {
    const filteredKeywords = contentTogglesKeywords.filter((tag: string) => (tag !== tagName))

    // TODO: future task to set up slice for this
    // dispatch(setKeywords(filteredKeywords))
    setContentTogglesKeywords(filteredKeywords)
  }

  function renderKeywordPills() {
    return contentTogglesKeywords.map((tag) => (
      <Pill
        key={ tag }
        label={ tag }
        onDelete={ () => handleRemoveKeywords(tag) }
      />
    ))
  }

  function hasKewordPills() {
    return contentTogglesKeywords.length > 0
  }

  // Event handler Keywords
  const handleAddKeywords = (): void => {
    // Split the input by commas, trim whitespace, and filter out any empty strings
    const newKeywords = relevantPostsKeyword.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)

    const filteredKeywords = newKeywords
      .filter((keyword) => !contentTogglesKeywords.includes(keyword))

    if (filteredKeywords.length > 0) {
      // There will be a different task to set up slice for this
      // dispatch(setKeywords([ ...listModalFormState.keywords, ...filteredKeywords ]))
      setContentTogglesKeywords([ ...contentTogglesKeywords, ...filteredKeywords ])
    }

    setRelevantPostsKeyword("") // Clear the input field after adding keywords
  }

  const handleKeywordKeyDown = (event: React.KeyboardEvent): void => {
    // When the user presses Enter, add the keywords
    if (event.key === "Enter") {
      event.preventDefault() // Prevent form submission and other default actions
      handleAddKeywords()
    }
  }

  // Event handler Image Tags
  const setSelectedImageTags = (selectedImageTags: string[]) => {
    setImageTags(selectedImageTags)
  }

  function hasImageTags() {
    return imageTags.length > 0
  }

  const handleRemoveImageTags = (tagName: string): void => {
    const filteredKeywords = imageTags
      .filter((tag: string) => (tag !== tagName))

    setImageTags(filteredKeywords)
  }

  function renderImageTagPills() {
    return imageTags.map((tag) => (
      <Pill
        key={ tag }
        label={ tag }
        onDelete={ () => handleRemoveImageTags(tag) }
      />
    ))
  }

  return (
    <div className="config-option-switch-additional_fields">
      <Input
        className="create-list-long-description"
        value={ relevantPostsKeyword }
        placeholder={ translateCVH("Keywords") }
        onKeyDown={ handleKeywordKeyDown }
        onChange={ (e) => setRelevantPostsKeyword(e.currentTarget.value) }
        fullWidth={ true }
        label={ translateCVH("Keywords") }
      />
      <div className={ `config-option-switch-pills ${ hasKewordPills() ? "has-keywords" : "" }` }>
        {
        renderKeywordPills()
      }
      </div>
      <div className="config-option-switch-image_tags">
        <ImageTagsAutocomplete
          setSelectedImageTags={ setSelectedImageTags }
          selectedImageTags={ imageTags }
          inputLabel={ translateCVH("Image Tags") }
          notFoundLabel={ translateCVH("Image Tag not found") }
        />
        <div className={ `config-option-switch-pills ${ hasImageTags() ? "has-image-tags" : "" }` }>
          {
          renderImageTagPills()
        }
        </div>
      </div>
    </div>
  )
}
