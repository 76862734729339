import React from "react"
import EngagementRateOverTimeVizualization from "../../../asset/visualizations/LIST_ENGAGEMENT_RATE_EX.svg"
import EngagementByPostTypeOSVisualization from "../../../asset/visualizations/LIST_ENG_POST_TYPE_EX.svg"
import EngagementByPostTypeRNVisualization from "../../../asset/visualizations/LIST_ENG_POST_TYPE_REEL_EX.svg"
import EngagementHeatMapVisualization from "../../../asset/visualizations/LIST_ENG_HEATMAP_EX.svg"
import RecentMediaAiAnalysisVisualization from "../../../asset/visualizations/LIST_RECENT_MEDIA_AI_EX.svg"
import BrandsMentionedVisualization from "../../../asset/visualizations/LIST_BRANDS_MENTIONED_EX.svg"
import RecentMedialStoriesVisualization from "../../../asset/visualizations/LIST_RECENT_STORIES_EX.svg"
import PerconalityTraitsVisualization from "../../../asset/visualizations/LIST_PERSONALITY_TRAITS_EX.svg"
import FollowersOverTimeVisualization from "../../../asset/visualizations/LIST_FOLLOWERS_OVER_TIME_EX.svg"
import AudienceQualityVisualization from "../../../asset/visualizations/LIST_AUDIENCE_QUALITY_EX.svg"
import GenderFamlyStatusVisualization from "../../../asset/visualizations/LIST_GENDER_FAMILY_EX.svg"
import AgeIncomeEducationRangeVisualization from "../../../asset/visualizations/LIST_BASIC_EX.svg"
import EthinicitiesLanguagesReligionsVisualization from "../../../asset/visualizations/LIST_ETHNICITY_LANG_RELIGION_EX.svg"
import CountriesStatesCitiesVisualization from "../../../asset/visualizations/LIST_LOCATION_EX.svg"
import VocationVisualization from "../../../asset/visualizations/LIST_VOCATION_EX.svg"
import AffinitiesVisualization from "../../../asset/visualizations/LIST_AFFINITIES_EX.svg"
import ListTopPosts from "../../../asset/visualizations/LIST_TOP_POSTS.svg"
import ListRelevantKeywords from "../../../asset/visualizations/LIST_RELEVANTPOST_KEYWORDS.svg"
import ListRelevantTags from "../../../asset/visualizations/LIST_RELEVANTPOST_TAGS.svg"
import Divider from "../../Divider"

export default function ContentVisualizations() {
  return (
    <div className="cp_lcp-preview_example-visualizations">
      <h2>Account Insights</h2>
      <img src={ EngagementRateOverTimeVizualization } alt="Engagement Rate Over Time" />
      <img src={ EngagementByPostTypeOSVisualization } alt="Engagement by Post Type: Organic vs Sponsored" />
      <img src={ EngagementByPostTypeRNVisualization } alt="Engagement by Post Type: Reel vs Non-Reel" />
      <img src={ EngagementHeatMapVisualization } alt="Engagements Heat Map" />
      { /* eslint-disable-next-line jsx-a11y/img-redundant-alt */ }
      <img src={ RecentMediaAiAnalysisVisualization } alt="Recent Media And AI Image Analysis" />
      <img src={ BrandsMentionedVisualization } alt="Brands Mentioned And Brands Worked With" />
      <img src={ RecentMedialStoriesVisualization } alt="Recent Media Stories" />
      <img src={ PerconalityTraitsVisualization } alt="Personality Traits" />
      <Divider />
      <h2>Audience</h2>
      <img src={ FollowersOverTimeVisualization } alt="Followers Over Time" />
      <img src={ AudienceQualityVisualization } alt="Audience Quality" />
      <img src={ GenderFamlyStatusVisualization } alt="Gender & Family" />
      <img src={ AgeIncomeEducationRangeVisualization } alt="Basic chart" />
      <img src={ EthinicitiesLanguagesReligionsVisualization } alt="Ethinicities Languages Religions Visualizations" />
      <img src={ CountriesStatesCitiesVisualization } alt="Countries States Cities Visualizations" />
      <img src={ VocationVisualization } alt="Vocation Visualization" />
      <img src={ AffinitiesVisualization } alt="Affinities Visualization" />
      <Divider />
      <h2>Content</h2>
      <img src={ ListTopPosts } alt="Top Posts" />
      <img src={ ListRelevantKeywords } alt="List Relevant Keywords" />
      <img src={ ListRelevantTags } alt="List Relevant Images Tag" />
    </div>
  )
}
