import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"

import { useSelector } from "../../../state/hooks"
import {
  AUDIENCE_DISPLAY_TOGGLES,
  LIST_DISPLAY_TOGGLES,
  PUBLIC_DETAILS_DISPLAY_TOGGLES,
} from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import * as MiscHelper from "../../../util/miscHelper"
import { isSuccess } from "../../../util/apiClient"

type RibbomMetric = {
  label: string
  value: string | number
}

type MetricToggle = {
  code: string
  label: string
}

interface MetricsRibbonBannerProps {
  // eslint-disable-next-line max-len
  account: GraphQL.SearchSuggestionListByIdQuery["suggestionListById"]["suggestionListCategories"][number]["suggestionListSocialAccounts"][number]
}

export default function MetricsRibbonBanner({ account }: MetricsRibbonBannerProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ConfigurationHighlightsTab" })

  const updateListForm = useSelector(({ listConfiguration }) => listConfiguration.updateListForm)
  const iScores = useSelector((state) => state.list.iScores)

  const [ metrics, setMetrics ] = React.useState<RibbomMetric[]>([])

  React.useEffect(() => {
    // Ensure updateListForm is defined
    if (updateListForm) {
      // Pull out the toggles from updateListForm
      const { toggles } = updateListForm

      // Initialize the array to hold the metrics label with code
      const metricToggles: MetricToggle[] = [ {
        code: PUBLIC_DETAILS_DISPLAY_TOGGLES.AGE,
        label: "Age",
      }, {
        code: PUBLIC_DETAILS_DISPLAY_TOGGLES.GENDER,
        label: "Gender",
      }, {
        code: PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION,
        label: "Location",
      }, {
        code: AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
        label: "Followers",
      }, {
        code: LIST_DISPLAY_TOGGLES.ENG_SCORE,
        label: "Eng Score",
      }, {
        code: LIST_DISPLAY_TOGGLES.ENG_RATE,
        label: "Eng Rate (90 Day)",
      }, {
        code: LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE,
        label: "Eng Rate (TTCM)",
      }, {
        code: LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO,
        label: "In-Demo",
      }, {
        code: LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
        label: "Demographic Score",
      }, {
        code: LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
        label: "Ad Council Score",
      }, {
        code: LIST_DISPLAY_TOGGLES.ISCORE,
        label: "I-Score",
      } ]

      // Loop through and create metrics
      const ribbonMetrics: RibbomMetric[] = []
      metricToggles.forEach((metric) => {
        if (toggles.includes(metric.code)) {
          switch (metric.label) {
            case "Age":
              if (account.age) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: account.age,
                })
              }
              break
            case "Gender":
              if (account.gender) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: account.gender,
                })
              }
              break
            case "Location":
              if (account.location) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: account.location,
                })
              }
              break
            case "Followers":
              if (account.socialAccount.socialAccountStatistics.followers) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: MiscHelper.shorthandNumber(account.socialAccount.socialAccountStatistics.followers),
                })
              }
              break
            case "Eng Score":
              if (account.socialAccount.engagementScore?.value) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: Math.round(account.socialAccount.engagementScore.value),
                })
              }
              break
            case "Eng Rate (90 Day)":
              if (account.socialAccount.socialAccountStatistics.engagementRate) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: MiscHelper.formatPercent(2, account.socialAccount.socialAccountStatistics.engagementRate),
                })
              }
              break
            case "Eng Rate (TTCM)":
              if (account.socialAccount.network === GraphQL.Network.Tiktok) {
                if (account.socialAccount.ttcmEngagementRate) {
                  ribbonMetrics.push({
                    label: metric.label,
                    value: MiscHelper.formatPercent(2, account.socialAccount.ttcmEngagementRate),
                  })
                }
              }
              break
            case "In-Demo":
              if (account.inDemo) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: MiscHelper.formatPercent(2, account.inDemo),
                })
              }
              break
            case "Demographic Score":
              if (account.score && account.score.type === GraphQL.ScoreType.Demographic) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ Math.round(account.score.value) }%`,
                })
              }
              break
            case "Ad Council Score":
              if (isSuccess(iScores)) {
                const iScore = iScores.payload.getSocialAccountsIScore.socialAccountsIScore.find((accountIscore) => (
                  accountIscore.socialAccountId === account.socialAccount.id
                ))
                if (iScore) {
                  ribbonMetrics.push({
                    label: metric.label,
                    value: `${ Math.round(iScore.iScore.value) }%`,
                  })
                }
              }
              break
            case "I-Score":
              if (isSuccess(iScores)) {
                const iScore = iScores.payload.getSocialAccountsIScore.socialAccountsIScore.find((accountIscore) => (
                  accountIscore.socialAccountId === account.socialAccount.id
                ))
                if (iScore) {
                  ribbonMetrics.push({
                    label: metric.label,
                    value: `${ Math.round(iScore.iScore.value) }%`,
                  })
                }
              }
              break
            default:
              break
          }
        }
      })
      setMetrics(ribbonMetrics)
    }
  }, [ updateListForm ])

  return (
    <div className="cp_lcp_highlights-previews_account-container_metrics-ribbon-banner">
      { metrics.map((metric) => (
        <div key={ `${ metric.label.toLowerCase().replaceAll(" ", "_") }` } className="cp_lcp_hpm_rb_metric">
          <p className="cp_lcp_hpm_rb_metric_value">
            { metric.value }
          </p>
          <p className="label_small-caps-bold">
            { translate(metric.label) }
          </p>
        </div>
      )) }
    </div>
  )
}
