import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { createSlice } from "@reduxjs/toolkit"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"
import { RootState } from "../store"
import { FetchMentionedPostParams } from "../../component/ProfileTabs/AccountInsights/ModalBrandDetails"
import { AUDIENCE_DISPLAY_TOGGLES, ACCOUNT_INSIGHTS_DISPLAY_TOGGLES } from "../../util/constant"

// Profile Slice Interface and Initial State
export interface ListSocialProfileState {
  listProfileToggles: Status<GraphQL.SearchListSocialProfileTogglesQuery>,
  listProfile: Status<GraphQL.SearchListSocialProfileQuery>,
  audience: Status<GraphQL.AudienceDemographicsQuery>
  dailyEngagementRate: Status<GraphQL.GetDailyHourlyEngagementSummaryQuery>,
  mentionedBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  workedWithBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  detectedBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  mentionedPostsStatus: Status<GraphQL.GetMentionedPostsQuery>
  mentionedPostsContent: Array<GraphQL.MentionedPostFragment>
  loadedVerticals: GraphQL.VerticalFragment[]
  loadingVerticals: boolean
  editModalOpen: boolean
  editModalLoadingPrimaryAction: boolean
  addToModalPhase: null | 1 | 2
  selectedSocialAccountID: string,
  selectedCampaignIDs: string[],
  selectedSocialAccountNetwork: GraphQL.Network | null
  campaignsForProfile: Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>,
  campaigns: Status<GraphQL.SearchCampaignsQuery>
  campaignsForAccount: Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>,
  fetchedTopPosts: GraphQL.SuggestionsPostFragment[]
  imageTagPosts: GraphQL.PostFragment[]
  toggleFetchingImageTags: boolean
  ImageTagsWithPosts: GraphQL.SuggestionListImageTag[]
  activeTagId: string
  keywordPosts: GraphQL.PostFragment[]
  keywordsWithPosts: GraphQL.SuggestionListKeyword[]
  toggleFetchingKeywords: boolean
  commsForProfile: Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>
  comms: Status<GraphQL.SearchCommunicationGroupsQuery>
  commsForAccount: Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>
  selectedCommIDs: string[],
  modalAddToCommsOpen: boolean
}

const initialState: ListSocialProfileState = {
  listProfileToggles: "init",
  listProfile: "init",
  audience: "init",
  dailyEngagementRate: "init",
  mentionedBrands: "init",
  workedWithBrands: "init",
  detectedBrands: "init",
  mentionedPostsStatus: "init",
  mentionedPostsContent: [],
  loadedVerticals: [],
  loadingVerticals: false,
  editModalOpen: false,
  editModalLoadingPrimaryAction: false,
  addToModalPhase: null,
  selectedSocialAccountID: "",
  selectedCampaignIDs: [],
  selectedSocialAccountNetwork: null,
  campaignsForProfile: "init",
  campaigns: "init",
  campaignsForAccount: "init",
  fetchedTopPosts: [],
  imageTagPosts: [],
  toggleFetchingImageTags: false,
  activeTagId: "",
  ImageTagsWithPosts: [],
  keywordPosts: [],
  keywordsWithPosts: [],
  toggleFetchingKeywords: false,
  commsForProfile: "init",
  comms: "init",
  commsForAccount: "init",
  selectedCommIDs: [],
  modalAddToCommsOpen: false,
}

// Profile Slice
export const listSocialProfileSlice = createSlice({
  name: "ListSocialProfile",
  initialState,
  reducers: {
    setListProfileToggles: (
      state,
      action: PayloadAction<Status<GraphQL.SearchListSocialProfileTogglesQuery>>,
    ) => ({
      ...state,
      listProfileToggles: action.payload,
    }),
    setListProfile: (
      state,
      action: PayloadAction<Status<GraphQL.SearchListSocialProfileQuery>>,
    ) => ({
      ...state,
      listProfile: action.payload,
    }),
    setAudience: (
      state,
      action: PayloadAction<Status<GraphQL.AudienceDemographicsQuery>>,
    ) => ({
      ...state,
      audience: action.payload,
    }),
    setDailyEngagementRate: (
      state,
      action: PayloadAction<Status<GraphQL.GetDailyHourlyEngagementSummaryQuery>>,
    ) => ({
      ...state,
      dailyEngagementRate: action.payload,
    }),
    setMentionedBrands: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      mentionedBrands: action.payload,
    }),
    setWorkedWithBrands: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      workedWithBrands: action.payload,
    }),
    setDetectedBrands: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      detectedBrands: action.payload,
    }),
    setMentionedPostsStatus: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedPostsQuery>>,
    ) => ({
      ...state,
      mentionedPostsStatus: action.payload,
    }),
    setMentionedPostsContent: (
      state,
      action: PayloadAction<Array<GraphQL.MentionedPostFragment>>,
    ) => ({
      ...state,
      mentionedPostsContent: action.payload,
    }),
    setLoadedVerticals: (
      state,
      action: PayloadAction<GraphQL.VerticalFragment[]>,
    ) => ({
      ...state,
      loadedVerticals: action.payload,
    }),
    setEditModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      editModalOpen: action.payload,
    }),
    setLoadingVerticals: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      loadingVerticals: action.payload,
    }),
    setEditModalLoadingPrimaryAction: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      editModalLoadingPrimaryAction: action.payload,
    }),
    setSelectedSocialAccountID: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedSocialAccountID: action.payload,
    }),
    setAddToModalPhase: (
      state,
      action: PayloadAction<null | 1 | 2>,
    ) => ({
      ...state,
      addToModalPhase: action.payload,
    }),
    setSelectedCampaignIDs: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedCampaignIDs: action.payload,
    }),
    setCampaignsForProfile: (state, action: PayloadAction<Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>>) => ({
      ...state,
      campaignsForProfile: action.payload,
    }),
    setSelectedSocialAccountNetwork: (
      state,
      action: PayloadAction<GraphQL.Network | null>,
    ) => ({
      ...state,
      selectedSocialAccountNetwork: action.payload,
    }),
    setCampaigns: (state, action: PayloadAction<Status<GraphQL.SearchCampaignsQuery>>) => ({
      ...state,
      campaigns: action.payload,
    }),
    setCampaignsForAccount: (state, action: PayloadAction<Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>>) => ({
      ...state,
      campaignsForAccount: action.payload,
    }),
    toggleSelectedCampaign: (
      state,
      action: PayloadAction<string>,
    ) => {
      const indexOfID = state.selectedCampaignIDs.indexOf(action.payload)
      // Remove if already selected
      if (indexOfID !== -1) {
        const newIDs = [ ...state.selectedCampaignIDs ]
        newIDs.splice(indexOfID, 1)
        return {
          ...state,
          selectedCampaignIDs: newIDs,
        }
      }
      if (state.selectedCampaignIDs.length < 10) {
        return {
          ...state,
          selectedCampaignIDs: [ ...state.selectedCampaignIDs, action.payload ],
        }
      }
      return state
    },
    setFetchedTopPosts: (
      state,
      action: PayloadAction<GraphQL.SuggestionsPostFragment[]>,
    ) => ({
      ...state,
      fetchedTopPosts: action.payload,
    }),
    setKeywordPosts: (
      state,
      action: PayloadAction<GraphQL.PostFragment[]>,
    ) => ({
      ...state,
      keywordPosts: action.payload,
    }),
    setImageTagPosts: (
      state,
      action: PayloadAction<GraphQL.PostFragment[]>,
    ) => ({
      ...state,
      imageTagPosts: action.payload,
    }),
    setImageTagswithPosts: (
      state,
      action: PayloadAction<GraphQL.SuggestionListImageTag[]>,
    ) => ({
      ...state,
      ImageTagsWithPosts: action.payload,
    }),
    setKeywordswithPosts: (
      state,
      action: PayloadAction<GraphQL.SuggestionListKeyword[]>,
    ) => ({
      ...state,
      keywordsWithPosts: action.payload,
    }),
    setToggleFetchingImageTags: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      toggleFetchingImageTags: action.payload,
    }),
    setToggleFetchingKeywords: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      toggleFetchingKeywords: action.payload,
    }),
    setActiveTagId: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      activeTagId: action.payload,
    }),
    setCommsForProfile: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>>) => ({
      ...state,
      commsForProfile: action.payload,
    }),
    setComms: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsQuery>>) => ({
      ...state,
      comms: action.payload,
    }),
    setCommsForAccount: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>>) => ({
      ...state,
      commsForAccount: action.payload,
    }),
    setSelectedCommIDs: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedCommIDs: action.payload,
    }),
    setModalAddToCommsOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      modalAddToCommsOpen: action.payload,
    }),
  },
})

export const {
  setListProfile,
  setAudience,
  setDailyEngagementRate,
  setDetectedBrands,
  setMentionedBrands,
  setWorkedWithBrands,
  setMentionedPostsContent,
  setMentionedPostsStatus,
  setListProfileToggles,
  setLoadingVerticals,
  setEditModalOpen,
  setLoadedVerticals,
  setEditModalLoadingPrimaryAction,
  setAddToModalPhase,
  setSelectedCampaignIDs,
  setSelectedSocialAccountID,
  setCampaignsForProfile,
  setSelectedSocialAccountNetwork,
  setCampaigns,
  setCampaignsForAccount,
  toggleSelectedCampaign,
  setFetchedTopPosts,
  setImageTagPosts,
  setToggleFetchingImageTags,
  setImageTagswithPosts,
  setActiveTagId,
  setKeywordPosts,
  setKeywordswithPosts,
  setToggleFetchingKeywords,
  setCommsForProfile,
  setComms,
  setCommsForAccount,
  setModalAddToCommsOpen,
} = listSocialProfileSlice.actions
export default listSocialProfileSlice.reducer

// Profile Slice Thunks
export const fetchListSocialProfileToggles = (
  params: GraphQL.SearchListSocialProfileQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setListProfileToggles("loading"))

  const result = await API.fetchListSocialProfileToggles(params)

  dispatch(setListProfileToggles(result))
}

export const fetchListSocialProfile = (
  params: GraphQL.SearchListSocialProfileQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setListProfile("loading"))

  const result = await API.fetchListSocialProfile(params)

  dispatch(setListProfile(result))
}

export const fetchAudience = (
  params: Omit<GraphQL.AudienceDemographicsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setAudience("loading"))

  const result = await API.fetchSocialProfileAudience(params)
  dispatch(setAudience(result))
}

export const getDailyHourlyEngagementRate = (
  variables: GraphQL.GetDailyHourlyEngagementSummaryQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDailyEngagementRate("loading"))

  const result = await API.getDailyHourlyEngagementSummary(variables)

  dispatch(setDailyEngagementRate(result))
}

export const fetchMentionedBrands = (
  params: Omit<GraphQL.GetMentionedSocialAccountsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMentionedBrands("loading"))

  const result = await API.getMentionedSocialAccounts({
    ...params,
    type: GraphQL.MentionType.Brand,
  })

  dispatch(setMentionedBrands(result))
}

export const fetchWorkedWithBrands = (
  params: Omit<GraphQL.GetMentionedSocialAccountsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setWorkedWithBrands("loading"))

  const result = await API.getMentionedSocialAccounts({
    ...params,
    type: GraphQL.MentionType.BrandWorkedWith,
  })

  dispatch(setWorkedWithBrands(result))
}

export const fetchDetectedBrands = (
  params: Omit<GraphQL.GetMentionedSocialAccountsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDetectedBrands("loading"))

  const result = await API.getMentionedSocialAccounts({
    ...params,
    type: GraphQL.MentionType.BrandLogoDetection,
  })

  dispatch(setDetectedBrands(result))
}

export const getMentionedPosts = (
  params: FetchMentionedPostParams,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMentionedPostsStatus("loading"))
  const result = await API.fetchMentionedPosts({
    mentionAccountId: params.socialAccountId,
    networkAccountId: params.socialProfileId,
    type: params.mentionType,
    limit: 50,
    page: params.page,
  })

  if (API.isSuccess(result)) {
    dispatch(setMentionedPostsStatus(result))
    dispatch(setMentionedPostsContent(result.payload.getMentionedCippus.rows))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const getMoreMentionedPosts = (
  params: FetchMentionedPostParams,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const result = await API.fetchMentionedPosts({
    mentionAccountId: params.socialAccountId,
    networkAccountId: params.socialProfileId,
    type: params.mentionType,
    limit: 50,
    page: params.page,
  })

  if (API.isSuccess(result)) {
    const { listSocialProfile: { mentionedPostsContent: currentContent } } = getState()
    dispatch(setMentionedPostsContent([ ...currentContent, ...result.payload.getMentionedCippus.rows ]))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const getCampaigns = (searchInput: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCampaigns("loading"))

  const campaignsResults = await API.fetchCampaigns({
    startsWith: searchInput,
    column: GraphQL.SearchCampaignSort.CampaignName,
    direction: GraphQL.SortDirection.Desc,
    limit: 200,
  })

  dispatch(setCampaigns(campaignsResults))
}

export const getCampaignsForProfile = (
  networkAccountIds: string[],
  networkFilter: GraphQL.Network | GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCampaignsForProfile("loading"))
  if (!networkFilter) {
    dispatch(setCampaignsForProfile({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCampaignsByNetworkIds({
      networkAccountIds,
      networkFilter,
      limit: 200,
    })

    dispatch(setCampaignsForProfile(results))
  }
}

export const getCampaignsForAccount = (
  networkAccountIds: string[],
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCampaignsForAccount("loading"))
  if (!networkFilter) {
    dispatch(setCampaignsForAccount({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCampaignsByNetworkIds({
      networkAccountIds,
      networkFilter,
      limit: 200,
    })

    dispatch(setCampaignsForAccount(results))
  }
}

export const submitSocialAccountsToCampaign = async (
  socialAccountID: string,
  campaignIDs: string[],
  onSuccess: () => any,
  onError: () => any,
) => {
  const results = await Promise
    .all(campaignIDs.map((id) => API.createCampaignNetworkAccount({ campaignId: id, networkAccountId: socialAccountID })))

  if (results.some((result) => API.isError(result))) {
    onError()
  } else {
    onSuccess()
  }
}

export const updateListSocialProfile = (
  params: GraphQL.UpdateSuggestionListSocialAccountMutationVariables,
  listId: string,
  socialAccountId: string,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setEditModalLoadingPrimaryAction(true))
  await API.updateSuggestionListSocialAccount(params)
  dispatch(setEditModalLoadingPrimaryAction(false))
  fetchListSocialProfile({
    listId,
    socialAccountId,
  })(dispatch)
}

export const searchVerticals = (
  params: GraphQL.SearchVerticalsQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLoadingVerticals(true))

  const result = await API.query<
      GraphQL.SearchVerticalsQuery,
      GraphQL.SearchVerticalsQueryVariables
      >(GraphQL.SearchVerticalsDocument, params)
  if (API.isSuccess(result)) {
    dispatch(setLoadedVerticals(result.payload.searchVerticals.rows))
  }
  dispatch(setLoadingVerticals(false))
}

export const fetchTopPosts = (
  params: GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdForContentTabQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  const result = await API.fetchTopPostsForContentTab(params.listId, params.socialAccountId)
  if (API.isSuccess(result)) {
    const posts = result.payload.suggestionListSocialAccountByListIdSocialAccountId.cippusFeatured.map((item) => item.post)
    dispatch(setFetchedTopPosts(posts))
  }
}

export const getSuggestionListPostsByImageTag = (
  params: GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQueryVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const {
    listSocialProfile: {
      ImageTagsWithPosts,
    },
  } = getState()
  dispatch(setToggleFetchingImageTags(true))
  const result = await API.getSuggestionListPostsByImageTag(params)
  if (API.isSuccess(result)) {
    const { cippusImageTags } = result.payload.suggestionListSocialAccountByListIdSocialAccountId
    const posts = cippusImageTags.map((item) => item.post)
    dispatch(setImageTagPosts(posts))
    if (!ImageTagsWithPosts.length) {
      dispatch(setImageTagswithPosts(result.payload.suggestionListSocialAccountByListIdSocialAccountId.imageTagsWithPosts))
    }
  }
  dispatch(setToggleFetchingImageTags(false))
}

export const getSuggestionListPostsByKeyword = (
  params: GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQueryVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const {
    listSocialProfile: {
      keywordsWithPosts,
    },
  } = getState()
  dispatch(setToggleFetchingKeywords(true))
  const result = await API.getSuggestionListPostsByKeyword(params)
  if (API.isSuccess(result)) {
    const { cippusRelevant } = result.payload.suggestionListSocialAccountByListIdSocialAccountId
    const posts = cippusRelevant.map((item) => item.post)
    dispatch(setKeywordPosts(posts))
    if (!keywordsWithPosts.length) {
      dispatch(setKeywordswithPosts(result.payload.suggestionListSocialAccountByListIdSocialAccountId.keywordsWithPosts))
    }
  }
  dispatch(setToggleFetchingKeywords(false))
}

// Non-thunk helpers
export const checkDisplayAccountInsights = (toggleValues: string[]) => {
  const insightDisplayValues = Object.values(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES)
  return toggleValues.some((toggle) => insightDisplayValues.includes(toggle))
}

export const checkDisplayAudienceDetails = (toggleValues: string[]) => {
  const audienceDisplayValues = Object.values(AUDIENCE_DISPLAY_TOGGLES)
  return toggleValues.some((toggle) => audienceDisplayValues.includes(toggle))
}

export const getCommsForProfile = (params: {
  networkAccountIds: string[],
  networkFilter: GraphQL.Network[] | null,
}) => async (dispatch: Dispatch) => {
  dispatch(setCommsForProfile("loading"))
  if (!params.networkFilter) {
    dispatch(setCommsForProfile({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCommunicationsByNetworkAccountIds({
      networkAccountIds: params.networkAccountIds,
      networkFilter: params.networkFilter,
      page: 1,
      limit: 200,
    })

    dispatch(setCommsForProfile(results))
  }
}

export const getComms = (searchInput: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setComms("loading"))

  const commsResults = await API.query<
      GraphQL.SearchCommunicationGroupsQuery,
      GraphQL.SearchCommunicationGroupsQueryVariables
    >(GraphQL.SearchCommunicationGroupsDocument, {
      startsWith: searchInput,
    })
  dispatch(setComms(commsResults))
}

export const getCommsForAccount = (
  networkAccountId: string,
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCommsForAccount("loading"))
  if (!networkFilter) {
    dispatch(setCommsForAccount({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCommunicationsByNetworkAccountIds({
      networkAccountIds: networkAccountId,
      networkFilter,
      page: 1,
      limit: 200,
    })

    dispatch(setCommsForAccount(results))
  }
}
