/* eslint-disable no-shadow */
import {
  LIST_DISPLAY_TOGGLES,
  PUBLIC_DETAILS_DISPLAY_TOGGLES,
  AUDIENCE_DISPLAY_TOGGLES,
  CONTENT_DISPLAY_TOGGLES,
  HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES,
  HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES,
  HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_CONTENT_DISPLAY_TOGGLES,
  APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
} from "../../util/constant"
import { Metric } from "../../state/listConfigurationSlice"

import * as GraphQL from "../../graphql"

// ############## ENUMS ####################

// #############  TYPES #####################
export type DemoScoreFormValueCompare =
  GraphQL.Sex[]
  | GraphQL.Ethnicity[]
  | GraphQL.Family[]
  | GraphQL.Religion[]
  | GraphQL.IncomeBrackets[]
  | GraphQL.AudienceLocation[]
  | Occupation[]
  | Affinity[]
  | { from: number, to: number }

export type Occupation = { __typename?: "Tag", id: string, name: string, code: string }

export type Affinity = { __typename?: "Tag", id: string, name: string, code: string }

export type GroupMetrics = {
  groupName: string
  metrics: Metric[]
}

export type DemographicScoreForm = {
  sexes: GraphQL.Sex[]
  age: {
    from: number,
    to: number,
  },
  ethnicity: GraphQL.Ethnicity[]
  family: GraphQL.Family[]
  religion: GraphQL.Religion[]
  income: GraphQL.IncomeBrackets[]
  locations: GraphQL.AudienceLocation[]
  occupations: Occupation[]
  affinities: Affinity[]
}

// ################ CONSTANTS ###############
export const highlightMetrics: GroupMetrics[] = [ {
  groupName: "Metrics",
  metrics: [],
}, {
  groupName: "Public List Account Details",
  metrics: [
    {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.AGE,
      name: "Age",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.GENDER,
      name: "Gender",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION,
      name: "Location",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.TAGS,
      name: "Tags",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.BIO,
      name: "Bio",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.RATIONALE,
      name: "Rationale",
    },
  ],
}, {
  groupName: "Scores & Engagement Rate",
  metrics: [ {
    code: LIST_DISPLAY_TOGGLES.ENG_SCORE,
    name: "Engagement Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.ENG_RATE,
    name: "Engagement Rate (90 days)",
  }, {
    code: LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE,
    name: "TTCM Engagement Rate",
  }, {
    code: LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO,
    name: "Age/Gender In Demo",
  }, {
    code: LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
    name: "Demographic Score*",
  }, {
    code: LIST_DISPLAY_TOGGLES.AUDIENCE_AUTHENTICITY,
    name: "Audience Authenticity Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
    name: "Ad Council Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.ISCORE,
    name: "I-Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.NO_SCORE,
    name: "None",
  } ],
}, {
  groupName: "Account Insights",
  metrics: [ {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
    name: "Engagement by Post Type Organic vs Sponsored",
  }, {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
    name: "Engagement by Post Type Reel vs Non-Reel",
  }, {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
    name: "Brands Mentions & Brands Worked With",
  }, {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
    name: "Personality Traits",
  } ],
}, {
  groupName: "Audience Metrics (up to 5)",
  metrics: [ {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.GENDER,
    name: "Gender",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
    name: "Family Status",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
    name: "Age Range",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
    name: "Income Range",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
    name: "Ethnicity",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
    name: "Religion",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
    name: "Countries",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.STATES,
    name: "States",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.CITIES,
    name: "Cities",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
    name: "Occupations",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
    name: "Affinities",
  } ],
}, {
  groupName: "Content",
  metrics: [ {
    code: HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
    name: "Top Posts",
  }, {
    code: HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS,
    name: "Relevant Posts",
  }, {
    code: HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
    name: "Post Impressions",
  } ],
} ]

export const initialDemographicScoreForm: DemographicScoreForm = {
  sexes: [],
  age: {
    from: 0,
    to: 0,
  },
  ethnicity: [],
  family: [],
  religion: [],
  income: [],
  locations: [],
  occupations: [],
  affinities: [],
}

export const ReadableAgeRange = {
  [GraphQL.AgeRange.Under_18]: "17 and under",
  [GraphQL.AgeRange["18_20"]]: "18 to 20",
  [GraphQL.AgeRange["21_24"]]: "21 to 24",
  [GraphQL.AgeRange["25_29"]]: "25 to 29",
  [GraphQL.AgeRange["30_34"]]: "30 to 34",
  [GraphQL.AgeRange["35_44"]]: "35 to 44",
  [GraphQL.AgeRange["45_54"]]: "45 to 54",
  [GraphQL.AgeRange["55_64"]]: "55 to 64",
  [GraphQL.AgeRange.Above_64]: "65 and over",
}

export const ReadableEthnicity = {
  [GraphQL.Ethnicity.AfricanAmerican]: "African American",
  [GraphQL.Ethnicity.AsianPacificIslander]: "Asian / Pacific Islander",
  [GraphQL.Ethnicity.HispanicLatino]: "Hispanic / Latino",
  [GraphQL.Ethnicity.WhiteCaucasian]: "White / Caucasian",
}

export const ReadableFamilyStatus = {
  [GraphQL.FamilyStatus.Married]: "Married",
  [GraphQL.FamilyStatus.Parent]: "Parent",
  [GraphQL.FamilyStatus.Single]: "Single",
  [GraphQL.FamilyStatus.NonParent]: "Non-parent",
}

export const ReadableFamily = {
  [GraphQL.Family.Married]: "Married",
  [GraphQL.Family.Parent]: "Parent",
  [GraphQL.Family.Single]: "Single",
}

export const ReadableReligion = {
  [GraphQL.Religion.Christian]: "Christian",
  [GraphQL.Religion.Jewish]: "Jewish",
  [GraphQL.Religion.Muslim]: "Muslim",
}

export const ReadableIncome = {
  [GraphQL.IncomeBrackets["100000Above"]]: "$100,000+",
  [GraphQL.IncomeBrackets["10000_19999"]]: "$10,000 - $19,999",
  [GraphQL.IncomeBrackets["20000_29999"]]: "$20,000 - $29,999",
  [GraphQL.IncomeBrackets["30000_39999"]]: "$30,000 - $39,999",
  [GraphQL.IncomeBrackets["40000_49999"]]: "$40,000 - $49,999",
  [GraphQL.IncomeBrackets["50000_74999"]]: "$50,000 - $74,999",
  [GraphQL.IncomeBrackets["75000_100000"]]: "$75,000 - $99,999",
  [GraphQL.IncomeBrackets.Under_10000]: "$0 - $9,999",
}

export const engagementToggles = [
  LIST_DISPLAY_TOGGLES.ENG_SCORE,
  LIST_DISPLAY_TOGGLES.ENG_RATE,
  LIST_DISPLAY_TOGGLES.AUDIENCE_AUTHENTICITY,
  LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
  LIST_DISPLAY_TOGGLES.ISCORE,
  LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
  LIST_DISPLAY_TOGGLES.NO_SCORE,
  LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE,
  AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
]

export const defaultSelectedMetrics = [
  PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION,
  PUBLIC_DETAILS_DISPLAY_TOGGLES.TAGS,
  PUBLIC_DETAILS_DISPLAY_TOGGLES.BIO,
  PUBLIC_DETAILS_DISPLAY_TOGGLES.RATIONALE,
  LIST_DISPLAY_TOGGLES.ENG_SCORE,
  LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO,
  CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
]

export const accountInsightDisplayCodes = [
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
]

export const audienceDisplayCodes = [
  AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
  AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
  AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
  AUDIENCE_DISPLAY_TOGGLES.BASELINE,
  AUDIENCE_DISPLAY_TOGGLES.CITIES,
  AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
  AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL,
  AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS,
  AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
  AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
  AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
  AUDIENCE_DISPLAY_TOGGLES.GENDER,
  AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
  AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES,
  AUDIENCE_DISPLAY_TOGGLES.LANGUAGES,
  AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
  AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
  AUDIENCE_DISPLAY_TOGGLES.STATES,
  AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES,
]

export const contentDisplayCodes = [
  CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
  CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS,
  CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
]

export const appendixMetrics: GroupMetrics[] = [ {
  groupName: "Metrics",
  metrics: [],
}, {
  groupName: "Account Insights",
  metrics: [ {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME,
    name: "Engagement Rate over Time",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
    name: "Engagement by Post Type Organic vs Sponsored",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
    name: "Engagement by Post Type Reel vs Non-Reel",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP,
    name: "Engagement Heat Map",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS,
    name: "Recent Media & AI Image Analysis",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
    name: "Recent Media Stories",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
    name: "Brands Mentions & Brands Worked With",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
    name: "Personality Traits",
  } ],
}, {
  groupName: "Audience Metrics",
  metrics: [ {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
    name: "Followers Over Time",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
    name: "Audience Authenticity",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.GENDER,
    name: "Gender",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
    name: "Family Status",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
    name: "Age Range",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
    name: "Income Range",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL,
    name: "Education Level",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
    name: "Ethnicities",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.LANGUAGES,
    name: "Languages",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
    name: "Religions",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
    name: "Countries",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.STATES,
    name: "States",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.CITIES,
    name: "Cities",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
    name: "Occupations",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES,
    name: "Industries",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS,
    name: "Employers",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES,
    name: "Universities",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
    name: "Affinities",
  } ],
}, {
  groupName: "Content",
  metrics: [ {
    code: APPENDIX_CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
    name: "Top Posts",
  }, {
    code: APPENDIX_CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS,
    name: "Relevant Posts",
  }, {
    code: APPENDIX_CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
    name: "Post Impressions",
  } ],
} ]

export const contentTitleMetrics = {
  TOP_POSTS: "Top Posts",
  RELEVANT_POSTS: "Relevant Posts",
  POST_IMPRESSIONS: "Post Impressions",
}
