import React from "react"

import { useSelector } from "react-redux"

import { ReactComponent as EngagementRateOverTimeIcon } from "../../../asset/visualizations/LIST_ENGAGEMENT_RATE_EX.svg"
import { ReactComponent as EngagementByPostTypeOSIcon } from "../../../asset/visualizations/LIST_ENG_POST_TYPE_EX.svg"
import { ReactComponent as EngagementByPostTypeRNIcon } from "../../../asset/visualizations/LIST_ENG_POST_TYPE_REEL_EX.svg"
import { ReactComponent as EngagementHeatMapIcon } from "../../../asset/visualizations/LIST_ENG_HEATMAP_EX.svg"
import { ReactComponent as RecentMediaAiAnalysisIcon } from "../../../asset/visualizations/LIST_RECENT_MEDIA_AI_EX.svg"
import { ReactComponent as BrandsMentionedIcon } from "../../../asset/visualizations/LIST_BRANDS_MENTIONED_EX.svg"
import { ReactComponent as RecentMedialStoriesIcon } from "../../../asset/visualizations/LIST_RECENT_STORIES_EX.svg"
import { ReactComponent as PersonalityTraitsIcon } from "../../../asset/visualizations/LIST_PERSONALITY_TRAITS_EX.svg"
import { ACCOUNT_INSIGHTS_DISPLAY_TOGGLES } from "../../../util/constant"

export default function AccountInsightsVisualizations() {
  // Pull the list form to check toggles
  const updateListForm = useSelector(({ listConfiguration }) => listConfiguration.updateListForm)

  return (
    <div className="cp_lcp-preview_example-visualizations">
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME)) && (
        <EngagementRateOverTimeIcon />
      ) }
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE)) && (
        <EngagementByPostTypeOSIcon />
      ) }
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL)) && (
        <EngagementByPostTypeRNIcon />
      ) }
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP)) && (
        <EngagementHeatMapIcon />
      ) }
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS)) && (
        <RecentMediaAiAnalysisIcon />
      ) }
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH)) && (
        <BrandsMentionedIcon />
      ) }
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES)) && (
        <RecentMedialStoriesIcon />
      ) }
      { (updateListForm.toggles.includes(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS)) && (
        <PersonalityTraitsIcon />
      ) }
    </div>
  )
}
