import { createSlice } from "@reduxjs/toolkit"
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit"
import { CreateTcmOrderMutationVariables } from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"

export interface inviteTikTokModalState {
  tikTokInviteLinkOpen: boolean
  deliverableId?: string
  deliverableVersion?: "v1" | "v2"
  onClose?: () => any
}

const initialState: inviteTikTokModalState = {
  tikTokInviteLinkOpen: false,
}

export const tikTokInviteModal = createSlice({
  name: "TikTokInviteModal",
  initialState,
  reducers: {
    openTikTokInviteModal: (
      state,
      action: PayloadAction<{
        onClose?: () => any,
        deliverableId: string,
        deliverableVersion: "v1" | "v2"
      }>,
    ) => ({
      ...state,
      tikTokInviteLinkOpen: true,
      onClose: action.payload.onClose,
      deliverableId: action.payload.deliverableId,
      deliverableVersion: action.payload.deliverableVersion,
    }),
    closeTikTokInviteModal: (
      state,
    ) => ({
      ...state,
      tikTokInviteLinkOpen: false,
      deliverableId: undefined,
      deliverableVersion: undefined,
    }),
  },
})

export const {
  closeTikTokInviteModal,
  openTikTokInviteModal,
} = tikTokInviteModal.actions
export default tikTokInviteModal.reducer

export const generateLink = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState()
  const deliverableId = state.tikTokInviteModalSlice?.deliverableId
  if (!deliverableId) return null
  const params: CreateTcmOrderMutationVariables = {
    deliverableId,
  }
  const result = await API.createTctmOrder(params)
  return result
}
