import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Campaign, CampaignVersion } from "../../graphqlV2/types/campaignTypes"

interface campaignDetailsState{
campaignDetails: Campaign
campaignVersion: CampaignVersion
}

const defaultCampaignDetails: Campaign = {
  id: "",
  numberOfCampaignNetworkAccounts: 0,
  numberOfAllDeliverables: 0,
  numberOfDeliverablesWithoutContent: 0,
  numberOfPendingFeedBackDeliverables: 0,
  numberOfPendingRevisionDeliverables: 0,
  numberOfFinalizedDeliverables: 0,
  numberOfLiveDeliverables: 0,
  deliverableSummaryList: [],
}

const defaultCampaignVersion: CampaignVersion = {
  id: "",
  isMigrated: false,

}

const initialState: campaignDetailsState = {
  campaignDetails: defaultCampaignDetails,
  campaignVersion: defaultCampaignVersion,
}

export const campaignDetailsSlice = createSlice({
  name: "campaignV2Slice",
  initialState,
  reducers: {
    setIsCampaignMigrated: (state, action: PayloadAction<CampaignVersion>) => ({
      ...state,
      campaignVersion: action.payload,
    }),
    setCampaignDetails: (state, action: PayloadAction<Campaign>) => ({
      ...state,
      campaignDetails: action.payload,
    }),
  },
})

export const {
  setIsCampaignMigrated,
  setCampaignDetails,
} = campaignDetailsSlice.actions

export default campaignDetailsSlice.reducer
