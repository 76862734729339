import React from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  DeliverableQueryVariables,
  FINALIZE_REVIEWER_GROUP_FEEDBACK,
  FinalizeReviewerGroupFeedbackOutput,
  GET_DELIVERABLE,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import LoadingIndicator from "../LoadingIndicator"
import { setFinalizeConfirmationModalOpen } from "../../state/campaignDeliverableContentModalV2Slice"

import "./finalize-deliverable-confirmation-modal.sass"

export default function FinalizeDeliverableConfirmationModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.FinalizeDeliverableConfirmationModal" })

  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""

  const dispatch = useDispatch()

  const { finalizeConfirmationModalOpen } = useSelector(
    ({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2,
  )

  const [ finalizeReviewerGroupFeedback, { loading } ] = useMutation<
    FinalizeReviewerGroupFeedbackOutput, DeliverableQueryVariables
  >(FINALIZE_REVIEWER_GROUP_FEEDBACK)

  const handleSubmit = async () => {
    await finalizeReviewerGroupFeedback({
      variables: {
        deliverableId,
      },
      context: {
        apiVersion: "v2",
      },
      refetchQueries: [ {
        query: GET_DELIVERABLE,
        variables: { deliverableId },
        context: {
          apiVersion: "v2",
        },
      } ],
      awaitRefetchQueries: true,
    })
    handleClose()
  }

  const handleClose = () => {
    dispatch(setFinalizeConfirmationModalOpen(false))
  }

  return (
    <Modal
      className="cp_component_finalize-deliverable-confirmation-modal"
      title={ translate("Finalize Group Feedback") }
      subtitle={ translate("You cannot undo this action") }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("CONFIRM") }
      primaryAction={ handleSubmit }
      disabled={ loading }
      secondaryLabel={ translate("GO BACK") }
      secondaryAction={ handleClose }
      secondaryDisabled={ loading }
      open={ finalizeConfirmationModalOpen }
      closeAction={ handleClose }
    >
      <p className="confirm-finalize-warning">
        { translate("CONFIRM FINALIZE WARNING") }
      </p>
    </Modal>
  )
}
