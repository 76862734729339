import React from "react"
import "./style.sass"
import { isSuccess } from "../../../util/apiClient"
import { useSelector } from "../../../state/hooks"
import MetricsRibbonBanner from "./MetricsRibbonBanner"
import SocialAvatar from "../../SocialAvatar"
import AccountInsights from "./AccountInsights"
import PublicAccountDetails from "./PublicAccountDetails"

export default function PreviewHighlights() {
  // Pull in the list
  const list = useSelector((state) => state.list.list)

  // Extract the accounts
  const accounts = React.useMemo(() => {
    if (isSuccess(list)) {
      const { suggestionListCategories } = list.payload.suggestionListById
      return suggestionListCategories.map((category) => category.suggestionListSocialAccounts).flat()
    }
    return []
  }, [ list ])

  return (
    <div className="cp_lcp_highlights-previews">
      <h5 className="title_large">Highlights</h5>
      { accounts.map((account) => (
        <div className="cp_lcp_highlights-previews_account-container" key={ account.id }>
          <SocialAvatar
            followers={ account.socialAccount.socialAccountStatistics.followers }
            fullName=""
            isBlacklisted={ false }
            isNSFW={ false }
            isPlaceholder={ false }
            isUnsubscribed={ false }
            network={ account.socialAccount.network }
            profilePictureUrl={ account.socialAccount.profilePictureUrl }
            username={ account.socialAccount.userName }
          />
          <MetricsRibbonBanner key={ account.id } account={ account } />
          <AccountInsights key={ account.id } account={ account } />
          <PublicAccountDetails key={ account.id } account={ account } />
        </div>
      )) }
    </div>
  )
}
