import React from "react"

import BrandManagerPermissionsModal from "../BrandManagerPermissionsModal"
import CampaignModal from "../ModalCampaign"
import CampaignUpdateDeliverableMetricsModal from "../CampaignTabs/Tabs/CampaignUpdateDeliverableMetricsModal"
import ConfirmationModal from "../ConfirmationModal"
import ListFormModal from "../ListFormModal"
import ModalAddToLists from "../ModalAddToLists"
import ModalCampaignDeliverable from "../ModalCampaignDeliverable"
import ModalCampaignDeliverableContent from "../ModalCampaignDeliverableContent"
import ModalCommGroup from "../ModalCommGroup"
import ModalConfirmFinalize from "../ModalCampaignDeliverableContent/ModalConfirm"
import ModalCopyLink from "../ModalCopyLink"
import ModalCreateCustomer from "../ModalCreateCustomer"
import ModalCreateListGroup from "../ModalCreateListGroup"
import ModalCustomerActivation from "../ModalCustomerActivation"
import ModalCustomerResetPassword from "../ModalCustomerResetPassword"
import ModalCustomerUser from "../ModalCustomerUser"
import ModalEditListProfile from "../ModalEditListProfile"
import ModalLinkDeliverables from "../CampaignTabs/Tabs/CampaignAds/ModalLinkDeliverables"
import ModalManageBrandManagers from "../CampaignTabs/Tabs/CampaignOverviewDetails/UserListCards/Modals/ModalManageBrandManagers"
import ModalManageClients from "../CampaignTabs/Tabs/CampaignOverviewDetails/UserListCards/Modals/ModalManageClients"
import ModalManageManagers from "../CampaignTabs/Tabs/CampaignOverviewDetails/UserListCards/Modals/ModalManageCampaignManagers"
import ModalNewTracker from "../ModalNewTracker"
import ModalProfile from "../ModalProfile"
import ModalTikTokInvite from "../ModalTikTokInviteLink"
import SelectCampaignModal from "../ModalSelectCampaign"
import SocialTrackerDownloadCSVModal from "../NavigationBar/SlidingPanels/SocialTrackerPanel/SocialTrackerDownloadCSVModal"
// eslint-disable-next-line max-len
import UnassignedThreadAssignModal from "../NavigationBar/SlidingPanels/CommunicationsPanel/UnassignedContent/UnassignedThreadAssignModal"
// eslint-disable-next-line max-len
import UnassignedThreadViewModal from "../NavigationBar/SlidingPanels/CommunicationsPanel/UnassignedContent/UnassignedThreadViewModal"
import UpdateAudienceTargettingModal from "../CampaignTabs/Tabs/CampaignAds/UpdateAudienceTargettingModal"
import ModalCommsCreateEditTemplate from "../ModalCommsCreateEditTemplate"
import ModalCreateMessage from "../ModalCreateMessage"
import AssignReviewersModal from "../CampaignTabs/Tabs/AssignReviewers/AssignReviewersModal"
import ModalCreateDeliverable from "../ModalCreateDeliverable"
import GroupAccountsContactInfoModal from "../AccountContactInfoModal"
import ModalEditEmailsByTeam from "../ModalEditEmailsByTeam"
import ModalCampaignDeliverableAssignReviewerGroup from "../ModalCampaignDeliverableAssignReviewerGroup"
import GroupAccountsFilterModal from "../ModelGroupAccountsFilter"
import ModalCampaignDeliverableContentV2 from "../ModalCampaignDeliverableContentV2"
import ModalCampaignAssociationWarning from "../ModalCreateDeliverable/ModalCampaignAssociationWarning"
import ModalAddUserToCampaign from "../ModalCreateDeliverable/ModalAddUserToCampaign"
import ModalAddToCommunications from "../ProfileTabs/ListSocialProfileTabs/ModalAddToCommunications"
import OAuthUserAccountModal from "../ModalOAuthUserAccount"
import UserOauthContinueModal from "../ModalOAuthUserContinue"
import ModalEditURL from "../ModalEditURL"
import RoleModal from "../ModalRole"
import FinalizeDeliverableConfirmationModal from "../ModalCampaignDeliverableContentV2/FinalizeDeliverableConfirmationModal"
import ModalSearchAISummary from "../SearchAIAccountsTable/ModalSummary"
import ModalRenameCategory from "../ModalEditCategory"
import ModalRemoveListAccount from "../ListManagement/ModalRemoveListAccount"
import ModalRemoveCategory from "../ListManagement/ModalRemoveCategory"
import ModalCreateFeedback from "../ListManagement/ModalCreateFeedback"
import UpdateStatusModal from "../ModalCampaignDeliverableContentV2/UpdateStatusModal"

// Single mount point for ALL modals in Radius
export default function Modals() {
  return (
    <>
      <AssignReviewersModal />
      <CampaignModal />
      <CampaignUpdateDeliverableMetricsModal />
      <ConfirmationModal />
      <ListFormModal />
      <ModalAddToLists />
      <ModalCampaignDeliverable />
      <ModalCampaignDeliverableContent />
      <ModalCampaignDeliverableContentV2 />
      <ModalCommGroup />
      <ModalConfirmFinalize />
      <ModalTikTokInvite />
      <ModalCopyLink />
      <ModalLinkDeliverables />
      <ModalManageBrandManagers />
      <ModalManageClients />
      <ModalManageManagers />
      <UpdateAudienceTargettingModal />
      <ModalEditListProfile />
      <ModalProfile />
      <ModalCreateListGroup />
      <ModalNewTracker />
      <SocialTrackerDownloadCSVModal />
      <SelectCampaignModal />
      <ModalCreateCustomer />
      <ModalCustomerUser />
      <UnassignedThreadAssignModal />
      <UnassignedThreadViewModal />
      <ModalCustomerResetPassword />
      <ModalCustomerActivation />
      <BrandManagerPermissionsModal />
      <ModalCommsCreateEditTemplate />
      <ModalCreateMessage />
      <ModalCreateDeliverable />
      <GroupAccountsContactInfoModal />
      <ModalEditEmailsByTeam />
      <ModalCampaignDeliverableAssignReviewerGroup />
      <GroupAccountsFilterModal />
      <ModalCampaignAssociationWarning />
      <ModalAddUserToCampaign />
      <ModalAddToCommunications />
      <OAuthUserAccountModal />
      <UserOauthContinueModal />
      <ModalEditURL />
      <RoleModal />
      <FinalizeDeliverableConfirmationModal />
      <ModalSearchAISummary />
      <ModalCreateFeedback />
      <ModalRenameCategory />
      <ModalRemoveCategory />
      <ModalRemoveListAccount />
      <UpdateStatusModal />
    </>
  )
}
