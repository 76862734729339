import React from "react"
import { useTranslation } from "react-i18next"
import Pill from "../../../Pill"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  setAppendixMetrics,
  Metric,
  populateState,
  setUpdateListForm,
} from "../../../../state/listConfigurationSlice"
import ContentAdditionalFields from "../../ContentAdditionalFields"
import Divider from "../../../Divider"
import { appendixMetrics as metrics, contentTitleMetrics } from "../../constants"
import { isSuccess } from "../../../../util/apiClient"

import "./style.sass"

export default function AppendixDisplay() {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  const { appendixsMetrics, updateListForm } = useSelector((state) => state.listConfiguration)
  const list = useSelector((state) => state.list.list)

  React.useEffect(() => {
    if (isSuccess(list)) {
      dispatch(populateState())
    }
  }, [ list ])

  const accountInsightMetrics: Metric[] = React.useMemo(() => (
    appendixsMetrics
      .filter((metric) => metrics.find((group) => group.groupName === "Account Insights")?.metrics.includes(metric))
  ), [ appendixsMetrics ])

  const audienceMetrics: Metric[] = React.useMemo(() => (
    appendixsMetrics
      .filter((metric) => metrics.find((group) => group.groupName === "Audience Metrics")?.metrics.includes(metric))
  ), [ appendixsMetrics ])

  const contentMetrics: Metric[] = React.useMemo(() => (
    appendixsMetrics
      .filter((metric) => metrics.find((group) => group.groupName === "Content")?.metrics.includes(metric))
  ), [ appendixsMetrics ])

  /**
   * deleteAppendixsMetric: Removes the metrics from global state
   * @param metric The metric to be removed
   */
  const deleteAppendixsMetric = (metric: Metric) => {
    // Remove the metric from global state
    dispatch(setAppendixMetrics(appendixsMetrics.filter((stateMetric) => stateMetric.name !== metric.name)))

    // Check if update list form exists
    if (updateListForm) {
      // Remove the metric from the update list form
      const listForm = { ...updateListForm }
      listForm.toggles = (Array.isArray(listForm.toggles))
        ? listForm.toggles.filter((toggle) => toggle !== metric.code)
        : [ listForm.toggles ].filter((toggle) => toggle !== metric.code)
      dispatch(setUpdateListForm(listForm))
    }
  }

  return (
    <div className="cp_lcv-accordion-details_display">
      { (accountInsightMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Account Insights") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { accountInsightMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteAppendixsMetric(metric) } }
              />
            )) }
          </div>
        </div>
      ) }
      { (audienceMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Audience Metrics") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { audienceMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteAppendixsMetric(metric) } }
              />
            )) }
          </div>
        </div>
      ) }
      { (contentMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Content") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { contentMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteAppendixsMetric(metric) } }
              />
            )) }
          </div>
          { contentMetrics.some((item) => item.name === contentTitleMetrics.RELEVANT_POSTS) && (
            <>
              <Divider />
              <ContentAdditionalFields />
            </>
          ) }
        </div>
      ) }
    </div>
  )
}
