import { gql } from "@apollo/client"

export const DELIVERABLE_SUMMARY_FRAGMENT = gql` fragment DeliverableSummaryFragment on DeliverableSummary {
    id
    name
    caption
    deliverableStatus
    feedbackCount
    networkId
    ttcmCampaignName
    mediaUrl
    postTracker{
      id
      enabled
      status
    }
    socialAccount{
      id
      name
      username
      profilePictureUrl
      followerCount
      networkType
    }

}

`

export const CAMPAIGN_VERSION_FRAGMENT = gql` fragment CampaignVersionFragment on CampaignVersion{
    id
    isMigrated
}

`
