/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import { populateListForm } from "../../component/ListFormModal/displayOptionsHelper"
import { ListMode } from "../../component/ListFormModal"

export interface ListForm {
  affinities: string[];
  avatarId?: string | null;
  categories: string[];
  clientIds: string[];
  clientName?: string | null;
  contextualRelevancy?: string | null;
  description?: string | null;
  demographicScoreFormOpen: boolean;
  groupId?: string | null;
  keywords: string[];
  imageTags: string[];
  maxAge?: number | null;
  minAge?: number | null;
  name: string;
  network: GraphQL.Network | null;
  isPublic: boolean;
  sexes: GraphQL.Sex[];
  subscribedUserIds: string[];
  suggestionAccessCode?: GraphQL.SuggestionAccessCodeInput | null;
  suggestionListMode: GraphQL.SuggestionListMode;
  suggestionListVerticalIds: string[];
  toggles: string[];
  score: string,
  vetting: boolean;
  vettingSubscriptionUserIds: string[];
  demographicScoreMinAge?: number | null;
  demographicScoreMaxAge?: number | null;
  demographicScoreGender: GraphQL.Sex[];
  demographicScoreEthnicity: GraphQL.Ethnicity[];
  demographicScoreFamily: GraphQL.Family[];
  demographicScoreReligion: GraphQL.Religion[];
  demographicScoreIncome: GraphQL.IncomeBrackets[];
  demographicScoreLocations: string[];
  demographicScoreOccupations: string[];
  demographicScoreAffinities: string[];
  audienceGender: GraphQL.Sex[];
  audienceFamilyStatus: GraphQL.Family[];
  audienceAgeRange?: GraphQL.AgeRange;
  audienceIncomeRange?: GraphQL.IncomeBrackets;
  audienceEthnicity?: GraphQL.Ethnicity;
  audienceReligion?: GraphQL.Religion;
  audienceCountry: string[];
  audienceState: string[];
  audienceCity: string[];
  audienceOccupation: string[];
  audienceAffinity: string[];
}

const initialFormState: ListForm = {
  affinities: [],
  avatarId: null,
  categories: [],
  clientIds: [],
  clientName: null,
  contextualRelevancy: null,
  description: null,
  demographicScoreFormOpen: false,
  groupId: null,
  keywords: [],
  imageTags: [],
  maxAge: null,
  minAge: null,
  name: "",
  network: null,
  isPublic: true,
  sexes: [],
  subscribedUserIds: [],
  suggestionAccessCode: null,
  suggestionListMode: GraphQL.SuggestionListMode.Standard,
  suggestionListVerticalIds: [],
  toggles: [
    "ToggleEngagementScore",
    "ToggleInsightsEngagementRateOverTime",
    "ToggleInsightsEngagementRateDistribution",
    "ToggleInsightsEngagementsByPostType",
    "ToggleInsightsEngagementsByPostTypeReel",
    "ToggleInsightsEngagementsHeatMap",
    "ToggleInsightsRecentMediaAIImageAnalysis",
    "ToggleInsightsBrandsMentionedBrandsWorkedWith",
    "ToggleInsightsPITraits",
    "ToggleAudienceBaseline",
    "ToggleFollowersOverTime",
    "ToggleAudienceQuality",
    "ToggleGender",
    "ToggleFamilyStatus",
    "ToggleAgeRange",
    "ToggleIncomeRange",
    "ToggleEducationLevel",
    "ToggleEthnicities",
    "ToggleLanguages",
    "ToggleReligions",
    "ToggleCountries",
    "ToggleStates",
    "ToggleCities",
    "ToggleOccupations",
    "ToggleIndustries",
    "ToggleEmployers",
    "ToggleUniversities",
    "ToggleAffinities",
    "ToggleTopPosts",
    "ToggleKeywords",
    "TogglePostLocation",
  ],
  score: "ToggleEngagementScore",
  vetting: false,
  vettingSubscriptionUserIds: [],
  demographicScoreMinAge: null,
  demographicScoreMaxAge: null,
  demographicScoreGender: [],
  demographicScoreEthnicity: [],
  demographicScoreFamily: [],
  demographicScoreReligion: [],
  demographicScoreIncome: [],
  demographicScoreLocations: [],
  demographicScoreOccupations: [],
  demographicScoreAffinities: [],
  audienceGender: [],
  audienceFamilyStatus: [],
  audienceCountry: [],
  audienceState: [],
  audienceCity: [],
  audienceOccupation: [],
  audienceAffinity: [],
}

export const listModalForm = createSlice({
  name: "ListModalForm",
  initialState: initialFormState,
  reducers: {
    setAffinities: (state, action: PayloadAction<Array<string>>) => {
      state.affinities = action.payload
    },
    setAvatarId: (state, action: PayloadAction<string | null>) => {
      state.avatarId = action.payload
    },
    setCategories: (state, action: PayloadAction<Array<string>>) => {
      state.categories = action.payload
    },
    setClientIds: (state, action: PayloadAction<Array<string>>) => {
      state.clientIds = action.payload
    },
    setClientName: (state, action: PayloadAction<string | null>) => {
      state.clientName = action.payload
    },
    setContextualRelevancy: (state, action: PayloadAction<string | null>) => {
      state.contextualRelevancy = action.payload
    },
    setDescription: (state, action: PayloadAction<string | null>) => {
      state.description = action.payload
    },
    setDemographicFormScoreOpen: (state, action: PayloadAction<boolean>) => {
      state.demographicScoreFormOpen = action.payload
    },
    setGroupId: (state, action: PayloadAction<string | null>) => {
      state.groupId = action.payload
    },
    setKeywords: (state, action: PayloadAction<Array<string>>) => {
      state.keywords = action.payload
    },
    setImageTags: (state, action: PayloadAction<Array<string>>) => {
      state.imageTags = action.payload
    },
    setMaxAge: (state, action: PayloadAction<number | null>) => {
      state.maxAge = action.payload
    },
    setMinAge: (state, action: PayloadAction<number | null>) => {
      state.minAge = action.payload
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setNetwork: (state, action: PayloadAction<GraphQL.Network>) => {
      state.network = action.payload
    },
    setIsPublic: (state, action: PayloadAction<boolean>) => {
      state.isPublic = action.payload
    },
    setSexes: (state, action: PayloadAction<Array<GraphQL.Sex>>) => {
      state.sexes = action.payload
    },
    setSubscribedUserIds: (state, action: PayloadAction<Array<string>>) => {
      state.subscribedUserIds = action.payload
    },
    setSuggestionAccessCode: (state, action: PayloadAction<GraphQL.SuggestionAccessCodeInput | null>) => {
      state.suggestionAccessCode = action.payload
    },
    setSuggestionListMode: (state, action: PayloadAction<GraphQL.SuggestionListMode>) => {
      state.suggestionListMode = action.payload
    },
    setSuggestionListVerticalIds: (state, action: PayloadAction<Array<string>>) => {
      state.suggestionListVerticalIds = action.payload
    },
    setToggles: (state, action: PayloadAction<Array<string>>) => {
      state.toggles = action.payload
    },
    setScore: (state, action: PayloadAction<string>) => {
      state.score = action.payload
    },
    setVetting: (state, action: PayloadAction<boolean>) => {
      state.vetting = action.payload
    },
    setVettingSubscriptionUserIds: (state, action: PayloadAction<Array<string>>) => {
      state.vettingSubscriptionUserIds = action.payload
    },
    setDemographicScoreMinAge: (state, action: PayloadAction<number | null>) => {
      state.demographicScoreMinAge = action.payload
    },
    setDemographicScoreMaxAge: (state, action: PayloadAction<number | null>) => {
      state.demographicScoreMaxAge = action.payload
    },
    setDemographicScoreGender: (state, action: PayloadAction<Array<GraphQL.Sex>>) => {
      state.demographicScoreGender = action.payload
    },
    setDemographicScoreEthnicity: (state, action: PayloadAction<Array<GraphQL.Ethnicity>>) => {
      state.demographicScoreEthnicity = action.payload
    },
    setDemographicScoreFamily: (state, action: PayloadAction<Array<GraphQL.Family>>) => {
      state.demographicScoreFamily = action.payload
    },
    setDemographicScoreReligion: (state, action: PayloadAction<Array<GraphQL.Religion>>) => {
      state.demographicScoreReligion = action.payload
    },
    setDemographicScoreIncome: (state, action: PayloadAction<Array<GraphQL.IncomeBrackets>>) => {
      state.demographicScoreIncome = action.payload
    },
    setDemographicScoreLocations: (state, action: PayloadAction<Array<string>>) => {
      state.demographicScoreLocations = action.payload
    },
    setDemographicScoreOccupations: (state, action: PayloadAction<Array<string>>) => {
      state.demographicScoreOccupations = action.payload
    },
    setDemographicScoreAffinities: (state, action: PayloadAction<Array<string>>) => {
      state.demographicScoreAffinities = action.payload
    },
    setAudienceGender: (state, action: PayloadAction<Array<GraphQL.Sex>>) => {
      state.audienceGender = action.payload
    },
    setAudienceFamilyStatus: (state, action: PayloadAction<Array<GraphQL.Family>>) => {
      state.audienceFamilyStatus = action.payload
    },
    setAudienceAgeRange: (state, action: PayloadAction<GraphQL.AgeRange | undefined>) => {
      state.audienceAgeRange = action.payload
    },
    setAudienceIncomeRange: (state, action: PayloadAction<GraphQL.IncomeBrackets | undefined>) => {
      state.audienceIncomeRange = action.payload
    },
    setAudienceEthnicity: (state, action: PayloadAction<GraphQL.Ethnicity | undefined>) => {
      state.audienceEthnicity = action.payload
    },
    setAudienceReligion: (state, action: PayloadAction<GraphQL.Religion | undefined>) => {
      state.audienceReligion = action.payload
    },
    setAudienceCountry: (state, action: PayloadAction<string[]>) => {
      state.audienceCountry = action.payload
    },
    setAudienceState: (state, action: PayloadAction<string[]>) => {
      state.audienceState = action.payload
    },
    setAudienceCity: (state, action: PayloadAction<string[]>) => {
      state.audienceCity = action.payload
    },
    setAudienceOccupation: (state, action: PayloadAction<string[]>) => {
      state.audienceOccupation = action.payload
    },
    setAudienceAffinity: (state, action: PayloadAction<string[]>) => {
      state.audienceAffinity = action.payload
    },
    setCurrentList: (state, action: PayloadAction<ListForm>) => action.payload,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetCreateListForm: (state) => initialFormState,
    resetDemographics: (state) => ({
      ...state,
      demographicScoreMinAge: null,
      demographicScoreMaxAge: null,
      demographicScoreGender: [],
      demographicScoreEthnicity: [],
      demographicScoreFamily: [],
      demographicScoreReligion: [],
      demographicScoreIncome: [],
      demographicScoreLocations: [],
      demographicScoreOccupations: [],
      demographicScoreAffinities: [],
    }),
  },
})

export const {
  setAffinities,
  setAvatarId,
  setCategories,
  setClientIds,
  setClientName,
  setContextualRelevancy,
  setDescription,
  setDemographicFormScoreOpen,
  setGroupId,
  setKeywords,
  setImageTags,
  setMaxAge,
  setMinAge,
  setName,
  setNetwork,
  setIsPublic,
  setSexes,
  setSubscribedUserIds,
  setSuggestionAccessCode,
  setSuggestionListMode,
  setSuggestionListVerticalIds,
  setToggles,
  setScore,
  setVetting,
  setVettingSubscriptionUserIds,
  setDemographicScoreMinAge,
  setDemographicScoreMaxAge,
  setDemographicScoreGender,
  setDemographicScoreEthnicity,
  setDemographicScoreFamily,
  setDemographicScoreReligion,
  setDemographicScoreIncome,
  setDemographicScoreLocations,
  setDemographicScoreOccupations,
  setDemographicScoreAffinities,
  setAudienceGender,
  setAudienceFamilyStatus,
  setAudienceAgeRange,
  setAudienceIncomeRange,
  setAudienceEthnicity,
  setAudienceReligion,
  setAudienceCountry,
  setAudienceState,
  setAudienceCity,
  setAudienceOccupation,
  setAudienceAffinity,
  setCurrentList,
  resetCreateListForm,
  resetDemographics,
} = listModalForm.actions

export const listModalFormReducer = listModalForm.reducer

// Profile Slice Interface and Initial State
export interface ListFormStatusState {
  createListStatus: Status<GraphQL.CreateListMutation>
  updateListStatus: Status<GraphQL.UpdateSuggestionListMutation | null>
  originalList: Status<GraphQL.GetSuggestionListQuery>
  listModalOpen: boolean
  listModalMode: ListMode
}

const initialState: ListFormStatusState = {
  createListStatus: "init",
  updateListStatus: "init",
  originalList: "init",
  listModalOpen: false,
  listModalMode: { isEditMode: true, listId: "" },
}

export const ListFormManagerState = createSlice({
  name: "CreateList",
  initialState,
  reducers: {
    setCreateListStatus: (
      state,
      action: PayloadAction<Status<GraphQL.CreateListMutation>>,
    ) => ({
      ...state,
      createListStatus: action.payload,
    }),
    setUpdateListStatus: (
      state,
      action: PayloadAction<Status<GraphQL.UpdateSuggestionListMutation | null>>,
    ) => ({
      ...state,
      updateListStatus: action.payload,
    }),
    setOriginalList: (
      state,
      action: PayloadAction<Status<GraphQL.GetSuggestionListQuery>>,
    ) => ({
      ...state,
      originalList: action.payload,
    }),
    resetCreateListStatus: (
      state,
    ) => ({
      ...state,
      createListStatus: initialState.createListStatus,
    }),
    resetUpdateListStatus: (
      state,
    ) => ({
      ...state,
      updateListStatus: initialState.updateListStatus,
    }),
    resetOriginalList: (
      state,
    ) => ({
      ...state,
      originalList: initialState.originalList,
    }),
    setListModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      listModalOpen: action.payload,
    }),
    setListModalMode: (
      state,
      action: PayloadAction<ListMode>,
    ) => ({
      ...state,
      listModalMode: action.payload,
    }),
  },
})

export const {
  setCreateListStatus,
  setUpdateListStatus,
  resetCreateListStatus,
  resetUpdateListStatus,
  setOriginalList,
  resetOriginalList,
  setListModalMode,
  setListModalOpen,
} = ListFormManagerState.actions
export const listFormManagerReducer = ListFormManagerState.reducer

// Create List Thunks
export const manageListUpdate = (
  id: string | null,
  listForm: ListForm,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  if (id && listForm.network) { // update existing list
    dispatch(setUpdateListStatus("loading"))
    // eslint-disable-next-line object-curly-newline
    const updatedList: GraphQL.UpdateSuggestionListMutationVariables = { ...listForm, id, network: listForm.network }
    const updateListResult = await API.updateList(updatedList)
    dispatch(setUpdateListStatus(updateListResult))
  } else if (listForm.network) { // create new list
    dispatch(setCreateListStatus("loading"))
    const newList: GraphQL.CreateListMutationVariables = { ...listForm, network: listForm.network }
    const createListResult = await API.createList(newList)
    dispatch(setCreateListStatus(createListResult as Status<GraphQL.CreateListMutation>))
  }
}

export const getUpdateList = (
  id: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  const targetList = await API.getUpdateList(id)
  if (API.isSuccess(targetList)) {
    const formStaringState = populateListForm(
      targetList.payload.suggestionListById as GraphQL.SuggestionList,
      initialFormState,
    )
    dispatch(setCurrentList(formStaringState))
    dispatch(setOriginalList(targetList))
  }
}
