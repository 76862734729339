/* eslint-disable max-len */
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import "./style.sass"
import { Button } from "@mui/material"
import { useApolloClient } from "@apollo/client"
import Modal from "../Modal"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector, useDispatch } from "../../state/hooks"
import { isSuccess } from "../../util/apiClient"
import { pushToast } from "../../state/toastSlice"
import { closeTikTokInviteModal, generateLink } from "../../state/tikTokInviteLinkModalSlice"
import { refreshCampaign } from "../../state/campaignSlice"
import { DeliverableQueryOutput, GET_DELIVERABLE } from "../../state/campaignDeliverableContentModalV2Slice/types"
import { DeliverableQueryVariables } from "../../graphql"

function ModalTikTokInviteLink(): React.JSX.Element {
  const [ loadingPrimaryAction, setLoadingPrimaryAction ] = React.useState(false)
  const client = useApolloClient()

  const {
    tikTokInviteLinkOpen,
    deliverableVersion,
    onClose,
    deliverableId,
  } = useSelector(({ tikTokInviteModalSlice }) => tikTokInviteModalSlice)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalTikTokInviteLink" })

  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(closeTikTokInviteModal())
    if (onClose) {
      onClose()
    }
  }

  const generateLinkHandler = async () => {
    setLoadingPrimaryAction(true)
    const response = await dispatch(generateLink())
    setLoadingPrimaryAction(false)
    if (response && isSuccess(response)) {
      const newLink = response?.payload?.createTcmOrder.tiktokTcmCampaign.inviteUrl?.address || ""
      dispatch(pushToast({
        message: translate("Invite Created"),
        additionalInfo: (
          <Button
            variant="text"
            onClick={ () => navigator.clipboard.writeText(newLink) }
          >
            { translate("Copy Link") }
          </Button>
        ),
        type: "success",
      }))
      closeModal()
      if (deliverableVersion === "v1") {
        dispatch(refreshCampaign())
      } else {
        if (!deliverableId) return
        const deliverableData = client.readQuery<DeliverableQueryOutput, DeliverableQueryVariables>({
          query: GET_DELIVERABLE,
          variables: {
            deliverableId,
          },
        })
        if (deliverableData) {
          client.writeQuery<DeliverableQueryOutput, DeliverableQueryVariables>({
            variables: { deliverableId },
            query: GET_DELIVERABLE,
            data: {
              deliverable: {
                ...deliverableData.deliverable,
                ttcmLinkUrl: newLink,

              },
            },
          })
        }
      }
    } else {
      dispatch(pushToast({
        message: translate("Unable to create Invite Link"),
        type: "error",
      }))
    }
  }

  const primaryButtonLabel = useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    return translate("Continue")
  }, [ loadingPrimaryAction ])

  return (
    <Modal
      title={ translate("Create TTCM Invite Link") }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ tikTokInviteLinkOpen }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ generateLinkHandler }
      maxWidth="md"
      className="cp_component_modal-ttcm-link"
      disabled={ loadingPrimaryAction }
    >
      <div
        className="cp_component_modal-ttcm-link-container"
      >
        <div
          className="cp_component_modal-ttcm-link-container-body"
        >
          <p>
            { translate("Are you sure you want to create an invite link? Share the invite link with the creator to upload content.") }
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default ModalTikTokInviteLink
