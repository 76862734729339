import React from "react"
import "./style.sass"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material"
import { KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import * as GraphQL from "../../../../graphql"
import * as misc from "../../../../util/miscHelper"
import Checkbox from "../../../Checkbox"
import Select from "../../../Select"
import Button from "../../../Button"
import {
  Affinity,
  DemographicScoreForm,
  DemoScoreFormValueCompare,
  engagementToggles,
  initialDemographicScoreForm,
  Occupation,
  ReadableEthnicity,
  ReadableFamily,
  ReadableIncome,
  ReadableReligion,
} from "../../constants"
import MenuItem from "../../../MenuItem"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { setUpdateListForm, updateList } from "../../../../state/listConfigurationSlice"
import { isSuccess } from "../../../../util/apiClient"
import Pill from "../../../Pill"
import { pushToast } from "../../../../state/toastSlice"
import LocationAutocomplete from "./LocationAutocomplete"
import OccupationsAutocomplete from "./OccupationAutocomplete"
import AffinitiesAutocomplete from "./AffinityAutocomplete"
import { LIST_DISPLAY_TOGGLES } from "../../../../util/constant"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import { fetchList } from "../../../../state/listSlice"

export default function DemocraphicScoreForm() {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()
  const { listID } = useParams()

  const { updateListForm } = useSelector((state) => state.listConfiguration)
  const list = useSelector((state) => state.list.list)

  const [ expanded, setExpanded ] = React.useState(true)
  const [ saved, setSaved ] = React.useState<boolean>(false)
  const [ demographicScoreForm, setDemographicScoreForm ] = React.useState<DemographicScoreForm>(initialDemographicScoreForm)

  React.useEffect(() => {
    if (isSuccess(list)) {
      // Get list
      const suggestionList = list.payload.suggestionListById

      // Create/initialize demographic score form
      const demoScoreForm: DemographicScoreForm = {
        sexes: suggestionList.demographicScoreGender,
        age: {
          from: suggestionList.demographicScoreMinAge || 0,
          to: suggestionList.demographicScoreMaxAge || 0,
        },
        ethnicity: suggestionList.demographicScoreEthnicity,
        family: suggestionList.demographicScoreFamily,
        religion: suggestionList.demographicScoreReligion,
        income: suggestionList.demographicScoreIncome,
        locations: suggestionList.demographicScoreLocations.map((loc) => ({
          name: loc.name,
          code: loc.code,
          type: loc.type,
        })),
        occupations: suggestionList.demographicScoreOccupations.map((occ) => ({
          id: occ.id,
          code: occ.code,
          name: occ.name,
        })),
        affinities: suggestionList.demographicScoreAffinities.map((aff) => ({
          id: aff.id,
          code: aff.code,
          name: aff.name,
        })),
      }

      // Set local state
      setDemographicScoreForm(demoScoreForm)

      // Check to see if it has values
      const hasValues = Object.values(demoScoreForm).some((value: DemoScoreFormValueCompare) => {
        if (Array.isArray(value)) return value.length > 0
        return value.from > 0 || value.to > 0
      })

      // Set local state to indicate it's been saved or not (red vs normal color font)
      setSaved(hasValues)
      setExpanded(!hasValues)
    }
  }, [ list ])

  const updateSelectGender = (gender: GraphQL.Sex) => {
    // Make a copy of the object
    const demoScoreForm = misc.copyObject(demographicScoreForm)

    // Check to see if selecting or deselecting
    if (demoScoreForm.sexes.includes(gender)) {
      // Remove the gender from the list
      demoScoreForm.sexes.splice(demoScoreForm.sexes.indexOf(gender), 1)
    } else demoScoreForm.sexes = [ ...demoScoreForm.sexes, gender ]

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreGender = demoScoreForm.sexes
      dispatch(setUpdateListForm(updatedForm))
    }

    // Update the form
    setDemographicScoreForm(demoScoreForm)
  }

  const createAgeRangeOptions = (): React.JSX.Element[] => {
    // Set default first item
    const menuItems: React.JSX.Element[] = [
      (<MenuItem value={ 0 }>{ translate("Select") }</MenuItem>),
    ]

    // Create array to generate the rest of the range
    menuItems.push(...Array.from({ length: 65 }, (_, i) => (
      <MenuItem value={ (i + 1) }>{ (i + 1) }</MenuItem>
    )))

    // Return the menu items
    return menuItems
  }

  const updateAgeFromValue = (e: SelectChangeEvent<number>) => {
    const demoScoreForm = misc.copyObject(demographicScoreForm)
    demoScoreForm.age.from = (typeof e.target.value === "string") ? parseInt(e.target.value, 10) : e.target.value
    setDemographicScoreForm(demoScoreForm)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreMinAge = demoScoreForm.age.from
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const updateAgeToValue = (e: SelectChangeEvent<number>) => {
    const demoScoreForm = misc.copyObject(demographicScoreForm)
    demoScoreForm.age.to = (typeof e.target.value === "string") ? parseInt(e.target.value, 10) : e.target.value
    setDemographicScoreForm(demoScoreForm)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreMaxAge = demoScoreForm.age.to
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const createEthnicityOptions = (): React.JSX.Element[] => {
    // Set default first item
    const menuItems: React.JSX.Element[] = []

    menuItems.push(...Object.values(GraphQL.Ethnicity).map((value: GraphQL.Ethnicity) => (
      <MenuItem key={ value } value={ value }>
        <Checkbox checked={ demographicScoreForm.ethnicity.includes(value) } />
        <ListItemText primary={ translate(ReadableEthnicity[value]) } />
      </MenuItem>
    )))

    return menuItems
  }

  const selectEthnicity = (e: SelectChangeEvent<GraphQL.Ethnicity[]>) => {
    // Copy the state value
    const demoScoreForm = misc.copyObject(demographicScoreForm)

    // Convert string representation to actual ethnicity
    if (typeof e.target.value === "string") return

    // Set the values
    demoScoreForm.ethnicity = e.target.value

    // Set the new form
    setDemographicScoreForm(demoScoreForm)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreEthnicity = demoScoreForm.ethnicity
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const createFamilyOptions = (): React.JSX.Element[] => {
    // Set default first item
    const menuItems: React.JSX.Element[] = []

    Object.values(GraphQL.Family).forEach((family) => {
      menuItems.push((
        <MenuItem key={ family } value={ family }>
          <Checkbox checked={ demographicScoreForm.family.includes(family) } />
          <ListItemText primary={ translate(ReadableFamily[family]) } />
        </MenuItem>
      ))
    })

    return menuItems
  }

  const selectFamily = (e: SelectChangeEvent<GraphQL.Family[]>) => {
    // Copy the state value
    const demoScoreForm = misc.copyObject(demographicScoreForm)

    // Convert string representation to actual ethnicity
    if (typeof e.target.value === "string") return

    // Set the values
    demoScoreForm.family = e.target.value

    // Set the new form
    setDemographicScoreForm(demoScoreForm)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreFamily = demoScoreForm.family
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const createReligionOptions = (): React.JSX.Element[] => {
    // Set default first item
    const menuItems: React.JSX.Element[] = []

    menuItems.push(...Object.values(GraphQL.Religion).map((religion: GraphQL.Religion) => (
      <MenuItem key={ religion } value={ religion }>
        <Checkbox checked={ demographicScoreForm.religion.includes(religion) } />
        <ListItemText primary={ translate(ReadableReligion[religion]) } />
      </MenuItem>
    )))

    return menuItems
  }

  const selectReligion = (e: SelectChangeEvent<GraphQL.Religion[]>) => {
    // Copy the state value
    const demoScoreForm = misc.copyObject(demographicScoreForm)

    // Convert string representation to actual ethnicity
    if (typeof e.target.value === "string") return

    // Set the values
    demoScoreForm.religion = e.target.value

    // Set the new form
    setDemographicScoreForm(demoScoreForm)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreReligion = demoScoreForm.religion
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const createIncomeOptions = (): React.JSX.Element[] => {
    // Set default first item
    const menuItems: React.JSX.Element[] = []

    menuItems.push(...Object.values(GraphQL.IncomeBrackets).map((income: GraphQL.IncomeBrackets) => (
      <MenuItem key={ income } value={ income }>
        <Checkbox checked={ demographicScoreForm.income.includes(income) } />
        <ListItemText primary={ translate(ReadableIncome[income]) } />
      </MenuItem>
    )))

    return menuItems
  }

  const selectIncome = (e: SelectChangeEvent<GraphQL.IncomeBrackets[]>) => {
    // Copy the state value
    const demoScoreForm = misc.copyObject(demographicScoreForm)

    // Convert string representation to actual ethnicity
    if (typeof e.target.value === "string") return

    // Set the values
    demoScoreForm.income = e.target.value

    // Set the new form
    setDemographicScoreForm(demoScoreForm)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreIncome = demoScoreForm.income
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const setLocations = (locations: GraphQL.AudienceLocation[]) => {
    // Make copy of form data
    const newformdata = misc.copyObject(demographicScoreForm)

    // Add location
    newformdata.locations = locations

    // Save to state
    setDemographicScoreForm(newformdata)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreLocations = locations.map((loc) => loc.code)
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const setOccupations = (occs: Occupation[]) => {
    // Make copy of form data
    const newformdata = misc.copyObject(demographicScoreForm)

    // Add occupation
    newformdata.occupations = occs

    // Save to state
    setDemographicScoreForm(newformdata)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreOccupations = occs.map((occ) => occ.code)
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const setAffinities = (affs: Affinity[]) => {
    // Make copy of form data
    const newformdata = misc.copyObject(demographicScoreForm)

    // Add affinity
    newformdata.affinities = affs

    // Save to state
    setDemographicScoreForm(newformdata)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreAffinities = affs.map((aff) => aff.code)
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const deleteLocation = (location: GraphQL.AudienceLocation) => {
    // Make copy of form data
    const newformdata = misc.copyObject(demographicScoreForm)

    // Remove the location
    newformdata.locations = newformdata.locations.filter((loc:GraphQL.AudienceLocation) => loc.code !== location.code)

    // Save to state
    setDemographicScoreForm(newformdata)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreLocations = newformdata.locations.map((loc: GraphQL.AudienceLocation) => loc.code)
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const deleteOccupation = (occupation: Occupation) => {
    // Make copy of form data
    const newformdata = misc.copyObject(demographicScoreForm)

    // Remove the occupation
    newformdata.occupations = newformdata.occupations.filter((occ: Occupation) => occ.id !== occupation.id)

    // Save to state
    setDemographicScoreForm(newformdata)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreOccupations = newformdata.occupations.map((occ: Occupation) => occ.code)
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const deleteAffinity = (affinity: Affinity) => {
    // Make copy of form data
    const newformdata = misc.copyObject(demographicScoreForm)

    // Remove the affinity
    newformdata.affinities = newformdata.affinities.filter((aff: Affinity) => aff.id !== affinity.id)

    // Save to state
    setDemographicScoreForm(newformdata)

    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)
      updatedForm.demographicScoreAffinities = newformdata.affinities.map((aff: Affinity) => aff.code)
      dispatch(setUpdateListForm(updatedForm))
    }
  }

  const handleDemographicScoreFormPersistence = () => {
    // Update global state form
    if (updateListForm) {
      const updatedForm = misc.copyObject(updateListForm)

      // Handle if single toggle
      if (typeof updatedForm.toggles === "string") {
        if (engagementToggles.includes(updatedForm.toggles)) updatedForm.toggles = []
        else updatedForm.toggles = [ updatedForm.toggles ]
      }

      // Handle multiple toggles
      engagementToggles.forEach((toggle) => {
        if (!Array.isArray(updatedForm.toggles)) return
        if (updatedForm.toggles.includes(toggle)) {
          const index = updatedForm.toggles.indexOf(toggle)
          updatedForm.toggles.splice(index, 1)
        }
      })

      updatedForm.toggles.push(LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE)
      dispatch(setUpdateListForm(updatedForm))
    }

    // Make the call
    dispatch(updateList({
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Successfully save demographic score form!"),
        }))
        setSaved(true)
        setExpanded(false)
        dispatch(fetchList(listID || ""))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: "Unable to save demographic score form. Please try again later!",
        }))
      },
    }))
  }

  if (list === "init" || list === "loading") return <LoadingIndicatorCard />

  return (
    <Accordion
      onChange={ () => setExpanded(!expanded) }
      className="highlights-demo-score-form"
      elevation={ 0 }
      id="highlights-demo-score-form"
      defaultExpanded={ true }
      expanded={ expanded }
    >
      <AccordionSummary
        className="highlights-demo-score-form-summary"
        expandIcon={ expanded
          ? <KeyboardArrowUp className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
          : <KeyboardArrowRight className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
        }
        aria-controls="panel1a-content"
        id="demo-score-form-header"
      >
        <div className="highlights-demo-score-form-summary-header-container">
          <p className={ `main-heading${ (saved) ? " dsf-saved" : " dsf-not-saved" }` }>
            { translate("Demographic Score*") }
          </p>
          <p className="form-note">
            { translate("demographic reminder") }
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails className="highlights-demo-score-form-details">
        <div className="demographic-form">
          <div className="demographic-form-gender">
            <FormControl component="fieldset" fullWidth={ true }>
              <FormLabel component="legend" className="section-label">
                { translate("Gender") }
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  className="checkbox-label"
                  control={ (
                    <Checkbox
                      id="gender-female-checkbox"
                      className="gender-checkbox"
                      name={ translate("Female") }
                      size="small"
                      checked={ demographicScoreForm.sexes.includes(GraphQL.Sex.Female) }
                      onClick={ () => updateSelectGender(GraphQL.Sex.Female) }
                    />
                  ) }
                  label={ translate("Female") }
                />
                <FormControlLabel
                  className="checkbox-label"
                  control={ (
                    <Checkbox
                      id="gender-male-checkbox"
                      className="gender-checkbox"
                      name={ translate("Male") }
                      size="small"
                      checked={ demographicScoreForm.sexes.includes(GraphQL.Sex.Male) }
                      onClick={ () => updateSelectGender(GraphQL.Sex.Male) }
                    />
                  ) }
                  label={ translate("Male") }
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className="demographic-form-age-range">
            <FormControl
              className="demographic-form-age-range-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Age Range") }
              </FormLabel>
              <div className="demographic-form-age-range-form-control-from">
                <FormLabel component="legend" className="field-label">
                  { translate("From") }
                </FormLabel>
                <Select
                  id="age-range-from-select"
                  name="minAge"
                  className="select-from-field"
                  label=""
                  labelId="minAge"
                  value={ demographicScoreForm.age.from }
                  onChange={ (e) => updateAgeFromValue(e) }
                  menuItems={ createAgeRangeOptions() }
                  fullWidth={ true }
                />
              </div>
              <div className="demographic-form-age-range-form-control-to">
                <FormLabel component="legend" className="field-label">
                  { translate("To") }
                </FormLabel>
                <Select
                  id="age-range-to-select"
                  name="maxAge"
                  className="select-to-field"
                  label=""
                  labelId="maxAge"
                  value={ demographicScoreForm.age.to }
                  onChange={ (e) => updateAgeToValue(e) }
                  menuItems={ createAgeRangeOptions() }
                  fullWidth={ true }
                />
              </div>
            </FormControl>
          </div>
          <div className="demographic-form-ethnicity">
            <FormControl
              className="demographic-form-ethnicity-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Ethnicity") }
              </FormLabel>
              <Select
                id="ethnicity-select"
                name="ethnicity"
                className="select-ethnicity"
                label=""
                labelId="ethnicity"
                value={ demographicScoreForm.ethnicity }
                menuItems={ createEthnicityOptions() }
                fullWidth={ true }
                multiple={ true }
                onChange={ (e) => selectEthnicity(e) }
                renderValue={
                  (selected) => selected.map((ethnicity: GraphQL.Ethnicity) => ReadableEthnicity[ethnicity]).join(", ")
                }
              />
            </FormControl>
          </div>
          <div className="demographic-form-family">
            <FormControl
              className="demographic-form-family-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Family") }
              </FormLabel>
              <Select
                id="family-select"
                name="family"
                className="select-family"
                label=""
                labelId="family"
                value={ demographicScoreForm.family }
                menuItems={ createFamilyOptions() }
                fullWidth={ true }
                multiple={ true }
                onChange={ (e) => selectFamily(e) }
                renderValue={ (selected: GraphQL.Family[]) => selected
                  .map((family: GraphQL.Family) => ReadableFamily[family])
                  .join(", ") }
              />
            </FormControl>
          </div>
          <div className="demographic-form-religion">
            <FormControl
              className="demographic-form-religion-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Religion") }
              </FormLabel>
              <Select
                id="religion-select"
                name="religion"
                className="select-religion"
                label=""
                labelId="religion"
                value={ demographicScoreForm.religion }
                menuItems={ createReligionOptions() }
                fullWidth={ true }
                multiple={ true }
                onChange={ (e) => selectReligion(e) }
                renderValue={ (selected: GraphQL.Religion[]) => selected
                  .map((religion: GraphQL.Religion) => ReadableReligion[religion])
                  .join(", ") }
              />
            </FormControl>
          </div>
          <div className="demographic-form-income">
            <FormControl
              className="demographic-form-income-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Income") }
              </FormLabel>
              <Select
                id="income-select"
                name="income"
                className="select-income"
                label=""
                labelId="income"
                value={ demographicScoreForm.income }
                menuItems={ createIncomeOptions() }
                fullWidth={ true }
                multiple={ true }
                onChange={ (e) => selectIncome(e) }
                renderValue={
                  (selected: GraphQL.IncomeBrackets[]) => selected.map(
                    (income: GraphQL.IncomeBrackets) => ReadableIncome[income],
                  ).join("; ")
                }
              />
            </FormControl>
          </div>
          <div className="demographic-form-location">
            <FormControl
              className="demographic-form-location-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Location") }
              </FormLabel>
              <LocationAutocomplete
                setSelectedLocations={ setLocations }
                selectedLocations={ demographicScoreForm.locations }
                placeholder={ translate("Select locations") }
                notFoundLabel={ translate("No location found") }
              />
              <div className="autocomplete-selection-pills">
                { (demographicScoreForm.locations.length > 0)
                && demographicScoreForm.locations.map((location: GraphQL.AudienceLocation) => (
                  <Pill
                    key={ location.code }
                    label={ location.name }
                    onDelete={ () => deleteLocation(location) }
                  />
                )) }
              </div>
            </FormControl>
          </div>
          <div className="demographic-form-occupations">
            <FormControl
              className="demographic-form-occupations-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Occupations") }
              </FormLabel>
              <OccupationsAutocomplete
                setSelectedOccupations={ setOccupations }
                selectedOccupations={ demographicScoreForm.occupations }
                placeholder={ translate("Select occupations") }
                notFoundLabel={ translate("") }
              />
              <div className="autocomplete-selection-pills">
                { (demographicScoreForm.occupations.length > 0)
                && demographicScoreForm.occupations.map((occupation: Occupation) => (
                  <Pill
                    key={ occupation.id }
                    label={ occupation.name }
                    onDelete={ () => deleteOccupation(occupation) }
                  />
                )) }
              </div>
            </FormControl>
          </div>
          <div className="demographic-form-affinities">
            <FormControl
              className="demographic-form-affinities-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Affinities") }
              </FormLabel>
              <AffinitiesAutocomplete
                setSelectedAffinities={ setAffinities }
                selectedAffinities={ demographicScoreForm.affinities }
                placeholder={ translate("Select affinities") }
                notFoundLabel={ translate("No affinity found") }
              />
              <div className="autocomplete-selection-pills">
                { (demographicScoreForm.affinities.length > 0) && demographicScoreForm.affinities.map((affinity: Affinity) => (
                  <Pill
                    key={ affinity.id }
                    label={ affinity.name }
                    onDelete={ () => deleteAffinity(affinity) }
                  />
                )) }
              </div>
            </FormControl>
          </div>
          <div className="demographic-form-cta">
            <Button
              id="demographic-score-form-save-button"
              label={ translate("Save") }
              isPrimary={ true }
              fullWidth={ true }
              onClick={ handleDemographicScoreFormPersistence }
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
