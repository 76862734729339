import * as React from "react"
import { useTranslation } from "react-i18next"
import { FlagCircle } from "@mui/icons-material"

import Avatar from "../Avatar"
import LoadingIndicator from "../LoadingIndicator"
import NetworkIcon from "../NetworkIcon"
import { SuggestionListMode } from "../../graphql"
import { shorthandNumber } from "../../util/miscHelper"
import { useSelector } from "../../state/hooks"

import "./metrics.sass"
import "./modals.sass"
import "./overview.sass"
import ListManagementControls from "./ListManagementControls"

export default function ListOverview({ displayConfigureTab }: { displayConfigureTab: boolean }) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListOverview" })

  const {
    list: fetchedList,
  } = useSelector(({ list }) => list)

  const numberOfApprovedAccounts = React.useMemo(() => {
    let total = 0
    if (fetchedList === "init" || fetchedList === "loading" || fetchedList.status === "error") return total
    const {
      suggestionListCategories,
    } = fetchedList.payload.suggestionListById

    suggestionListCategories.forEach(({ suggestionListSocialAccounts }) => {
      suggestionListSocialAccounts.forEach(({ approvalStatus }) => {
        if (approvalStatus?.value === true) total += 1
      })
    })
    return total
  }, [ fetchedList ])

  const demographicTarget = React.useMemo(() => {
    if (fetchedList === "init" || fetchedList === "loading" || fetchedList.status === "error") return ""
    const {
      sexes,
      minAge,
      maxAge,
    } = fetchedList.payload.suggestionListById

    const demographics = []
    const ages = []

    if (minAge) ages.push(minAge)
    if (maxAge) ages.push(maxAge)

    const ageDemographic = ages.join(" - ")
    const sexDemographic = sexes.join(", ")

    if (ageDemographic) demographics.push(ageDemographic)
    if (sexDemographic) demographics.push(sexDemographic)

    return demographics.join(" | ")
  }, [ fetchedList ])

  if (fetchedList === "init" || fetchedList === "loading") {
    return (
      <div className="cp_list-overview_component-loading">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  }
  if (fetchedList.status === "error") {
    return (
      <div className="cp_list-overview_component-error">
        <p>
          { translate("Unable to fetch list.") }
        </p>
      </div>
    )
  }

  const {
    network,
    avatar,
    name,
    suggestionListGroup,
    suggestionListMode,
    numberOfLinkedSocialAccounts,
    reach,
  } = fetchedList.payload.suggestionListById

  return (
    <div className="cp_list-overview_component">
      <div className="cp_list-overview_component-info">
        <div className="cp_list-overview_component-info-flex">
          <div className="cp_list-overview_component-info-avatar-container">
            <Avatar size="lg" src={ avatar?.url.address } className="cp_list-overview_component-info-avatar">
              { name[0] }
            </Avatar>
          </div>
          <div className="cp_list-overview_component-info-name-container">
            { suggestionListGroup && (
              <p className="cp_list-overview_component-info-group-name">
                { suggestionListGroup.name }
              </p>
            ) }
            <p className="cp_list-overview_component-info-name">
              { name }
            </p>
            { suggestionListMode === SuggestionListMode.Campaign && (
              <div className="cp_list-overview_component-info-name-container-icon-container">
                <FlagCircle className="cp_list-overview_component-info-name-container-icon-container-icon" />
              </div>
            ) }
          </div>
        </div>
      </div>
      <div className="cp_list-overview_component-right-column">
        <div className="cp_list-overview_component-metrics-wrapper">
          <div className="cp_list-overview_component-metrics">
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-value">
                <NetworkIcon
                  network={ network }
                  isActive={ true }
                />
              </p>
              <p className="label_large-caps-bold">
                { network }
              </p>
            </div>
            { demographicTarget && (
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-value">
                { demographicTarget }
              </p>
              <p className="label_large-caps-bold">
                { translate("DEMOGRAPHIC") }
              </p>
            </div>
            ) }
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-value">
                { numberOfLinkedSocialAccounts }
              </p>
              <p className="label_large-caps-bold">
                { translate("ACCOUNTS") }
              </p>
            </div>
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-value">
                { shorthandNumber(parseInt(reach, 10)) }
              </p>
              <p className="label_large-caps-bold">
                { translate("REACH") }
              </p>
            </div>
            { suggestionListMode === SuggestionListMode.Campaign && (
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-value">
                { shorthandNumber(numberOfApprovedAccounts) }
              </p>
              <p className="label_large-caps-bold">
                { translate("APPROVED") }
              </p>
            </div>
            ) }
          </div>
        </div>
        { !displayConfigureTab && <ListManagementControls /> }
      </div>
    </div>
  )
}
