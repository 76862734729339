import React, { useMemo } from "react"
import { IconButton } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import CampaignIcon from "@mui/icons-material/Campaign"
import { useTranslation } from "react-i18next"

import Badge from "../Badge"
import Tooltip from "../Tooltip"
import {
  setEdited,
  setCaptionToApprove,
  setCaptionFeedback,
  setSelectedMediaForFeedback,
} from "../../state/campaignDeliverableContentModalV2Slice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./caption.sass"
import { DeliverableCaptionContainer, ReviewerGroupStatus } from "../../state/campaignDeliverableContentModalV2Slice/types"

interface DeliverableCaptionProps {
  caption: DeliverableCaptionContainer | undefined | null
}

// TODO: Implement approval and control in future ticket
function DeliverableCaption({ caption }: DeliverableCaptionProps): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    captionFeedback,
    captionToApprove,
    deliverablePermissions,
    deliverable,
  } = useSelector(({
    campaignDeliverableContentModalV2,
  }) => campaignDeliverableContentModalV2)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const canApprove = useMemo(() => {
    if (!deliverablePermissions) return false
    return deliverablePermissions.canVoteOnDeliverable || deliverablePermissions.canFinalizeDeliverableFeedback
  }, [ deliverablePermissions ])

  const approveDisabled = useMemo(() => {
    if (!deliverablePermissions) return true
    if (!deliverable) return true
    if (!caption) return true
    if (deliverable.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE) return true
    return caption.approved
  }, [ deliverablePermissions, deliverable ])

  const feedbackDisabled = useMemo(() => {
    if (!deliverablePermissions) return true
    if (!deliverable) return true
    if (deliverable.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE) return true
    return !deliverablePermissions.canUpdateDeliverable
  }, [ deliverablePermissions, deliverable ])

  if (!caption || !caption.caption) return <> </>
  return (
    <div id="cp_campaign-deliverable-caption-v2_component" className="cp_campaign-deliverable-caption-v2_component">
      <p
        className="body_large"
      >
        { translate("Caption") }
        { /* <CopyIconButton textToCopy={ caption.caption } /> */ }
      </p>
      <p
        className="cp_campaign-deliverable-caption-v2_component-text"
        // className={ `cp_campaign-deliverable-caption-v2_component-text ${ captionToApprove ? "compact" : "" }` }
      >
        { caption.caption }
      </p>
      <div className="cp_campaign-deliverable-caption-v2_component-controls">
        { canApprove && (
          <IconButton
            className={ `btn-approve-caption ${ caption.approved ? "approved" : "" } ${ captionToApprove ? "active" : "" }` }
            disabled={ approveDisabled }
            onClick={ () => {
              dispatch(setCaptionToApprove({ caption }))
              dispatch(setEdited(true))
              dispatch(setCaptionFeedback({ caption: undefined }))
            } }
          >
            <CheckIcon />
          </IconButton>
        ) }
        <Tooltip title={ translate("Leave Feedback") }>
          <div>
            <Badge badgeContent={ caption.feedbackCount }>
              <IconButton
                id="cp_campaign-deliverable-caption_feedback-button"
                className={ `btn-feedback ${ captionFeedback ? "active" : "" }` }
                disabled={ feedbackDisabled }
                onClick={ () => {
                  dispatch(setCaptionFeedback({ caption }))
                  dispatch(setCaptionToApprove({ caption: undefined }))
                  dispatch(setSelectedMediaForFeedback({ media: undefined }))
                } }
              >
                <CampaignIcon />
              </IconButton>
            </Badge>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default DeliverableCaption
