import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

import { useMutation, useQuery } from "@apollo/client"
import Button from "../Button"
import DeliverableFeedbackCard from "./FeedbackCard"
import LoadingIndicator from "../LoadingIndicator"
import { useDispatch, useSelector } from "../../state/hooks"
import { setCaptionFeedback } from "../../state/campaignDeliverableContentModalV2Slice"

import "./media-feedback.sass"
import RichTextEditor from "../RichTextEditor"
import {
  ADD_DELIVERABLE_ITEM_COMMENT,
  AddDeliverableItemCommentInput,
  AddDeliverableItemCommentOutput,
  DeliverableCaptionContainer,
  DeliverableCaptionItemsOutput,
  DeliverableItemFeedbackItem,
  DeliverableItemFeedbackItemsOutput,
  DeliverableItemFeedbackItemsVariables,
  GET_DELIVERABLE_CAPTION_ITEMS,
  GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
} from "../../state/campaignDeliverableContentModalV2Slice/types"

interface CaptionFeedbackProps {
  caption: DeliverableCaptionContainer
}

function CaptionFeedback({ caption }: CaptionFeedbackProps): React.JSX.Element {
  const [ newMessage, setNewMessage ] = useState("")
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const { deliverable } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const dispatch = useDispatch()

  const closeCard = useCallback(() => dispatch(setCaptionFeedback({ caption: undefined })), [])

  const [ feedbackItems, setFeedbackItems ] = useState<null | DeliverableItemFeedbackItem[]>(null)

  const {
    data, loading: queryLoading, refetch,
  } = useQuery<DeliverableItemFeedbackItemsOutput, DeliverableItemFeedbackItemsVariables>(
    GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
    {
      variables: { deliverableItemId: caption.id },
      context: {
        apiVersion: "v2",
      },
      skip: !caption.id,
    },
  )

  useEffect(() => {
    if (data?.deliverableItemFeedbackItems) {
      setFeedbackItems(data.deliverableItemFeedbackItems)
    }
  }, [ data ])

  const [ addDeliverableItemComment, { loading: mutationLoading } ] = useMutation<
      AddDeliverableItemCommentOutput,
      AddDeliverableItemCommentInput
    >(ADD_DELIVERABLE_ITEM_COMMENT)

  const createFeedback = useCallback(async () => {
    closeCard()
    if (!deliverable) return
    addDeliverableItemComment({
      variables: {
        content: newMessage,
        deliverableItemId: caption.id,
      },
      context: {
        apiVersion: "v2",
      },
      onCompleted: () => refetch(),
      update: (cache) => {
        const deliverableItemsData = cache.readQuery<DeliverableCaptionItemsOutput>({
          query: GET_DELIVERABLE_CAPTION_ITEMS,
          variables: {
            deliverableId: deliverable.id,
          },
        })
        if (deliverableItemsData) {
          cache.writeQuery<DeliverableCaptionItemsOutput>({
            query: GET_DELIVERABLE_CAPTION_ITEMS,
            variables: deliverable.id,
            data: {
              deliverableCaptionItems: {
                ...deliverableItemsData.deliverableCaptionItems,
                feedbackCount: deliverableItemsData.deliverableCaptionItems.feedbackCount + 1,
              },
            },
          })
        }
      },
    })
  }, [ newMessage, deliverable ])

  if (queryLoading || !feedbackItems) return <LoadingIndicator />

  return (
    <div className="cp_media-feedback_component">
      { feedbackItems.map((feedback) => <DeliverableFeedbackCard feedback={ feedback } />) }
      <div className="cp_media-feedback_component-new-message">
        <RichTextEditor
          hasMergeTags={ false }
          editorContent={ newMessage }
          setEditorContent={ setNewMessage }
        />
        <div className="cp_media-feedback_component-new-message-controls">
          <Button
            label={ mutationLoading ? <LoadingIndicator /> : translate("Send") }
            disabled={ mutationLoading }
            onClick={ createFeedback }
          />
          <Button
            isPrimary={ false }
            disabled={ mutationLoading }
            onClick={ closeCard }
            label={ translate("CANCEL") }
          />
        </div>
      </div>
    </div>
  )
}

export default CaptionFeedback
