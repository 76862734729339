import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Highcharts, { Options, Point } from "highcharts"
import HighchartsReact from "highcharts-react-official"

import * as API from "../../../../util/apiClient"
import * as GraphQL from "../../../../graphql"
import Button from "../../../Button"
import ModalScoreBreakDown from "../../../ModalScoreBreakDown"
import { calcGrade, prettyPrintDecimal } from "../../../../util/miscHelper"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../../../state/scoreBreakDownSlice"
import { CHART_BORDER_COLORS, scoreModalTypes } from "../../../../util/constant"
import { useSelector, useDispatch } from "../../../../state/hooks"

import "./audience-authenticity-charts.sass"

type Props = {
  context: "profiles" | "lists" | "publicLists"
}

const audienceAuthenticityChartBaseColors = {
  light: [
    "#9DEAED",
    "#A39CF7",
  ],
  dark: [
    "#9DEAED",
    "#A39CF7",
  ],
}

const botFollowingChartBaseColors = {
  light: [
    "#A39CF7",
    "#DFE1E566",
  ],
  dark: [
    "#A39CF7",
    "#576175",
  ],
}

const labelColors = {
  light: "#333333",
  dark: "#FAFBFD",
}

const baseOptions = (dataLabelColor: string): Options => ({
  chart: {
    type: "pie",
    plotShadow: false,
    height: 220,
    width: 220,
    style: {
      fontFamily: "Open Sans, sans-serif",
    },
  },
  plotOptions: {
    pie: {
      cursor: "pointer",
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "white",
      dataLabels: {
        color: dataLabelColor,
        enabled: true,
        format: "{point.percentage:.1f}",
        distance: -50,
        className: "cp_component_audience-authenticity-chart-label",
        filter: {
          property: "percentage",
          operator: ">",
          value: 10,
        },
      },
    },
  },
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "",
    },
    tickInterval: 1,
    floor: 0,
    gridLineDashStyle: "Dash",
    allowDecimals: false,
    labels: {
      format: "{value:.1f}",
    },
  },
  tooltip: {
    pointFormatter(this: Point) {
      // eslint-disable-next-line max-len
      return `<span style="color:${ this.color }">\u25CF</span> <b>${ prettyPrintDecimal((this.y || 0) / 100) }%</b><br/>`
    },
  },
  credits: {
    enabled: false,
  },
})

export default function AudienceAuthenticityCharts({ context }: Props) {
  const { t: translate } = useTranslation([], {
    keyPrefix: "component.AudienceAuthenticityCharts",
  })

  const dispatch = useDispatch()
  const [ scoreModal, setScoreModal ] = React.useState<boolean>(false)
  const profile = useSelector(({ socialProfile }) => socialProfile.profile)
  const { listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const { selectedListSocialAccount } = useSelector(({ publicList }) => publicList)
  const { theme } = useSelector(({ themeMode }) => themeMode)

  if (context === "profiles" && !API.isSuccess(profile)) {
    return null
  }

  if (context === "lists" && !API.isSuccess(listProfile)) {
    return null
  }

  if (context === "publicLists" && !selectedListSocialAccount) {
    return null
  }

  const getProperSocialAccount = () => {
    if (context === "profiles" && API.isSuccess(profile)) {
      return profile.payload.socialAccount
    }

    if (context === "lists" && API.isSuccess(listProfile)) {
      return listProfile.payload
        .suggestionListSocialAccountByListIdSocialAccountId.socialAccount
    }

    if (context === "publicLists" && selectedListSocialAccount) {
      return selectedListSocialAccount.socialAccount
    }

    return null
  }

  const socialAccount = getProperSocialAccount() as GraphQL.SocialAccount
  const audienceAuthenticityScore = socialAccount
    .audienceAuthenticityScore?.value

  if (!audienceAuthenticityScore) {
    return null
  }

  const [ rawGrade, modifier ] = calcGrade(audienceAuthenticityScore)
  const grade = rawGrade.toUpperCase()

  const gradeLabel = () => {
    switch (grade) {
      case "A":
        return translate("Low")

      case "B":
        return translate("Medium")

      default:
        return translate("High")
    }
  }

  const openScoreModal = () => {
    const scoreBreakDown = {
      socialAccount: socialAccount as GraphQL.SocialAccount,
      scores: [ socialAccount.audienceAuthenticityScore as GraphQL.Score ],
    }

    dispatch(rawDataToScoreBreakDown(scoreBreakDown, scoreModalTypes.AUDIENCE_AUTHENTICITY))
    setScoreModal(true)
  }

  const closeScoreModal = () => {
    setScoreModal(false)
    dispatch(resetScoreBreakDown())
  }

  const audienceAuthenticityChartOptions = useMemo(() => {
    const options = baseOptions(labelColors.light)
    if (!options.plotOptions?.pie) return options
    options.plotOptions.pie.colors = Highcharts
      .getOptions()
      .colors?.map((c, i, _colors) => Highcharts
        .color(audienceAuthenticityChartBaseColors[theme][i % _colors.length])
        .get())
    options.plotOptions.pie.borderColor = CHART_BORDER_COLORS[theme]
    options.legend = {
      borderWidth: 1,
      borderRadius: 2,
      padding: 16,
    }
    options.series = [
      {
        name: "Audience",
        type: "pie",
        data: [
          {
            name: translate("Audience Authenticity"),
            y: audienceAuthenticityScore,
          },
          {
            name: "",
            y: 100 - audienceAuthenticityScore,
          },
        ].sort((a, b) => b.y - a.y),
      },
    ]
    return options
  }, [ theme, audienceAuthenticityScore ])

  const botFollowingChartOptions = useMemo(() => {
    const options = baseOptions(labelColors[theme])
    if (!options.plotOptions?.pie) return options
    options.plotOptions.pie.colors = Highcharts
      .getOptions()
      .colors?.map((c, i, _colors) => Highcharts
        .color(botFollowingChartBaseColors[theme][i % _colors.length])
        .get())
    options.plotOptions.pie.borderColor = CHART_BORDER_COLORS[theme]
    options.legend = {
      borderWidth: 1,
      borderRadius: 2,
      padding: 16,
    }
    options.series = [
      {
        name: "Audience",
        type: "pie",
        data: [
          {
            name: translate("Estimated Fake Followers"),
            y: 100 - audienceAuthenticityScore,
          },
          {
            name: translate("Authentic Followers"),
            y: audienceAuthenticityScore,
          },
        ].sort((a, b) => a.y - b.y),
      },
    ]
    return options
  }, [ theme, audienceAuthenticityScore ])

  return (
    <div className="cp_audience-details_component-widget">
      <div className="cp_component_audience-authenticity-charts">
        <section className="chart-container">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Audience Authenticity") }
            <Button
              id="cp_component_audience-authenticity-charts-score-button"
              className="score-button"
              label={ `${ translate("Score") } | ${ grade } ${ modifier }` }
              onClick={ openScoreModal }
            />
          </h6>
          <article>
            <aside>
              <HighchartsReact
                highcharts={ Highcharts }
                options={ audienceAuthenticityChartOptions }
              />
            </aside>
            <aside>
              <div>
                <h4 className="headline_small">
                  { `${ translate("Grade") }: ${ grade }${ modifier }` }
                </h4>
                <p className="body_large">
                  { translate("Measure of audience") }
                </p>
              </div>
            </aside>
          </article>
        </section>
        <section className="chart-container">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Estimated Fake Followers") }
          </h6>
          <article>
            <aside>
              <HighchartsReact
                highcharts={ Highcharts }
                options={ botFollowingChartOptions }
              />
            </aside>
            <aside>
              <div>
                <h4 className="headline_small">
                  { `${ gradeLabel() } ${ translate("Fake Followers") }` }
                </h4>
                <p className="body_large">
                  { translate("Estimated percentage") }
                </p>
              </div>
            </aside>
          </article>
        </section>
      </div>
      <ModalScoreBreakDown
        isModalScoreOpen={ scoreModal }
        closeModal={ closeScoreModal }
      />
    </div>
  )
}
