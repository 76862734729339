import React from "react"
import ListTopPosts from "../../../asset/visualizations/LIST_TOP_POSTS.svg"
import ListRelevantKeywords from "../../../asset/visualizations/LIST_RELEVANTPOST_KEYWORDS.svg"
import ListRelevantTags from "../../../asset/visualizations/LIST_RELEVANTPOST_TAGS.svg"

export default function ContentVisualizations() {
  return (
    <div className="cp_lcp-preview_example-visualizations">
      <img src={ ListTopPosts } alt="Content Visualization" />
      <img src={ ListRelevantKeywords } alt="List Relevant Keywords" />
      <img src={ ListRelevantTags } alt="List Relevant Images Tag" />
    </div>
  )
}
