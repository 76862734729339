import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material"
import {
  Add,
  Remove,
  HelpOutline,
  ArrowForwardIos,
  DragIndicator,
} from "@mui/icons-material"
import { useParams } from "react-router-dom"

import Highlights from "./Visualizations/Highlights"
import HighlightsDisplay from "./Visualizations/HighlightsDisplay"
import ContentAdditionalFields from "./ContentAdditionalFields"
import ModalListAccountsDataPoint from "../ModalListAccountsDataPoint"
import Switch from "../Switch"
import {
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES,
  AUDIENCE_DISPLAY_TOGGLES,
  CONTENT_DISPLAY_TOGGLES,
} from "../../util/constant"
import Button from "../Button"
import { useDispatch, useSelector } from "../../state/hooks"
import { setUpdateListForm, updateList } from "../../state/listConfigurationSlice"
import { pushToast } from "../../state/toastSlice"
import { isSuccess } from "../../util/apiClient"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import * as GraphQL from "../../graphql"
import Appendix from "./Visualizations/Appendix"
import AppendixDisplay from "./Visualizations/AppendixDisplay"

import "./configuration-option-styles.sass"
import { fetchList } from "../../state/listSlice"
import {
  accountInsightDisplayCodes,
  audienceDisplayCodes,
  contentDisplayCodes,
} from "./constants"

export default function ConfigurationVisualizations() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })
  const {
    t: translateConfigOptions,
  } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualizationsOptions" })
  const {
    t: translateCVH,
  } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()
  const { listID } = useParams()

  const list = useSelector((state) => state.list.list)
  const updateListForm = useSelector(({ listConfiguration }) => listConfiguration.updateListForm)

  const [ expanded, setExpanded ] = React.useState(false)
  const [ open, setOpen ] = React.useState<boolean>(false)
  const [ highlightsToggleCount, setHighlightsToggleCount ] = useState<number>(0)
  const [ acctInsightToggles, setAcctInsightToggles ] = useState<string[]>([])
  const [ audienceToggles, setAudienceToggles ] = useState<string[]>([])
  const [ contentToggles, setContentToggles ] = useState<string[]>([])

  React.useEffect(() => {
    if (updateListForm) {
      // Get all the active toggles
      const activeInsightToggles: string[] = (Array.isArray(updateListForm.toggles))
        ? updateListForm.toggles.filter((t) => accountInsightDisplayCodes.includes(t))
        : (accountInsightDisplayCodes.includes(updateListForm.toggles)) ? [ updateListForm.toggles ] : []
      const activeAudienceToggles: string[] = (Array.isArray(updateListForm.toggles))
        ? updateListForm.toggles.filter((t) => audienceDisplayCodes.includes(t))
        : (accountInsightDisplayCodes.includes(updateListForm.toggles)) ? [ updateListForm.toggles ] : []
      const activeContentToggles: string[] = (Array.isArray(updateListForm.toggles))
        ? updateListForm.toggles.filter((t) => contentDisplayCodes.includes(t))
        : (contentDisplayCodes.includes(updateListForm.toggles)) ? [ updateListForm.toggles ] : []

      // Set the toggles
      setAcctInsightToggles(activeInsightToggles)
      setAudienceToggles(activeAudienceToggles)
      setContentToggles(activeContentToggles)
    }
  }, [ updateListForm ])

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  const isToggleChecked = (toggleName: string, toggles: string[]): boolean => toggles.includes(toggleName)

  const handleToggleUpdate = (
    toggleName: string,
    setToggles: React.Dispatch<React.SetStateAction<string[]>>,
  ): void => {
    setToggles((prevToggles) => prevToggles.includes(toggleName)
      ? prevToggles.filter((t) => t !== toggleName)
      : [ ...prevToggles, toggleName ])

    // Update list toggles
    if (updateListForm) {
      const form: GraphQL.UpdateSuggestionListMutationVariables = { ...updateListForm }
      if (Array.isArray(form.toggles) && form.toggles.includes(toggleName)) {
        form.toggles = form.toggles.filter((t) => t !== toggleName)
      } else if (typeof form.toggles === "string" && form.toggles === toggleName) {
        form.toggles = []
      } else if (Array.isArray(form.toggles)) {
        form.toggles = [ ...form.toggles, toggleName ]
      } else {
        form.toggles = [ form.toggles, toggleName ]
      }

      // Update the form
      dispatch(setUpdateListForm(form))
    }
  }

  const saveToggles = () => {
    // Make the call
    dispatch(updateList({
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Successfully saved toggles!"),
        }))
        dispatch(fetchList(listID || ""))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: "Unable to save toggles. Please try again later!",
        }))
      },
    }))
  }

  const renderHighlights = (): React.JSX.Element => {
    if (isSuccess(list)) {
      return (
        <>
          <Highlights />
          <HighlightsDisplay setActiveToggles={ setHighlightsToggleCount } />
        </>
      )
    }

    return <LoadingIndicatorCard />
  }

  return (
    <div className="cp_list-configuration-visualizations cp_lcv">
      <div className="cp_lcv-top">
        <h2 className="cp_lcv-heading">{ translate("VISUALIZATIONS") }</h2>
        <IconButton
          id="open-accounts-data-point-modal-button"
          className="cp_lcv-help-icon"
          onClick={ () => setOpen(true) }
        >
          <HelpOutline fontSize="small" />
        </IconButton>
      </div>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion highlights"
        elevation={ 0 }
        id="cp_lcv-accordion_highlights"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary"
          expandIcon={ expanded ? <Remove className="cp_lcv-accordion-icon-md" /> : <Add className="cp_lcv-accordion-icon-md" /> }
          aria-controls="highlights-content"
          id="highlights-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Highlights") }</h3>
          <span id="highlightsCounter" className="summary-counter">{ highlightsToggleCount }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          { renderHighlights() }
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion account_insights"
        elevation={ 0 }
        id="cp_lcv-accordion_account_insights"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="account-insights-content"
          id="account-insights-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Account Insights") }</h3>
          <span id="acctInsigtsCounter" className="summary-counter">{ acctInsightToggles.length }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details config-options">
          <div className="cp_lcv-configuration-options">
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME, acctInsightToggles) }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">{ translateConfigOptions("Engagement Rate over Time") }</span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE, acctInsightToggles) }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Engagement by Post Type Organic vs Sponsored") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={
                  isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL, acctInsightToggles)
                }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Engagement by Post Type Reel vs Non-Reel") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP, acctInsightToggles) }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Engagement Heat Map") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS, acctInsightToggles) }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Recent Media & AI Image Analysis") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES, acctInsightToggles) }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Recent Media Stories") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={
                  isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH, acctInsightToggles)
                }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Brands Mentions & Brands Worked With") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS, acctInsightToggles) }
                handleChange={ () => handleToggleUpdate(
                  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
                  setAcctInsightToggles,
                ) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Personality Traits") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion audience"
        elevation={ 0 }
        id="cp_lcv-accordion_audience"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary  has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="audience-content"
          id="audience-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Audience") }</h3>
          <span id="audienceCounter" className="summary-counter">{ audienceToggles.length }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details config-options">
          <div className="cp_lcv-configuration-options">
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">{ translateCVH("Followers Over Time") }</span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Audience Authenticity") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.GENDER, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.GENDER,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Gender") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Family Status") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Age Range") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Income Range") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Education Level") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Ethnicities") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.LANGUAGES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.LANGUAGES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">{ translateCVH("Languages") }</span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.RELIGIONS, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Religions") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.COUNTRIES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Countries") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.STATES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.STATES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("States") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.CITIES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.CITIES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Cities") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Occupations") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Industries") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Employers") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Universities") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(AUDIENCE_DISPLAY_TOGGLES.AFFINITIES, audienceToggles) }
                handleChange={ () => handleToggleUpdate(
                  AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
                  setAudienceToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Affinities") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion content"
        elevation={ 0 }
        id="cp_lcv-accordion_content"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary  has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="content-content"
          id="content-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Content") }</h3>
          <span id="contentCounter" className="summary-counter">{ contentToggles.length }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details config-options">
          <div className="cp_lcv-configuration-options">
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(CONTENT_DISPLAY_TOGGLES.TOP_POSTS, contentToggles) }
                handleChange={ () => handleToggleUpdate(
                  CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
                  setContentToggles,
                ) }
              />
              <span className="config-option-label">{ translateCVH("Top Posts") }</span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS, contentToggles) }
                handleChange={ () => handleToggleUpdate(
                  CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS,
                  setContentToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Relevant Posts") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            { isToggleChecked(CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS, contentToggles) && (
            <ContentAdditionalFields />
            ) }
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS, contentToggles) }
                handleChange={ () => handleToggleUpdate(
                  CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
                  setContentToggles,
                ) }
              />
              <span className="config-option-label">
                { translateCVH("Post Impressions") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion appendix last"
        elevation={ 0 }
        id="cp_lcv-accordion_appendix"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary"
          expandIcon={ expanded ? <Remove className="cp_lcv-accordion-icon-md" /> : <Add className="cp_lcv-accordion-icon-md" /> }
          aria-controls="appendix-content"
          id="appendix-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Appendix") }</h3>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          <Appendix />
          <AppendixDisplay />
        </AccordionDetails>
      </Accordion>
      <div className="cp_lcv-save-toggles-button">
        <Button
          id="save-toggles-button"
          label={ translate("Save") }
          isPrimary={ true }
          fullWidth={ true }
          onClick={ saveToggles }
        />
      </div>
      <ModalListAccountsDataPoint open={ open } setOpen={ setOpen } />
    </div>
  )
}
